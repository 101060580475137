<template>
  <div class="resInfo" v-loading="loading"   element-loading-text="加载中。。。" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="item">
      <img class="item-img" @click="previewImg(image)" :src="image" />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  name: "mini",
  data() {
    return {
      image: "",
      loading: false,
    };
  },
  created() {
    this.getRes();
  },
  methods: {
    getRes() {
      this.loading = true;
      Axios.get(
        `https://smart.mulazim.com/ug/v1/restaurant/license?id=${this.$route.query.id}&type=0002`
      )
        .then((res) => {
          if (res.data.status == 200) {
            this.image = res.data.data;
          } else {
            this.$message.error(`请重试 ${res.data.msg}`);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("请重试");
        });
    },
    previewImg(urls) {
      this.$hevueImgPreview({ name: urls, url: urls });
    },
  },
};
</script>
<style scoped>
.resInfo {
    width: 100%;
    height: 100vh;
}
.item {
  width: 80%;
  height: 600px;
  margin: auto;
  text-align: center;
}
.item-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
