<style scoped>
body {
  background-color: #eff1f6;
}

td {
  border: #000000 solid 1px;
}

.content {
  max-width: 500px;
  width: 100%;
  /* padding: 15px; */
  margin: 15px;
  background-color: #fff;
  border-radius: 15px;
}

.contet_title {
  font-size: 24px;
  padding-top: 15px;
  padding-bottom: 40px;
}

.m-1 {
  margin: 20px;
}

.text-algin {
  text-align: center;
}

.text-bold {
  font-weight: 600;
}
</style>

<template>
  <div style="display: flex; justify-content: center">
    <div class="content">
      <div class="m-1">
        <div class="text-algin text-bold contet_title">{{ title }}</div>
      </div>
      <div class="m-1">
        <h2><!--[if-->第一章<!--[endif]--><b>总则</b><b></b></h2>
        <h3><b>一、</b><b>目的和依据</b><b></b></h3>
        <p>
          为规范美滋来外卖平台商户入驻审查登记标准，维护美滋来外卖平台经营秩序，保障入驻商户和消费者的合法权益，根据《中华人民共和国食品安全法》、《网络餐饮服务食品安全监督管理办法》、《网络食品安全违法行为查处办法》等相关法律法规规章，以及相关协议和管理规范，特制订本规范。
        </p>
        <h3><b>二、</b><b>适用范围</b><b></b></h3>
        <p>本规范适用于在美滋来外卖平台上从事网络餐饮服务活动的所有餐饮服务提供者。</p>
        <h3><b>三、</b><b>定义</b></h3>
        <p>
          1.&nbsp;美滋来外卖平台:指为商户提供外卖展示及交易信息的网络服务平台，包括但不限于美滋来微信小程序、美滋来外卖客户端等。
        </p>
        <p>
          2.&nbsp;商户:即入网餐饮服务提供者，指在美滋来外卖平台开展网络餐饮服务的法人、自然人或其他组织。
        </p>
        <p>
          3.&nbsp;用户:指在美滋来外卖平台注册、接受平台规则，并可通过美滋来外卖平台与商户达成订单的消费者。
        </p>
        <p>
          4.&nbsp;网络餐饮服务:指入驻美滋来外卖平台的商户通过平台发布餐饮服务信息或者接受订购需求后制作并供应餐饮膳食的食品经营行为。
        </p>
        <h2><!--[if-->第二章<!--[endif]--><b>基本内容</b><b></b></h2>
        <h3><b>一、</b><b>入驻要求</b><b></b></h3>
        <p>1.&nbsp;商户经营资质：</p>
        <p>
          1.1
          地方市场监督管理部门、行政审批局(或行政审批服务中心)等颁发的营业执照或其他替代营业执照的合法有效资质(下统称“营业执照”
          )。替代营业执照的其他有效资质包括事业单位法人证书、民办非企业单位登记证书、军队单位对外有偿服务许可证、社会团体法人登记证书等;
        </p>
        <p>
          1.2
          地方市场监督管理部门、食品安全监督管理部门、行政审批局(或行政审批服务中心)、街道办等颁布的行业许可资质文件。行业许可资质文件包括食品经营许可证、食品生产许可证、以及符合当地法规政策要求、可以依法从事网络餐饮服务的其他有效资质证明，包括但不限于小餐饮登记证(卡)、小餐饮备案证(卡)、小餐饮许可证、小餐饮核准证、食品小经营店登记(备案)证等
        </p>
        <p>
          1.3 “含网络经营”标注
          :监管部门要求在许可资质证明中标注“含网络经营方可被允许从事入网经营的，商户应向发证机关申请予以标注。
        </p>
        <p>2.&nbsp;商户门店环境</p>
        <p>
          商户需须有合法自营实体经营门店，店内环境干净整洁，符合《食品安全法》《网络餐饮服务食品安全监督管理办法》、国家标准以及行业标准等相关要求。
        </p>
        <h3><b>二、</b><b>入驻审查标准</b><b></b></h3>
        <p>1.&nbsp;资质信息</p>
        <table>
          <tbody>
            <tr>
              <td><p>资质项目</p></td>
              <td><p>图片要求</p></td>
            </tr>
            <tr>
              <td><p>营业执照</p></td>
              <td>
                <p>(1)营业执照需提供原件;真实有效、提交审核时在有效期内；</p>
                <p>(2)营业执照需边框、国徽完整，拍摄清晰；</p>
                <p>
                  (3)营业执照中注册号、名称、经营地址、经营范围、有效期、发证日期、发照机关等重要信息不能被遮挡或涂改；
                </p>
                <p>(4)营业执照图片中不得出现除美滋来及商户自己品牌以外的其他水印；</p>
                <p>(5)营业执照图片不得造假(包括P图、假照等)；</p>
                <p>
                  (6)营业执照的黑白复印件可以替代营业执照原件，复印件必须加盖红色公章，公章文字需清晰且与营业执照名称一致(合同章不可)。
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>食品经营资质包括但不限于食品经营许可证、小餐饮登记/备案证等 )</p>
              </td>
              <td>
                <p>
                  (1)许可证等食品经营资质需原件、真实有效、提交审核时距许可证有效期截止时间大于30天；
                </p>
                <p>(2)许可证等食品经营资质需边框完整，拍摄清晰；</p>
                <p>
                  (3)许可证等食品经营资质中经营者名称、经营场所、经营项目、发证机关等重要信息不能被遮挡或涂改；
                </p>
                <p>
                  (4)许可证等食品经营资质的图片中不得出现除美滋来及商户自己品牌以外的其他水印；
                </p>
                <p>
                  (5)许可证等食品经营资质不得造假，不得借用、冒用他人的经营资质，图片不得造假(包括P图、假证等)。
                </p>
              </td>
            </tr>
            <tr>
              <td><p>法定代表人/商户负责人手持身份证件</p></td>
              <td>
                <p>
                  (1)身份证件须为:有效大陆居民二代身份证、有效临时身份证、有效护照、有效港澳居民来往内地通行证、有效台湾居民来往大陆通行证、有效台胞证；
                </p>
                <p>(2)本人手持身份证件原件(带有照片面)拍照，并露出完整五官;</p>
                <p>(3)提供身份证件本人需年满18周岁；</p>
                <p>(4)身份证复印件需将正反面复印在同一张纸上，证件本人需按手印。</p>
              </td>
            </tr>
            <tr>
              <td><p>量化分级信息展示</p></td>
              <td>
                <p>(1)拍摄完整清晰的食品安全等级类别展示；</p>
                <p>(2)展示内容要有本店的监督检查结果。</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>2.&nbsp;基本信息</p>
        <table>
          <tbody>
            <tr>
              <td><p>基本项目</p></td>
              <td><p>图片要求</p></td>
            </tr>
            <tr>
              <td><p>经营品类</p></td>
              <td>
                <p>(1)符合营业执照、行业许可证的经营范围；</p>
                <p>(2)选择的品类需是所有商品中的主营品类。</p>
              </td>
            </tr>
            <tr>
              <td><p>头图</p></td>
              <td>
                <p>
                  (1)头图应为菜品图/商户LOGO，要求完整、色彩明亮美观、清晰不模糊(800x600)、方向正确；
                </p>
                <p>(2)头图应与商户名称、品类基本相符；</p>
                <p>(3)头图长宽比例为 4:3；</p>
                <p>(4)头图不能使用商户牌匾、环境图、门脸图、菜单、宣传</p>
                <p>单、名片等图片；</p>
                <p>(5)头图不能有水印(包括美滋来水印) 及其他品牌logo；</p>
                <p>(6)头图不可打马赛克；</p>
                <p>(7)头图不能有联系方式( 包括微信号，QQ，送电话</p>
                <p>二维码等&nbsp;) ;</p>
                <p>
                  (8)头图不能出现商户活动(例:“满减“半价”)等及夸大宣传(例:“最
                  XX”、“XX第一”、“XX极品”)等宣传语；
                </p>
                <p>(9)头图不得侵权，不得使用非授权的动画、人物等形象及品牌logo；</p>
                <p>(10)头图不得出现“美滋来”或“美滋来外卖”字样等。</p>
              </td>
            </tr>
            <tr>
              <td><p>门脸图</p></td>
              <td>
                <p>(1)要求清晰、明亮;图片包括完整牌匾及完整正门；</p>
                <p>(2) 牌匾名称应与线上商户名称主体一致；</p>
                <p>
                  (3)拍摄时为开门营业状态，不可关闭卷帘铁门，不可标示“暂不营业”、“正在装修”、“转让”、“关闭”等;
                </p>
                <p>(4)不可用图片处理软件处理，包括拼图、马赛克、photoshop等;</p>
                <p>(5)不可为网络图片、手机截图、或有水印；</p>
                <p>(6)必须有固定经营场所不允许为流动餐车或无固定门店。</p>
              </td>
            </tr>
            <tr>
              <td><p>环境图</p></td>
              <td>
                <p>(1)要求呈现清晰、明亮、真实的就餐环境图；</p>
                <p>(2)拍摄时门店为营业状态(店内不可装修中)；</p>
                <p>(3)不可用图片处理软件处理，包括拼图、马赛克、photoshop等；</p>
                <p>(4)不可为他店图片、网络图片、手机截图、有水印(美滋来水印也不行哦);</p>
                <p>(5)不允许为流动餐车或无固定门店；</p>
                <p>(6)干净整洁，无杂物。</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3><b>三、</b><b>登记制度</b><b></b></h3>
        <p>
          商户入驻美滋来外卖平台均应按照平台登记流程和要求，在美滋来外卖平台登记其真实有效的实际经营名称、营业资质、地址、食品安全管理人员、法定代表人或者负责人及联系方式等信息。
        </p>
        <h3><b>四、</b><b>及时更新</b><b></b></h3>
        <p>
          商户如提供的资质、信息或材料有变更的，应及时更新，以确保信息的准确性和有效性。如商户提供的资质、信息或材料错误、失效的，美滋来外卖有权要求商户补充上传资质、信息或材料。如商户提供的信息不准确，导致用户或美滋来外卖无法联系上的，美滋来外卖有权视情况终止与商户合作。
        </p>
        <h2><!--[if-->第三章<!--[endif]--><b>违规处理</b><b></b></h2>
        <h3><b>一、</b><b>违规行为</b><b></b></h3>
        <p>商户除遵守法律法规及本规范第五条入驻审查标准外，商户不得出现如下违规行为：</p>
        <p>1.&nbsp;商户提交的营业执照和食品经营资质主体名称不一致的；</p>
        <p>
          2.&nbsp;商户营业执照和食品经营资质提交审核时过期或合作期间未能保持持续有效的；
        </p>
        <p>
          3.&nbsp;商户营业执照和食品经营资质的经营范围与实际经营范围不一致或超范围经营的；
        </p>
        <p>
          4.&nbsp;商户无实体门店或未按照食品经营资质载明的主体业态、经营项目从事经营活动的；
        </p>
        <p>5.&nbsp;商户实际经营地址与所提供资质证照中营业地址不一致的；</p>
        <p>6.&nbsp;商户提供虚假资质、信息或材料的；</p>
        <p>7.&nbsp;其他违反法律法规及双方协议、相关管理规范情形。</p>
        <h3><b>二、</b><b>违规处理</b><b></b></h3>
        <p>
          1.&nbsp;美滋来外卖平台有权按照法定要求，根据《商户经营行为抽查监测规范》等平台规则对商户违规行为进行抽查监测。如发现商户存在违法违规情形的，美滋来外卖平台有权根据违法违规行为严重程度对商户进行警告、置休整改、下线、永久停止服务等处理措施。
        </p>
        <p>
          2.&nbsp;对于商户严重违法犯罪行为，美滋来外卖有权交由行政管理机关或司法机关追究商户行政或刑事责任。
        </p>
        <p>
          3.
          因美滋来外卖平台在审核商户信息过程中因审核疏忽、管理义务而造成消费者损害的，平台将依法承担相应责任。
        </p>
        <h3><b>三、</b><b>申诉流程</b><b></b></h3>
        <p>
          1.&nbsp;针对美滋来外卖对商户的违规处理措施，如商户认为不合理的，则有权依据本规范或其他相关规范在收到违规处理之日起2日内向美滋来外卖提起违规申诉，如商户2日内未进行违规申诉的，视为商户认可违规处罚。
        </p>
        <p>
          2.&nbsp;为了保证申诉的有效性，提高申诉成功率，商户申诉时需按照美滋来外卖平台的要求提供完整、真实、有效的证明材料，包括但不限于：聊天记录、截图、链接、照片、录音、快递单凭证、报案回执等。如商户提供虚假证明材料的，即视为申诉不成功。
        </p>
        <p>
          3.&nbsp;美滋来外卖在收到商户提起申诉后，将及时审核商户提交的申诉材料并反馈相应处理结果。若违规申诉成功，则对应的违规处罚不成立、处罚措施不执行；若申诉不成功，则对应的违规处罚成立、处罚措施将直接执行。
        </p>
        <h2><b>附则</b><b></b></h2>
        <h3><b></b></h3>
        <h3><b>一、规范变更</b><b></b></h3>
        <p>
          美滋来外卖有权对本规范内容进行修改、补充或更新，并在该规范生效前通过美滋来外卖商户后台管理中心、美滋来外卖商家客户端APP公告等方式予以公告，上述公告一经发布即视为已经单独通知到每一家使用美滋来外卖服务的商户；若商户在本规范内容修改、补充或更新公告后继续使用美滋来外卖提供的服务，即视为商户同意遵守修改、补充或更新的规范。
        </p>
        <h3><b>二、规范效力</b><b></b></h3>
        <p>
          本规范作为商户与美滋来外卖所签署协议的附件，与协议具有相同法律效力。合作方违反本规范的，按照本规范、双方协议及其他管理规范进行处罚。
        </p>
        <h3><b>三、规范溯及力</b><b></b></h3>
        <p>
          发生在本规范生效之日前的行为，适用当时的规范进行处理；发生在本规范生效之日后的，适用本规范。
        </p>
        <h3><b>四、生效</b><b></b></h3>
        <p>
          本规范首次发布日期为2019年1月1日，自发布之日起7日后生效本规范最新修订日期为2022年4月15日，自发布之日起7日后生效
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  created() {
    this.title = this.$route.query.data;
  },
  methods: {},
};
</script>
