<template>
  <div class="model-content">
    <div
      class="model_26"
      :class="
        posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
      "
    >
      <div class="bg_img_container">
        <img style="width: 100%" :src="url" />
      </div>
      <div class="merchant_name">
        <div class="merchant_name_font">{{ posterList.merchant_name }}</div>
      </div>
      <div class="food_img">
        <img :src="posterList.food_img" alt="" />
      </div>
      <div class="food_info">
        <div class="food_name">{{ posterList.food_name }}</div>
        <div class="food_price">
          <span class="food_price_number">{{
            parseFloat(posterList.food_price)
          }}</span>
          <span>{{
            posterList.lang == "ug" || !posterList.lang ? "يۈەن" : "元"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_26",
  data() {
    return {
      posterList: {},
      url: require("../../img/poster/model_26.jpg"),
    };
  },
  created() {
    this.canvasfortitle(
      this.$route.query.merchant_name,
      this.$route.query.food_name,
      this.$route.query
    );
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      let res_name = merchant_name.replaceAll("(", "-");
      res_name = res_name.replaceAll("（", "-");
      res_name = res_name.replaceAll(")", " ");
      res_name = res_name.replaceAll("）", " ");
      this.posterList = query;
      this.posterList["merchant_name"] = res_name;
    },
  },
};
</script>

<style scoped>
.model-content {
  width: 750px;
  height: 320px;
  display: flex;
  justify-content: center;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.model_26 {
  width: 750px;
  height: 320px;
  position: relative;
  overflow: hidden;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.bg_img_container {
  width: 100%;
  height: 100%;
}

.bg_img_container img {
  width: 100%;
}

.merchant_name {
  width: 394px;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  color: #a77808;
  font-size: 32px;
  padding: 8px 12px;
  box-sizing: border-box;
  position: absolute;
  right: 300px;
  top: 35px;
}

.merchant_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.food_img {
  position: absolute;
  height: 262px;
  width: 425px;
  overflow: hidden;
  right: -110px;
  top: 55px;
}

.food_img img {
  width: 100%;
  height: 100%;
  clip-path: ellipse(50% 50% at 50% 50%);
  object-fit: cover;
}

.food_info {
  position: absolute;
  top: 190px;
  right: 360px;
  width: 284px;
  height: 44px;
}

.food_info .food_name {
  color: #aa7c0d;
  position: absolute;
  right: 0;
  width: 132px;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 44px;
}

.food_info .food_price {
  color: #2e0202;
  position: absolute;
  left: 0;
  width: 144px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.food_info .food_price .food_price_number {
  padding: 0px 4px;
}
</style>
