<style scoped>
body {
  background-color: #eff1f6;
}

.content {
  max-width: 500px;
  width: 100%;
  /* padding: 15px; */
  margin: 15px;
  background-color: #fff;
  border-radius: 15px;
}
.contet_title {
  font-size: 24px;
  padding-top: 15px;
  padding-bottom: 40px;
}
.m-1 {
  margin: 20px;
}
.text-algin {
  text-align: center;
}
.text-bold {
  font-weight: 600;
}
</style>

<template>
  <div style="display: flex; justify-content: center">
    <div class="content">
      <div class="m-1">
        <div class="text-algin text-bold contet_title">{{ title }}</div>
      </div>
      <div class="m-1">
        <p><b>一、概述</b></p>
        <p>
          <b>1.1</b
          >由于互联网高速发展，用户与平台签署的本协议列明的条款并不能完整罗列并涵盖用户与平台所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，平台的隐私权政策（或其更名后的文件）及其他各类平台规则为本协议的补充协议，与本协议不可分割且具有同等法律效力。<b
            ><u
              >用户使用平台服务前需认真阅读每一项补充协议并确认，用户的确认行为视为用户同意上述补充协议。</u
            ></b
          ><b><u></u></b>
        </p>
        <p>
          <b>1.2</b
          >根据国家法律法规变化、运营需要或为提升服务质量的目的，平台将在必要的时候对上述各项协议、条款与规则进行修改更新，并通过在网页或移动客户端上发出公告、通知等合理、醒目的方式向用户进行提前通知。<b
            ><u
              >用户应当及时查阅并了解相关更新修改内容，如用户不同意相关更新</u
            ></b
          ><b><u>、</u></b
          ><b
            ><u
              >修改内容，可停止使用相关更新修改内容所涉及的服务，此情形下，变更事项对用户不产生效力；如用户在上述更新修改内容实施后继续使用所涉及的服务，将视为您已同意各项更新修改内容。</u
            ></b
          >
        </p>
        <p>
          <b>&nbsp; &nbsp;1.3 </b
          >本协议中所提到的平台是指平台，协议中简称“平台”。
        </p>
        <p><b>二、用户的权利和义务</b></p>
        <p><b>2.1用户规范</b><b></b></p>
        <p>
          为了给广大用户营造一个健康、有序的网络服务交易平台，平台倡导诚信交易，并为此提供一系列的解决方案。
        </p>
        <p>
          平台是为用户交易提供信息服务的平台，平台只能部分控制交易所涉及的产品的质量、安全或合法性、商贸信息的真实性或准确性，以及交易方履行其在贸易协议下的各项义务的能力。<b
            ><u>鉴于</u></b
          ><b><u>平台</u></b
          ><b><u>具备存在海量信息及信息网络环境下信息与实物相分离的特点，</u></b
          ><b><u>平台</u></b
          ><b
            ><u
              >无法逐一审查商品及/或服务的信息，无法逐一审查交易所涉及的商品及/或服务的质量、安全以及合法性、真实性、准确性，对此用户应谨慎判断。</u
            ></b
          ><b><u></u></b>
        </p>
        <p>
          您知悉并承诺，在使用平台所提供的服务的过程中，您应遵守相关法律法规，不应从事如下违反法律法规的规定，影响正常服务提供或损害他人合法利益的行为：
        </p>
        <p>
          （1）不应利用平台或相关服务危害国家安全、破坏政治稳定、泄露国家秘密，不侵犯国家、社会、集体利益和第三方的合法权益，不从事违法犯罪活动，不设立用于实施诈骗，传授犯罪方法，制作或者销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组，不利用网络发布涉及实施诈骗，制作或者销售违禁物品、管制物品以及其他违法犯罪活动的信息；
        </p>
        <p>
          （2）不应制作、发布、复制、查阅和传播、存储、链接下列信息：反对宪法所确定的基本原则的；危害国家安全，泄露国家秘密，颠覆国家政权，推翻社会主义制度，煽动分裂国家，破坏国家统一的；损害国家荣誉和利益的；煽动民族仇恨、民族歧视，破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；捏造、散布谣言，侵犯他人权利，扰乱经济、社会秩序，破坏社会稳定的；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；侮辱或者诽谤他人，侵害他人合法权益的；宣扬恐怖主义、极端主义的；违背当地风俗习惯的；含有法律、行政法规禁止的其他内容的；
        </p>
        <p>
          （3）不应从事下列危害计算机信息网络安全的活动：对网络服务及相关软硬件设施进行破解、破坏、删除、修改或者增加的；对计算机信息网络中存储或者传输的数据和应用程序进行删除、修改或者增加的；使用软件或硬件等方式窃取他人口令、非法入侵他人计算机系统；故意制作、传播计算机病毒等破坏性程序的；其他危害计算机信息网络安全的活动；
        </p>
        <p>
          （4）不应擅自复制和使用网络上未公开和未授权的文件。除相关著作权人明示同意，不应在网络中擅自破解、传播、下载或复制第三人享有著作权的软件或进行其他任何侵害他人知识产权的活动；
        </p>
        <p>
          （5）不应私自传播广告信息或以“刷单”等不正当方式帮助他人提升评价或利用评价权利对其他用户、商户实施威胁、敲诈勒索；
        </p>
        <p>（6）不应因对平台相关服务的使用行为导致平台卷入政治和公共事件；</p>
        <p>
          （7）不应通过侵犯第三人合法权益、作弊、扰乱系统、实施网络攻击、恶意套现、刷信誉、批量注册、用机器注册平台账户、用机器模拟客户端等手段进行交易或参加平台或其所授权的第三方发布的任何活动；
        </p>
        <p>
          （8）未经平台事先书面许可，不应自行或授权、协助任何第三方非法抓取平台所展示的任何内容（“非法抓取”是指采用未经平台认可的程序或者非正常浏览等技术手段获取内容数据的行为）。<b
            ><u></u
          ></b>
        </p>
        <p><b>2.2</b><b>用户的权利</b></p>
        <p>
          <b>2.2.1</b
          >创建及使用账户：用户有权在向平台提交相关注册资料后创立平台账号，拥有自己在平台的用户名及密码，并有权利使用自己的用户名及密码登录平台。平台原则上只允许每位用户使用一个平台账号。<b
            ><u>用户不得以任何形式擅自转让或授权他人使用自己的平台账号</u></b
          >。<b><u>由此造成的任何损失和风险由用户自行承担责任。</u></b
          ><b><u></u></b>
        </p>
        <p>
          <b>2.2.2</b
          >浏览信息、确认并提交订单：用户有权利使用平台和平台商户在线取得直接联系，以获得最新的商品信息和相关增值服务。在浏览相关商品/服务的信息后，用户点击“去结算”按钮后进入订单页面，页面中包括但不限于商品名称、购买数量、应付总额、收货地址等信息，用户应仔细核对。<b
            ><u
              >用户点击订单底部的“提交订单”按钮，视为用户已阅读并同意外卖页面的所有内容并认可订单内容</u
            ></b
          ><b
            ><u
              >，该订单内容及外卖页面信息，视为用户与商户、配送服务商之间的外卖交易合同。</u
            ></b
          >
        </p>
        <p>
          <b>2.2.3</b
          ><b
            ><u
              >支付价款：上述外卖合同成立后，用户应根据付款页面提示完成外卖价款的支付。在用户支付外卖价款前商家不会向用户提供外卖商品</u
            ></b
          ><b><u>/</u></b
          ><b
            ><u
              >服务，除非平台或商家在特定商品或服务页面中特别明示说明之外。</u
            ></b
          >
        </p>
        <p><b>2.2.4</b>配送服务：</p>
        <p>
          <!--[if-->（1）<!--[endif]--><b
            >商户确认用户订单后，选择外卖配送的订单将由配送员完成配送服务</b
          >。用户同意并知晓，部分商品由商户向您提供送货服务，在此情况下，送货服务相关标准按该商户在平台公示的标准执行（包括但不限于费用标准、送货时限等）；配送员向您提供配送服务，平台向您收取一定的配送服务费用，具体金额以您下单时系统显示的配送金额为准。
        </p>
        <p>
          <!--[if-->（2）<!--[endif]-->用户知悉、理解并同意，基于外卖服务配送服务特性，“预计送达时间”为系统根据配送距离、商品/服务准备时间、用户评价时间等因素进行综合计算得到的参考时间，实际配送时间会受到交通情况、当天天气、订单量、商家实际出餐时间等因素影响而不同。<b
            ><u>“预计送达时间”不作为</u></b
          ><b><u>平台</u></b
          ><b><u>或商家对用户做出的配送时间承诺。</u></b>
        </p>
        <p>
          <!--[if-->（3）<!--[endif]-->用户理解并同意，在平台合同成立且用户付款的情形下，平台将协调相应配送资源向用户提供配送服务，配送服务费用（如有）具体金额以用户已确认并提交的订单上显示金额为准。用户知悉并授权平台协调相关配送资源（如适用）向商家取货并将商品配送至用户指定收货地址，授权通过平台向配送服务提供方支付相关配送费用（如有）。
        </p>
        <p>
          （4）<b
            ><u
              >如用户付款成功后，因配送运力不足、不可抗力等导致无法履约时，本次外卖服务合同解除，用户有权要求商家进行退款。</u
            ></b
          >
        </p>
        <p>
          <b>2.2.5</b
          >评价和投诉：用户有权利对平台商户的服务做出评价和投诉，并提出建议和意见。用户的评价行为应当遵守相关法律法规及平台规则的要求。
        </p>
        <p>
          <b>2.2.6</b>请求协调纠纷：<b><u>用户在</u></b
          ><b><u>平台</u></b
          ><b
            ><u
              >交易过程中如与商户因交易产生纠纷，可以请求平台运营方予以协调解决。</u
            ></b
          >对因商户或配送团队原因在整个物流配送过程中导致的货物损坏、损毁、灭失等情况，将由对应责任方承担赔偿责任，平台将根据平台规则，积极配合用户对该部分损失进行追偿。<b
            ><u>用户如发现其他用户有违法或违反本服务协议的行为，可以向</u></b
          ><b><u>平台</u></b
          ><b
            ><u
              >进行反映要求处理。如用户因交易与其他用户产生诉讼的，用户有权通过司法部门要求</u
            ></b
          ><b><u>平台</u></b
          ><b><u>提供相关资料。</u></b>
        </p>
        <p><b>2.3</b><b>用户的义务</b></p>
        <p>
          <b>2.3.1</b
          >用户有义务在注册及使用平台时提供自己的真实资料，并保证联系电话、收货地址等内容的有效性及准确性，保证平台及其他用户可以通过以上联系方式与用户联系。同时，<b
            ><u
              >用户也有义务在相关资料实际变更时及时更新有关资料。用户保证不以冒用他人资料在</u
            ></b
          ><b><u>平台</u></b
          ><b
            ><u
              >进行注册。若用户使用虚假电话、姓名、收货地址或冒用他人信息使用</u
            ></b
          ><b><u>平台</u></b
          ><b><u>服务，</u></b
          ><b><u>平台</u></b
          ><b><u>有权屏蔽用户信息或做出其他的处理</u></b
          >。
        </p>
        <p>
          <b>2.3.2</b
          >为确保评价内容能够为消费者购物决策提供可靠的依据，反映商品及服务的真实情况，用户的评价应当客观、真实，与交易的商品及/或服务具有关联性。<b
            ><u>用户不应该在</u></b
          ><b><u>平台</u></b
          ><b
            ><u
              >恶意评价其他商户、采取不正当手段提高自身的信用度或降低其他商户的信用度</u
            ></b
          ><u>，</u
          ><b
            ><u
              >评价信息不应该包含任何污言秽语、色情低俗、广告信息或法律法规与本协议、《</u
            ></b
          ><b><u>平台</u></b
          ><b><u>用户评价规范》等</u></b
          ><b><u>平台</u></b
          ><b><u>规则列明的其他禁止性信息</u></b
          >。<b><u>如用户评价违反法律法规、社会公共利益及《</u></b
          ><b><u>平台</u></b
          ><b><u>用户评价饭》等</u></b
          ><b><u>平台</u></b
          ><b><u>相关规则，</u></b
          ><b><u>平台</u></b
          ><b
            ><u
              >有权对用户实施上述行为所产生的评价信息采取必要处置措施，包括但不限于删除相关评价内容</u
            ></b
          >。
        </p>
        <p>
          <b>2.3.3</b><b><u>用户承诺在使用</u></b
          ><b><u>平台</u></b
          ><b><u>服务过程中，遵守法律法规及</u></b
          ><b><u>平台</u></b
          ><b><u>规则，以诚实信用为基础使用</u></b
          ><b><u>平台</u></b
          ><b><u>服务，不会出现违反诚实信用原则、法律法规及</u></b
          ><b><u>平台</u></b
          ><b><u>规则的情形，该等违规情形包括但不限于</u></b
          >：
        </p>
        <p>（1）虚假交易、制造虚假订单、虚假分享、恶意评论；</p>
        <p>（2）滥用用户权利、侵犯他人合法权利；</p>
        <p>
          （3）无正当理由批量购买后批量申请退款、利用系统漏洞获取利润、违规套现等影响平台提供正常服务或妨碍平台运营秩序的行为；
        </p>
        <p>
          （4）其他违反诚实信用原则，或损害平台、商户、其他用户或第三方合法权益的行为；
        </p>
        <p>
          （5）其他违反国家法律法规及平台规定要求，或损害各种社会公共利益或公共道德的行为。
        </p>
        <p>
          <b
            ><u
              >如出现以上违规行为，用户需承担相应法律责任；如因用户违规行为给</u
            ></b
          ><b><u>平台</u></b
          ><b><u>和商家造成损失的，用户应当承担赔偿责任</u></b
          ><u>。</u><b><u>此外，根据具体违法违规情形，</u></b
          ><b><u>平台</u></b
          ><b
            ><u
              >有权做出独立判断，立即暂停或终止对用户提供部分或全部服务，包括锁定、注销、删除账号等措施，或者根据法律法规或者平台规则采取其他措施。</u
            ></b
          ><b><u></u></b>
        </p>
        <p>
          <b>2.3.4</b><b><u>用户同意，不得对</u></b
          ><b><u>平台</u></b
          ><b><u>上任何资料作商业性利用，包括但不限于在未经</u></b
          ><b><u>平台</u></b
          ><b><u>事先书面同意的情况下，以复制、传播、传销等方式使用</u></b
          ><b><u>平台</u></b
          ><b><u>上展示的任何资料</u></b
          >。
        </p>
        <p>
          <b>2.3.5</b><b><u>用户同意接收来自美滋平台发出的</u></b
          ><b><u>短信</u></b
          ><b><u>信息。如果您不愿意接受此类信息，您有权通过</u></b
          ><b><u>平台</u></b
          ><b><u>提供的相应退订方式进行退订。</u></b>
        </p>
        <p><b>三、</b><b>平台</b><b>的权利和义务</b></p>
        <p><b>3.1</b><b>平台</b><b>的权利</b></p>
        <p>
          <b>3.1.1</b
          >用户在平台交易过程中如与商户、其他用户或第三方因交易产生纠纷，请求平台协调解决时，经平台审核后，平台有权通过电话、微信或其他合理方式向纠纷双方了解情况，并将所了解的情况通过电话、微信或其他合理方式通知对方。
        </p>
        <p>
          <b>3.1.2</b
          >平台有权对用户的注册资料及交易行为进行查阅，发现注册资料或交易行为中存在违反国家法律法规或平台规则时，平台有权根据相关规定进行处理。
        </p>
        <p>
          <b>3.1.3</b
          >许可使用权。对于用户提供、发布及在使用平台服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，<b
            ><u>在法律规定的保护期限内用户免费授予</u></b
          ><b><u>平台</u></b
          ><b
            ><u
              >及其关联公司获得许可使用权利及再授权给第三方使用并可以自身名义对第三方侵权行为取证及提起诉讼的权利。</u
            ></b
          >
        </p>
        <p><b>3.2</b><b>平台</b><b>的义务</b></p>
        <p>
          <b>3.2.1</b
          >平台有义务在现有技术上维护整个交易平台的正常运行，并努力提升和改进技术，使用户网上订购交易活动得以顺利进行。
        </p>
        <p>
          <b>3.2.2</b
          >对用户在注册使用平台中所遇到的与交易或注册有关的问题及反映的情况，平台应及时做出回复。
        </p>
        <p>
          <b>3.2.3</b
          >用户因在平台进行的订购交易与其他用户产生诉讼的，用户通过司法部门或行政部门依照法定程序要求平台提供相关资料，平台应积极配合并提供有关资料。
        </p>
        <p><b>四、隐私权政策</b></p>
        <p>
          平台非常重视用户个人信息的保护，<b
            ><u
              >根据有关法律法规要求，网络产品、服务具有收集用户信息功能的，其提供者应当向用户明示并取得同意。</u
            ></b
          ><b><u>平台</u></b
          ><b
            ><u
              >特此通过单独明示的《隐私权政策》明确向您告知收集、使用用户个人信息的目的、方式和范围，查询、更正信息的渠道以及拒绝提供信息的后果。</u
            ></b
          >平台希望通过隐私权政策向用户清楚地介绍平台对用户个人信息的处理方式，因此平台建议您完整地阅读《隐私权政策》，以帮助您更好地保护您的隐私权。
        </p>
        <p><b>&nbsp;</b></p>
        <p><b>五、用户违约及处理</b></p>
        <p><b>5.1</b>发生如下情形之一的，视为用户违约：</p>
        <p><b>（1）</b>使用我方平台服务时违反有关法律法规规定的；</p>
        <p><b>（2）</b>违反本协议或本协议补充协议约定的。</p>
        <p>
          <b
            ><u
              >为适应互联网行业发展和满足海量用户对高效优质服务的需求，您理解并同意，</u
            ></b
          ><b
            ><u
              >平台可在平台规则中约定违约认定的程序和标准。您有权利对您的异常现象进行充分举证和合理解释，否则将被认定为违约。</u
            ></b
          ><b></b>
        </p>
        <p><b>5.2</b><b>违约处理措施</b></p>
        <p>
          <b>5.2.1</b
          >用户在平台上发布的内容和信息构成违约的，平台有权根据相应规则立即对相应内容和信息进行删除、屏蔽等处理或对您的账号进行暂停使用、查封、注销等处理。平台根据法律法规的明确规定承担相应的责任。
        </p>
        <p>
          <b>5.2.2</b
          >用户在平台上实施的行为，或虽未在平台上实施但对平台及其用户产生影响的行为构成违约的，<b
            ><u>平台</u></b
          ><b
            ><u
              >可依据相应规则对用户的账号执行限制参加活动、中止向您提供部分或全部服务等处理措施。如您的行为构成根本违约的，</u
            ></b
          ><b><u>平台</u></b
          ><b><u>可查封您的账号，终止向您提供服务。</u></b>
        </p>
        <p>
          <b>5.2.3</b
          >如果用户在平台上的行为违反相关的法律法规，平台可依法向相关主管机关报告并提交您的使用记录和其他信息。
        </p>
        <p>
          <b>5.3</b><b><u>如用户的行为使</u></b
          ><b><u>平台</u></b
          ><b
            ><u
              >及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿平台/或其关联公司的上述全部损失。如您的行为使平台及/或其关联公司遭受第三人主张权利，平台承担金钱给付等义务后就全部损失向您追偿。</u
            ></b
          ><b><u></u></b>
        </p>
        <p><b>六、协议的终止</b></p>
        <p><b>6.1</b>用户有权通过以下任何方式终止本协议：</p>
        <p>
          <!--[if-->（1）&nbsp;<!--[endif]-->在满足平台公示的账号注销条件时您通过平台注销您的账号的；
        </p>
        <p>
          <!--[if-->（2）&nbsp;<!--[endif]-->变更事项生效前停止使用并明示不愿接受变更事项的；
        </p>
        <p>
          <!--[if-->（3）&nbsp;<!--[endif]-->明示不愿继续使用平台服务，且符合平台终止条件的。
        </p>
        <p><b>6.2</b>出现以下情况时，平台可以通知用户终止本协议：</p>
        <p>
          <!--[if-->（1）&nbsp;<!--[endif]-->您违反本协议约定，平台依据违约条款终止本协议的；
        </p>
        <p>
          <!--[if-->（2）&nbsp;<!--[endif]-->您盗用他人账号、发布违禁信息、骗取他人财物、扰乱市场秩序、采取不正当手段谋利等行为，平台依据平台规则对您的账号予以查封的；
        </p>
        <p>
          <!--[if-->（3）&nbsp;<!--[endif]-->除上述情形外，因您多次违反平台规则相关规定且情节严重，平台依据平台规则对您的账号予以查封的；
        </p>
        <p>
          <!--[if-->（4）&nbsp;<!--[endif]-->用户的账号被平台依据本协议进行注销等清理的；
        </p>
        <p>
          <!--[if-->（5）&nbsp;<!--[endif]-->用户在平台有侵犯他人合法权益或其他严重违法违约行为的；
        </p>
        <p>
          <!--[if-->（6）&nbsp;<!--[endif]-->其它根据相关法律法规平台应当终止服务的情况。
        </p>
        <p>
          <b>6.3</b><b>&nbsp;</b
          ><b><u>本协议终止后，除法律法规另有规定外，原则上</u></b
          ><b><u>平台</u></b
          ><b><u>无义务向您或您指定的第三方披露您账号中的任何信息。</u></b>
        </p>
        <p><b>6.4</b>本协议终止后，平台仍享有下列权利：</p>
        <p>
          《电子商务法》第三十一条电子商务平台经营者应当记录、保存平台上发布的商品和服务信息、交易信息，并确保信息的完整性、保密性、可用性。商品和服务信息、交易信息保存时间自交易完成之日起不少于三年；法律、行政法规另有规定的，依照其规定。&nbsp;
        </p>
        <p><b>七、责任范围</b></p>
        <p>
          <b
            ><u
              >平台依照法律规定履行基础保障义务，但对于下述原因导致的协议履行障碍、履行瑕疵、履行延后或履行内容变更等情形，平台并不承担相应的违约责任：</u
            ></b
          ><b><u></u></b>
        </p>
        <p>
          <b><u>（</u></b
          ><u>1）</u><u> </u
          ><u
            >因自然灾害、疫情防控、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</u
          ><u></u>
        </p>
        <p>
          <u>（2）</u><u> </u
          ><u>因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</u
          ><u></u>
        </p>
        <p>
          <u
            >出现上述情况时，美滋来将努力在第一时间与相关部门配合，及时进行修复，但是由此给您造成的损失，美滋来在法律允许的范围内免责。</u
          >
        </p>
        <p><b>八、附则</b></p>
        <p>
          <b>8.1</b
          ><u
            >本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律；如法律无相关规定的，参照商业惯例及</u
          ><u>/</u><u>或行业惯例。</u>
        </p>
        <p>
          <b>8.2</b><u>用户因使用</u><u>平台</u><u>服务所产生及与</u><u>平台</u
          ><u>服务有关的争议，由</u><u>平台</u
          ><u
            >与用户协商解决。协商不成时，任何一方均可向被告所在地人民法院提起诉讼。</u
          >
        </p>
        <p>
          <b>8.3</b
          >如用户对本服务协议有任何问题或建议，可通过平台客服与我们取得联系。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  created() {
    this.title = this.$route.query.data;
  },
  methods: {},
};
</script>
