<style scoped>
body {
    background-color: #EFF1F6;
}
td{border: #000000 solid 1px;margin: 0px;}
.content {
    max-width: 500px;
    width: 100%;
    /* padding: 15px; */
    margin: 15px;
    background-color: #fff;
    border-radius: 15px;
}
.contet_title{
    font-size: 24px;
    padding-top: 15px;
    padding-bottom: 40px;
}
.m-1 {
    margin: 20px;
}

.text-algin {
    text-align: center;
}

.text-bold {
    font-weight: 600;
}
</style>


<template>
    <div style="display: flex;justify-content: center;">
        <div class="content">
            <div class="m-1">
                <div class="text-algin text-bold contet_title">{{ title }}</div>
            </div>
            <div class="m-1">
                <p><b>
                        <font color="#000000">&nbsp;</font>
                    </b></p>
                <h2>
                    <font color="#000000"><a style="">第一章&nbsp;</a>总则</font>
                </h2>
                <p>
                    <font color="#000000">&nbsp;</font>
                </p>
                <h3><a>
                        <font color="#000000">一、概述</font>
                    </a></h3>
                <p>
                    <font color="#000000">1.
                        为规范美滋来平台所有商户的经营与服务行为，营造合法、和谐的平台经营环境，维护消费者的权益，根据国家法律法规，以及《美滋来服务合同》、平台管理规范等，制定本规范。</font>
                </p>
                <p>
                    <font color="#000000">1. 商户在美滋来的经营行为应当遵守本规范。商户同意美滋来有权对商户经营行为进行指导、监督、管控等。任何违反本规范而发生的行为，美滋来有权对商户进行规则管控及处罚。
                    </font>
                </p>
                <h3><a>
                        <font color="#000000">二、适用范围</font>
                    </a></h3>
                <p>
                    <font color="#000000">本管理规范适用于美滋来平台的所有商户。</font>
                </p>
                <h3><a>
                        <font color="#000000">三、定义</font>
                    </a></h3>
                <p>
                    <font color="#000000">1. 美滋来平台：指为商户提供外卖展示及交易信息的网络服务平台，包括但不限于美滋来小程序、美滋来客户端（安卓，Ios）等。</font>
                </p>
                <p>
                    <font color="#000000">2. 商户：指在美滋来平台开展经营活动并向用户提供商品、服务的法人、自然人或其他组织。</font>
                </p>
                <p>
                    <font color="#000000">3. 用户：指为生活消费需要，在美滋来平台注册、接受平台规则，并可通过美滋来平台与商户达成订单的消费者。</font>
                </p>
                <p>
                    <font color="#000000">&nbsp;</font>
                </p>
                <h2>
                    <font color="#000000"><a>第二章&nbsp;</a>商户服务规范</font>
                </h2>
                <h3>
                    <font color="#000000">&nbsp;<a>一、基础服务规范</a></font>
                </h3>
                <p>
                    <font color="#000000">1. 入驻要求</font>
                </p>
                <p>
                    <font color="#000000">商户应提供有效的营业执照、食品经营许可证、法定代表人/商户负责人手持身份证件等，详情请参考《入网餐饮服务提供者审查登记规范》。</font>
                </p>
                <p>
                    <font color="#000000">2. 商户服务态度</font>
                </p>
                <p>
                    <font color="#000000">商户应保持积极、端正的服务态度，遵守美滋来商户服务规范要求，不得出现骚扰、辱骂用户等情况。</font>
                </p>
                <p>
                    <font color="#000000">3. 营业时间</font>
                </p>
                <p>
                    <font color="#000000">商户应合理设置营业时间，不得发生线上店铺正常营业但线下店铺无法提供正常备餐及配送的情况。</font>
                </p>
                <p>
                    <font color="#000000">4. 联系电话</font>
                </p>
                <p>
                    <font color="#000000">商户应保证平台预留电话信息正确，在营业时间内应电话畅通，以便用户来电咨询。</font>
                </p>
                <p>
                    <font color="#000000">5. 库存</font>
                </p>
                <p>
                    <font color="#000000">商户应合理设置并及时查看线上商品的库存情况，若库存不足，务必及时下线该商品。</font>
                </p>
                <p>
                    <font color="#000000">6. 商品与描述一致</font>
                </p>
                <p>
                    <font color="#000000">
                        商户在平台发布商品的信息描述应与实际保持一致，文字应包含与商品相关的描述信息，包括规格（重量/数量）、食材原料、加工工艺等。详情请查看《美滋来平台信息发布管理规范》。</font>
                </p>
                <p>
                    <font color="#000000">7. 商品与图片一致</font>
                </p>
                <p>
                    <font color="#000000">
                        平台发布商品图片应采用店铺自制商品真实拍摄，不得使用与实物不符的虚假或网络图片。商品图片不得打马赛克，不得有水印及其他品牌logo（商户水印/logo除外），不得侵犯第三方著作权等合法权益，详情请查看《美滋来平台信息发布管理规范》。
                    </font>
                </p>
                <p>
                    <font color="#000000">8. 价格设置</font>
                </p>
                <p>
                    <font color="#000000">
                        商户在美滋来平台发布的商品、运费、餐盒等价格，不得违反法律法规以及与美滋来签订相应协议或平台页面说明、规则中关于价格的相关规定/约定，包括但不限于：相同商品价格高于其他平台、高于店铺实际定价或虚高原价后再打折促销等违反价格法律法规情形/场景。详情请查看《美滋来商户违规作弊管理规范》。
                    </font>
                </p>
                <p>
                    <font color="#000000">9. 发票服务</font>
                </p>
                <p>
                    <font color="#000000">商户应按照相关法律法规的要求为用户开具真实、完整、正确的发票，不得提供虚假发票。</font>
                </p>
                <h3><a>
                        <font color="#000000">二、运营服务规范</font>
                    </a></h3>
                <p>
                    <font color="#000000">1. 接单服务规范</font>
                </p>
                <p>
                    <font color="#000000">2. 接单</font>
                </p>
                <p>
                    <font color="#000000">普通单：若商户设置手动接单，应随时关注订单情况，在用户下单5分钟内进行接单。若商户设置自动接单，应随时关注订单，避免丢单等情况。</font>
                </p>
                <p>
                    <font color="#000000">预订单：商户开始营业（以商户设置的营业时间为准）时，系统会自动推送预订单，请商户务必在5分钟内进行接单并在预定时间的前一个小时准备出餐，保证饭菜口感质量。
                    </font>
                </p>
                <p>
                    <font color="#000000">3. 取消订单</font>
                </p>
                <p>
                    <font color="#000000">商户在平台设置正常上线后，在营业时间内不得随意取消用户订单。</font>
                </p>
                <p>
                    <font color="#000000">4. 出餐服务规范</font>
                </p>
                <p>
                    <font color="#000000">5. 商品质量</font>
                </p>
                <p>
                    <font color="#000000">商户备餐时应使用新鲜、卫生的食材，不得出现有异物、食品安全等问题。</font>
                </p>
                <p>
                    <font color="#000000">6. 餐盒质量</font>
                </p>
                <p>
                    <font color="#000000">6.1 商户应使用符合国家和行业标准的无毒、清洁的食品容器、餐具和包装材料，保证餐盒的保温/冷鲜能力，为用户提供良好的用餐体验。</font>
                </p>
                <p>
                    <font color="#000000">6.2 商户在出餐时务必查看包装的密封性，避免餐盒不严密造成商品撒漏的情况。</font>
                </p>
                <p>
                    <font color="#000000">7. 查看备注</font>
                </p>
                <p>
                    <font color="#000000">商户在备餐时应仔细查看订单备注，根据用户备注准备商品，如有不能满足的备注条件，应及时与用户沟通并确认后进行出餐准备。</font>
                </p>
                <p>
                    <font color="#000000">8. 餐具/纸巾准备</font>
                </p>
                <p>
                    <font color="#000000">商户出餐前应确认已为订单配备餐具与纸巾。对于“不需要餐具”订单（用户选择不要餐具）请不要配备餐具，避免浪费。</font>
                </p>
                <p>
                    <font color="#000000">9. 出餐准时</font>
                </p>
                <p>
                    <font color="#000000">商户应根据向用户和平台承诺出餐时长进行备餐，避免由于出餐延迟导致用户投诉和违反平台规则被管控处罚。</font>
                </p>
                <h3><a>
                        <font color="#000000">三、售后服务规范</font>
                    </a></h3>
                <p>
                    <font color="#000000">1. 评价回复</font>
                </p>
                <p>
                    <font color="#000000">
                        商户应每日查看所有订单评价，对差评订单务必进行及时回复与处理，回复内容需使用礼貌用语，不得出现恶意辱骂用户、违法、涉黄、人身攻击、泄露个人信息等内容；同时商户应改进自身服务环节的问题，不得发生任何骚扰用户的行为。
                    </font>
                </p>
                <p>
                    <font color="#000000">2. 在线回复及时率</font>
                </p>
                <p>
                    <font color="#000000">针对用户的在线咨询，商户应在5分钟内进行解答。</font>
                </p>
                <p>
                    <font color="#000000">3. 退货、退款处理</font>
                </p>
                <p>
                    <font color="#000000">3.1 用户因个人原因退款</font>
                </p>
                <p>
                    <font color="#000000">
                        用户因个人原因申请退款的，如用户在商户备餐前发起的退款，商户应该同意；如用户在商户备餐后发起的退款，商户需要根据自身情况判断是否可以通过，拒绝用户退款前，应和用户沟通确认。</font>
                </p>
                <p>
                    <font color="#000000">用户因个人原因申请退款的情况包含：选择计划有变，不想要了、买错了，买多了，买少了、地址，电话填写有误、送达时间选错了、预计送达时间久，不想要了。</font>
                </p>
                <p>
                    <font color="#000000">3.2 用户因商户原因退款</font>
                </p>
                <p>
                    <font color="#000000">商户应进行自查，满足用户的合理诉求；用户诉求不合理的，应和用户沟通确认后再拒绝；如果用户被拒绝后申请客服介入处理，商户应该配合客服调查，不得提供虚假证据。
                    </font>
                </p>
                <p>
                    <font color="#000000">
                        用户因商户原因申请退款的情况包含：商户通知用户卖完了、商品质量有问题、商户错送商品、商户沟通态度差、商户没有给承诺的优惠、无法开发票、商品与商户设置的页面描述不符等。</font>
                </p>
                <p>
                    <font color="#000000">3.3 用户因配送原因退款（限美滋来配送商户）</font>
                </p>
                <p>
                    <font color="#000000">商户应和配送站点沟通确认情况，确认为配送原因的，建议优先同意退款，然后在外卖商户客户端后台发起餐损。</font>
                </p>
                <p>
                    <font color="#000000">用户因配送原因申请退款的情况包含：送太慢了，等太久了、商品撒漏、骑手通知用户无法配送等。因配送原因退款的情况仅限于美滋来配送商户。</font>
                </p>
                <p>
                    <font color="#000000">3.4 投诉/重大投诉</font>
                </p>
                <p>
                    <font color="#000000">商户应端正态度，正面处理用户的投诉，积极配合平台给出合理的解决方案。</font>
                </p>
                <p>
                    <font color="#000000">&nbsp;</font>
                </p>
                <h3><a>
                        <font color="#000000">四、交易规则</font>
                    </a></h3>
                <p>
                    <font color="#000000">商户交易规则详情参考《美滋来平台交易规则》中商户部分。</font>
                </p>
                <h2></h2>
                <h2>
                    <font color="#000000"><a>第三章&nbsp;</a>商户服务履约规则</font>
                </h2>
                <h3><a>
                        <font color="#000000">一、管控处理</font>
                    </a></h3>
                <p>
                    <font color="#000000">商户违反本规范上述规定，则按照如下管控措施处理：</font>
                </p>
                <p>
                    <font color="#000000">1. 不接单管控规则</font>
                </p>
                <p>
                    <font color="#000000">1.1 定义：当商户管理后台获取到用户提交的订单后，商户不接单或点击“拒单”或用户投诉商户私自取消订单，平台会针对商家进行不接单规则管控。</font>
                </p>
                <p>
                    <font color="#000000">1.2 具体：</font>
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p>
                                    <font color="#000000">管控范围</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">管控类型</font>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <font color="#000000">大于10%小于等于20%</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">高峰时段置休2h，周一13:00-15:00&nbsp;执行</font>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <font color="#000000">大于20%小于等于50%</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">高峰时段置休4h，周一12:00-16:00&nbsp;执行</font>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <font color="#000000">大于50%</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">置休1天（24小时），周一19:00-二19:00执行</font>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    <font color="#000000">2. 出餐慢管控规则</font>
                </p>
                <p>
                    <font color="#000000">2.1 定义：当商户管理后台获取到用户提交的订单后开始备餐，商户需在承诺出餐时间内提供餐食给骑手进行配送，平台会针对商户出餐履约情况进行考核。</font>
                </p>
                <p>
                    <font color="#000000">2.2 具体：当商户自然周内的出餐准时率&lt;50%时，次周平台会对门店置休一天，周三00:00-24:00。</font>
                </p>
                <p>
                    <font color="#000000">3. 投诉管控规则</font>
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p>
                                    <font color="#000000">投诉类型</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">管控范围</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">管控类型</font>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="2">
                                <p>
                                    <font color="#000000">商品安全问题</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">1、重大食品安全投诉，指用户因食用商户提供的餐品（食品）导致用户发生疾患并采取住院治疗（含以上）的情况；</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">重大安全事故：店铺立即下线</font>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <font color="#000000">2、一般食品安全投诉，指用户因食用商户提供的餐品（食品）导致用户发生疾患，但未住院的情况；</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">一般安全事故：第一次置休3天、第二次7天、第三次下线</font>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="2">
                                <p>
                                    <font color="#000000">商户态度恶劣</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">1、重大安全事故：在为用户提供服务的过程中或提供服务之后，出现辱骂、诅咒、骚扰、殴打客户</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">重大安全事故：店铺立即下线</font>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <font color="#000000">2、一般安全事故：在评论区辱骂用户或&nbsp;商家通过微博、微信、QQ、电话、短信等途径辱骂用户（用户能提供证据截图、录音等）
                                    </font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">一般安全事故：第一次置休3天、第二次7天、第三次下线</font>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <font color="#000000">用户投诉商户发票问题</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">商户提供假发票，金额/信息错误，或承诺用户开发票但未履约等情况</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">第一次置休4小时、第二次1天、第三次及以上3天</font>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <font color="#000000">取消不告知</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">商户接单后，未联系用户进行沟通，直接取消订单</font>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <font color="#000000">第一次置休4小时、第二次1天、第三次及以上3天</font>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <font color="#000000">商品质量问题</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">用户发现商户提供餐品（食品）有安全问题，但未造成用户身体不适的情况，包含：食品中存在异物、食材安全（变质／发霉）等</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">第一次置休4小时、第二次1天、第三次及以上3天</font>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <font color="#000000">商品已售完</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">用户投诉，商户商品售完未线下，继续售卖</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">第一次置休2个小时、第二次4个小时、第三次及以上1天</font>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <font color="#000000">图片不符</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">商品图片与实物严重不符（品类／数量／大小／颜色等）</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">第一次置休2个小时、第二次4个小时、第三次及以上1天</font>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <font color="#000000">商品未按备注制作</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">商户没有按照用户的订单备注制作商品</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">第一次置休2个小时、第二次4个小时、第三次及以上1天</font>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <font color="#000000">营业时间内打烊</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">用户在商户在线营业时间内下单，商户告知已打烊</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">第一次置休2个小时、第二次4个小时、第三次及以上1天</font>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <font color="#000000">联系不上商户</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">用户下单后联系不上商户，投诉到客服，经客服确认且1小时内2次均联系不上商户的情况</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">第一次置休2个小时、第二次4个小时、第三次及以上1天</font>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <font color="#000000">不配合处理客诉</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">不配合处理客诉，指用户在实际消费的过程中因少送、错送、等原因而向美滋来提起投诉，商家不配合处理的行为</font>
                            </p>
                        </td>
                        <td>
                            <p>
                                <font color="#000000">第一次置休2个小时、第二次4个小时、第三次及以上1天</font>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3><a>
                    <font color="#000000">二、申诉处理</font>
                </a></h3>
            <p>
                <font color="#000000">1.
                    针对美滋来给予商户违规行为的管控处理，如商户认为不合法或不合理的，则有权依据本规范或其他相关规范按照申诉标准对可申诉情况向美滋来提起违规申诉，如商户未在规定时间内进行违规申诉，视为商户认可管控处理。
                </font>
            </p>
            <p>
                <font color="#000000">2.
                    为了保证申诉的有效性，提高申诉成功率，商户申诉时需按照美滋来平台的要求提供完整、真实、有效的证明材料，包括但不限于：聊天记录、截图、链接、照片、录音、订单凭证、报案回执等。如商户提供虚假证明材料的，即视为申诉不成功。
                </font>
            </p>
            <p>
                <font color="#000000">3.
                    美滋来在收到商户提起申诉后，将及时审核商户提交的申诉材料并反馈相应处理结果。若违规申诉成功，则对应的管控处理不成立、处罚措施不执行；若申诉不成功，则对应的违规处罚成立、处罚措施将直接执行。
                </font>
            </p>
            <p>
                <font color="#000000">&nbsp;</font>
            </p>
            <h2>
                <font color="#000000"><a>第四章&nbsp;</a>其他&nbsp;</font>
            </h2>
            <h3><a>
                    <font color="#000000">一、规范变更</font>
                </a></h3>
            <p>
                <font color="#000000">
                    美滋来有权对本规范内容进行修改、补充或更新，并在该规范生效前通过美滋来商户后台管理中心、美滋来网站公告等方式予以公告，无需另行单独通知商户，上述公告一经发布即视为通知所有使用美滋来服务的商户；若商户在本规范内容修改、补充或更新公告后继续使用美滋来提供的服务，即视为商户同意遵守修改、补充或更新的规范。
                </font>
            </p>
            <h3><a>
                    <font color="#000000">二、规范效力</font>
                </a></h3>
            <p>
                <font color="#000000">本规范作为商户与美滋来所签署协议的附件，与协议具有相同法律效力。商户违反本规范的，按照本规范、双方协议及其他管理规范进行处罚。</font>
            </p>
            <h3><a>
                    <font color="#000000">三、规范溯及力</font>
                </a></h3>
            <p>
                <font color="#000000">发生在本规范生效之日前的行为，适用当时的规范进行处理；发生在本规范生效之日后的，适用本规范。</font>
            </p>
            <h3><a>
                    <font color="#000000">四、生效</font>
                </a></h3>
            <p>

            </p>
            <p>
                <font color="#000000">本规范发布日期为2019年1月1日，自发布之日起7日后生效。</font>
            </p>
        </div>

    </div>
</div></template>

<script>


export default {

    data() {
        return {
            title: '',

        }
    },
    created() {
        this.title = this.$route.query.data
    },
    methods: {

    }






}

</script>