
<template>
  <div class="model-content">
    <div class="model8" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'model_ug' : 'model_zh'">
      <div class="bg_img_container">
        <img v-if="posterList.lang == 'ug' || !posterList.lang" style="width: 100%" :src="moban_list.url_ug" />
        <img v-if="posterList.lang == 'zh'" style="width: 100%" :src="moban_list.url_zh" />
      </div>
      <div class="merchant_name">
        <div class="merchant_name_font">{{ posterList.merchant_name }}</div>
      </div>
      <div class="food_img">
        <img :src="posterList.food_img" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_18",
  data() {
    return {
      posterList: {},
      moban_list: {
        url_zh: require('../../img/poster/model_18_zh.jpg'),
        url_ug: require('../../img/poster/model_18_ug.jpg'),
      },
    };
  },
  created() {
    this.canvasfortitle(this.$route.query.merchant_name, this.$route.query.food_name, this.$route.query);
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      let res_name = merchant_name.replaceAll('(', '-')
      res_name = res_name.replaceAll('（', '-')
      res_name = res_name.replaceAll(')', ' ')
      res_name = res_name.replaceAll('）', ' ')
      this.posterList = query;
      this.posterList['merchant_name'] = res_name;
    },
  },
};
</script>

<style scoped>
.model-content {
  width: 750px;
  height: 320px;
  display: flex; 
  justify-content: center;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.model8 {
  width: 750px;
  height: 320px;
  position: relative;
  overflow: hidden;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.bg_img_container {
  width: 100%;
  height: 100%;
}

.bg_img_container img {
  width: 100%;
}

.merchant_name {
  width: 348px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  color: #cbb208;
  font-size: 32px;
  padding: 8px 12px;
  box-sizing: border-box;
}

.merchant_name {
  position: absolute;
  right: 246px;
  top: 48px;
}

.merchant_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.food_img {
  position: absolute;
  height: 100%;
  width: 280px;
  overflow: hidden;
  right: 0px;
  top: 0px;
}

.food_img img {
  width: 100%;
  height: 100%;
  clip-path: circle(83% at right center);
}

</style>