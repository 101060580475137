<template>
    <div class="model-content">
        <div class="model_55" :class="posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
            ">
            <div class="img-box">
                <img v-if="posterList.lang == 'ug' || !posterList.lang" style="width: 100%" :src="bg_list.url_ug" />
                <img v-if="posterList.lang == 'zh'" style="width: 100%" :src="bg_list.url_zh" />
            </div>

            <div class="merchant-name">
                <div class="merchant-name-title">{{ posterList.merchant_name }}</div>
            </div>

            <div class="merchant_img">
                <img :src="posterList.merchant_img" alt="" />
            </div>

            <div class="merchant_qrcode">
                <img :src="posterList.merchant_qrcode" alt="" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "model_55",
    data() {
        return {
            posterList: {},
            bg_list: {
                url_zh: require("../../img/poster/model_55_zh.jpg"),
                url_ug: require("../../img/poster/model_55_ug.jpg"),
            },
        };
    },
    created() {
        this.canvasfortitle(
            this.$route.query.merchant_name,
            this.$route.query.food_name,
            this.$route.query
        );
    },
    methods: {
        canvasfortitle(merchant_name, food_names, query) {
            let res_name = merchant_name.replaceAll("(", "-");
            res_name = res_name.replaceAll("（", "-");
            res_name = res_name.replaceAll(")", " ");
            res_name = res_name.replaceAll("）", " ");
            this.posterList = query;
            this.posterList["merchant_name"] = res_name;
        },
    },
};
</script>

<style scoped>
.model-content {
    width: 352px;
    height: 632px;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
}

.model_55 {
    width: 352px;
    height: 632px;
    position: relative;
}

.model_ug {
    direction: rtl;
}

.model_zh {
    direction: ltr;
}

.img-box {
    width: 100%;
    height: 100%;
}

.merchant-name {
    position: absolute;
    top: 310px;
    right: 50%;
    transform: translateX(50%);
    width: 260px;
    height: 56px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.merchant-name-title {
    font-size: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*垂直排列*/
    line-clamp: 2;
    -webkit-line-clamp: 2;
    /*只显示6行*/
    overflow: hidden;
    /*溢出隐藏*/
    text-overflow: ellipsis;
    /*省略号代替*/
    color: #ffffff;
}

.merchant_img {
    position: absolute;
    top: 223px;
    left: 127px;
    width: 96px;
    height: 86px;
    border-radius: 4px;
    overflow: hidden;
}

.merchant_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.merchant_qrcode {
    position: absolute;
    top: 367px;
    left: 94px;
    width: 165px;
    height: 165px;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
}

.merchant_qrcode img {
    width: 100%;
    height: 100%;
}
</style>