
<template>
  <div class="model-content" style="display: flex; justify-content: center;">
    <div class="model3" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'rtl' : ''">
      <div class="bg_img_container">
        <img style="width: 100%;" :src="moban_list.url" />
      </div>
      <div class="merchant_name">
        <div class="merchant_name_font">{{ posterList.merchant_name }}</div>
      </div>
      <div class="food_name">
        <div class="food_name_font">{{ posterList.food_name }}</div>
      </div>
      <div class="food_img">
        <img :src="posterList.food_img" alt="">
      </div>
      <div class="food_price">
        <div class="text food_price_ug" v-if="posterList.lang == 'ug' || !posterList.lang">
          <span>يۈەن</span>
        </div>
        <div class="text food_price_zh" v-if="posterList.lang == 'zh'">
          <span>元</span>
        </div>
        <div class="price">
          <span>{{ posterList.food_price }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      posterList: {},
      moban_list: {
        url: require('../../img/poster/model_12.jpg'),
      },
    };
  },
  created() {
    this.canvasfortitle(this.$route.query.merchant_name, this.$route.query.food_name, this.$route.query);
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      let res_name = merchant_name.replaceAll('(', '-')
      res_name = res_name.replaceAll('（', '-')
      res_name = res_name.replaceAll(')', ' ')
      res_name = res_name.replaceAll('）', ' ')
      let food_name = food_names.replaceAll('(', '-')
      food_name = food_name.replaceAll('（', '-')
      food_name = food_name.replaceAll(')', ' ')
      food_name = food_name.replaceAll('）', ' ')
      this.posterList = query;
      this.posterList['merchant_name'] = res_name;
      this.posterList['food_name'] = food_name;
    },
  },
};
</script>

<style scoped>
.model-content {
  width: 750px;
  height: 320px;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.model3 {
  width: 750px;
  height: 320px;
  position: relative;
  overflow: hidden;
}

.rtl {
  direction: rtl;
}

.bg_img_container {
  width: 100%;
  height: 100%;
}

.merchant_name {
  position: absolute;
  left: 60px;
  top: 1px;
  width: 356px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #15b92d;
  font-size: 22px;
  padding: 8px 36px;
  box-sizing: border-box;
}

.merchant_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
  text-align: center;
}

.food_name {
  position: absolute;
  top: 100px;
  left: 8px;
  color: #fff;
  width: 448px;
  height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 8px 30px;
  font-size: 22px;
  box-sizing: border-box;
}

.food_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
  text-align: center;
}

.food_price {
  position: absolute;
  bottom: 20px;
  left: 139px;
  width: 215px;
  height: 95px;
}

.food_price .text {
  position: absolute;
  left: 0;
  top: 0;
  width: 85px;
  height: 100%;
  font-size: 48px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.food_price .price {
  position: absolute;
  right: 1px;
  top: 0;
  width: 108px;
  height: 100%;
  font-size: 32px;
  font-weight: bold;
  color: #e4ff01;
  display: flex;
  justify-content: center;
  align-items: center;
}

.food_price_ug {
  margin-top: -10px;
}

.food_price .food_price_zh {
  font-weight: bold;
  left: 215px;
}

.food_img {
  position: absolute;
  right: 31px;
  top: 35px;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
}

.food_img img {
  width: 100%;
  height: 100%;
}
</style>