<template>
  <div class="model-content">
    <div
      class="model_64"
      :class="
        posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
      "
    >
      <img class="img-box" :src="url" />

      <div class="merchant-name">
        <div class="merchant-name-title">{{ posterList.merchant_name }}</div>
      </div>
      <div class="food_name">
        <div class="food_name_text">{{ posterList.food_name }}</div>
      </div>

      <div class="food_img">
        <img :src="posterList.food_img" alt="" />
      </div>

      <div class="food_price" :style="{ fontSize: posterList.fontSize + 'px' }">
        <span style="font-size: 20px; padding: 0 4px">￥</span
        >{{ posterList.food_price }}
      </div>

      <div class="old_price">
        <div class="old_price_text" v-if="posterList.lang == 'ug'">
          ئەسلى باھاسى :
        </div>
        <div class="old_price_text colors" v-if="posterList.lang == 'zh'">
          原价 :
        </div>
        <div class="line" :class="posterList.lang == 'zh' ? 'color-white' : ''">
          <span class="number-price"> {{ posterList.old_price }} </span>
          <span v-if="posterList.lang == 'ug'"> يۈەن </span>
          <span v-if="posterList.lang == 'zh'"> 元 </span>
        </div>
      </div>

      <div class="merchant_qrcode">
        <img :src="posterList.merchant_qrcode" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_64",
  data() {
    return {
      posterList: {},
      url: require("../../img/poster/model_65.jpg"),
    };
  },
  created() {
    this.canvasfortitle(
      this.$route.query.merchant_name,
      this.$route.query.food_name,
      this.$route.query
    );
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      // 替换商家名称和食物名称中的特定字符
      const replaceChars = (str) => {
        return str
          .replaceAll("(", "-")
          .replaceAll("（", "-")
          .replaceAll(")", " ")
          .replaceAll("）", " ");
      };

      // 处理商家名称和食物名称
      const res_name = replaceChars(merchant_name);
      const food_name = replaceChars(food_names);

      // 更新 posterList
      this.posterList = query;
      this.posterList.merchant_name = res_name;
      this.posterList.food_name = food_name;
      this.posterList.food_price = parseFloat(query.food_price);

      // 确定字体大小
      const priceLength = query.food_price.toString().length;
      let fontSize = 55;
      if (priceLength <= 2) {
        fontSize = 55;
      } else if (priceLength === 3) {
        fontSize = 50;
      } else if (priceLength === 4) {
        fontSize = 48;
      } else if (priceLength === 5) {
        fontSize = 44;
      }

      this.posterList.fontSize = fontSize;
    },
  },
};
</script>

<style scoped>
@import url("../../css/model_65/index.css");
.model-content {
  width: 388px;
  height: 737px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
}

.model_64 {
  width: 388px;
  height: 737px;
  position: relative;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.img-box {
  width: 100%;
  height: 100%;
}

.merchant-name {
  position: absolute;
  top: 100px;
  right: 50%;
  transform: translateX(49%);
  color: #fff;
  width: 300px;
  height: 56px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.merchant-name-title {
  font-size: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0px 4px 3px #1a1a1a;
  font-weight: bold;
}
.model_ug .merchant-name-title {
  font-family: "Nurjanbay Kufi Chong" !important;
}
.model_zh .merchant-name-title {
  font-family: "FZCQK--GBK1-0" !important;
  line-height: 30px;
}
.food_name {
  position: absolute;
  width: 200px;
  height: 56px;
  top: 220px;
  left: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.food_name_text {
  font-size: 20px;
  color: #000000;
  text-shadow: 2px 2px 0 #ffff00, /* 黄色阴影 */ -2px -2px 0 #ffff00,
    2px -2px 0 #ffff00, -2px 2px 0 #ffff00;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.model_ug .food_name_text {
  font-family: "Nurjanbay Kufi Chong" !important;
}
.model_zh .food_name_text {
  font-family: "FZCQK--GBK1-0" !important;
  line-height: 30px;
}

.food_img {
  position: absolute;
  top: 290px;
  left: 12px;
  width: 277px;
  height: 260px;
  overflow: hidden;
}

.food_img img {
  width: 100%;
  height: 100%;
  clip-path: ellipse(50% 50% at 50% 50%);
  object-fit: cover;
}

.food_price {
  position: absolute;
  top: 274px;
  right: -4px;
  width: 133px;
  text-align: center;
  font-weight: bold;
  transform: rotate(-34deg);
  color: #ffff00;
  text-shadow: 2px 2px 0 #000000, /* 黄色阴影 */ -2px -2px 0 #000000,
    2px -2px 0 #000000, -2px 2px 0 #000000;
  font-family: "UzluqEgme" !important;
}
.old_price {
  position: absolute;
  bottom: 156px;
  font-size: 15px;
  color: #fff;
  display: flex;
  align-items: center;
}

.old_price .line {
  text-decoration: line-through;
  padding-inline: 4px;
  color: #000;
  font-weight: bold;
}

.model_ug .old_price {
  right: 162px;
}

.model_zh .old_price {
  left: 105px;
}

.merchant_qrcode {
  position: absolute;
  bottom: 24.5px;
  left: 41px;
  width: 94px;
  height: 94px;
  box-sizing: border-box;
}

.merchant_qrcode img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.colors {
  color: #000 !important;
}
.color-white {
  color: #fff !important;
}
.number-price {
  font-family: "UzluqEgme" !important;
}
</style>
