<!-- eslint-disable vue/valid-template-root -->
<template>
  <div style="display: flex; justify-content: center;">
    <div>
      <div style="width: 400px;position: relative;">
        <div>
          <img v-if="posterList.lang == 'ug' || !posterList.lang" style="width: 100%;" :src="moban_list.url_ug">
          <img v-if="posterList.lang == 'zh'" style="width: 100%;" :src="moban_list.url_zh">
        </div>
        <div
          style=" width: 400px; position: absolute;top: 370px;display: flex;    flex-direction: column;align-items: center;">
          <div
            style="background-color: #fff; width: 80px; height: 80px;border-radius: 15px;overflow:hidden;border: 3px solid #fff;">
            <img mode="aspectFill" style="width: 100%;height: 100%;" :src="posterList.merchant_img">
          </div>
          <p class="mr_name"> {{ posterList.merchant_name }} </p>
          <div class="qarcode_seve"><img style="width: 97%; height: 97%;" mode="scaleToFill"
              :src="posterList.merchant_qrcode" /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      posterList: null,
      moban_list:
      {
        url_zh: require('../../img/poster/model2_zh.png'),
        url_ug: require('../../img/poster/model2_ug.png'),

      },
    };
  },
  created() {
    this.canvasfortitle(this.$route.query.merchant_name, this.$route.query)
  },
  methods: {
    canvasfortitle(merchant_name, query) {
      let res_name = merchant_name.replaceAll('(','-')
      res_name = res_name.replaceAll('（','-')
      res_name = res_name.replaceAll(')',' ')
      res_name = res_name.replaceAll('）',' ')
      this.posterList = query;
      this.posterList['merchant_name'] = res_name;
    },
  },
};
</script>

<style>
.mr_name {
  padding: 3px 10px;
  color: rgb(85, 85, 85);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  font-size: 19px;
  margin: 15px;
  max-width: 328px;
  text-align: center;
}

.qarcode_seve {
  top: 275px;
  left: 33px;
  width: 150px;
  height: 150px;
  border-radius: 90px;
  overflow: hidden;
  z-index: 999999;
  position: absolute;
}

.bg-poster {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  /*默认为 row，这时要设置 width: 100%*/
  height: 100%;
}

.poster {
  height: 100%;
  width: 100%;
}

.code-img {
  width: 160px;
  height: 160px;
  top: 661px;
  margin-right: 191px;
}

.card-poster {
  width: 100%;
  text-align: center;
  max-width: 414px;
  display: flex;
  justify-content: center;
}

.title2 {
  margin-top: 10px;
  color: #fff;
  font-size: 20px !important;
}

.flex {
  display: flex;
}

.axhana-title2 {
  position: absolute;
  z-index: 999;
  justify-content: center;
  top: 385px;
  width: 100%;
}

.bac-poster {
  padding: 50px;
  text-align: center;
  background: #fff;
  justify-content: center;
}

.cur {
  position: absolute;
}

.code-img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>
