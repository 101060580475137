<template>
    <div id="app">
      <div class="item">
        <img
          class="item-img"
          @click="
            previewImg(
              'https://acdn.mulazim.com/wechat_mini/img/resources/celleInfo.jpg'
            )
          "
          src="https://acdn.mulazim.com/wechat_mini/img/resources/celleInfo.jpg"
        />
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "App",
    data() {
      return {
        title: "demo",
      };
    },
    created() {},
    methods: {
      previewImg(urls) {
        this.$hevueImgPreview({ name: urls, url: urls });
      },
    },
  };
  </script>
  
  <style scoped>
  * {
    margin: 0;
    padding: 0;
  }
  #app {
    background: #f2f2f2;
  }
  .item {
    height: 100%;
    background-color: #fff;
    text-align: center;
    border: 4px solid #f0f0f0;
  }
  .item-img {
    width: 100%;
    height: 100%;
  }
  </style>
  