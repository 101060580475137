<template>
  <div class="model-content">
    <div
      class="model_30"
      :class="
        posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
      "
    >
      <div class="img-box">
        <img style="width: 100%" :src="url" />
      </div>

      <div class="merchant-name">
        <div class="merchant-name-title">{{ posterList.merchant_name }}</div>
      </div>

      <div class="date">
        <div class="date_text">{{ posterList.date }}</div>
      </div>

      <div class="food_name">
        <div class="food_name_text">{{ posterList.food_name }}</div>
      </div>

      <div class="food_img">
        <img :src="posterList.food_img" alt="" />
      </div>

      <div class="food_price" :style="{ fontSize: posterList.fontSize + 'px' }">
        <span>{{ posterList.food_price }}</span>
        <span
          v-if="posterList.lang == 'ug'"
          :style="{ fontSize: posterList.fontSize - 4 + 'px' }"
        >
          يۈەن</span
        >
        <span v-if="posterList.lang == 'zh'"> 元</span>
      </div>

      <div class="old_price">
        <div class="old_price_text" v-if="posterList.lang == 'ug'">
          ئەسلى باھاسى :
        </div>
        <div class="old_price_text" v-if="posterList.lang == 'zh'">原价 :</div>
        <div class="line">
          <span class="number"> {{ posterList.old_price }} </span>
          <span v-if="posterList.lang == 'ug'"> يۈەن </span>
          <span v-if="posterList.lang == 'zh'"> 元 </span>
        </div>
      </div>

      <div class="merchant_qrcode">
        <img :src="posterList.merchant_qrcode" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_60",
  data() {
    return {
      posterList: {},
      url: require("../../img/poster/model_60.jpg"),
    };
  },
  created() {
    this.canvasfortitle(
      this.$route.query.merchant_name,
      this.$route.query.food_name,
      this.$route.query
    );
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      let res_name = merchant_name.replaceAll("(", "-");
      res_name = res_name.replaceAll("（", "-");
      res_name = res_name.replaceAll(")", " ");
      res_name = res_name.replaceAll("）", " ");
      let food_name = food_names.replaceAll("(", "-");
      food_name = food_name.replaceAll("（", "-");
      food_name = food_name.replaceAll(")", " ");
      food_name = food_name.replaceAll("）", " ");
      this.posterList = query;
      this.posterList["merchant_name"] = res_name;
      this.posterList["food_name"] = food_name;
      this.posterList.food_price = parseFloat(query.food_price);
      const price = query.food_price.toString();
      let fontSize = 20;
      if (price.length <= 2) {
        fontSize = 30;
      } else if (price.length == 3) {
        fontSize = 28;
      } else if (price.length == 4) {
        fontSize = 24;
      } else if (price.length == 5) {
        fontSize = 23;
      } else if (price.length == 6) {
        fontSize = 20;
      }
      this.posterList.fontSize = fontSize;
    },
  },
};
</script>

<style scoped>
.model-content {
  width: 388px;
  height: 737px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
}

.model_30 {
  width: 388px;
  height: 737px;
  position: relative;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.img-box {
  width: 100%;
  height: 100%;
}

.merchant-name {
  position: absolute;
  top: 80px;
  right: 50%;
  transform: translateX(49%);
  color: #d8ff00;
  width: 282px;
  height: 56px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.merchant-name-title {
  font-size: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.date {
  position: absolute;
  top: 160px;
  right: 50%;
  transform: translateX(50%);
  color: #403c3c;
  background-color: #fff;
  border-radius: 30px;
  width: max-content;
  padding: 0px 15px;
  direction: ltr;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.food_name {
  position: absolute;
  width: 190px;
  height: 56px;
  top: 282px;
  left: 10px;
  font-size: 18px;
  color: #fffc00;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: bold;
  text-align: center;
  transform: rotate(-19deg);
}

.food_name_text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.food_img {
  position: absolute;
  top: 334px;
  left: 30px;
  width: 328px;
  height: 203px;
  border-radius: 50%;
  overflow: hidden;
}

.food_img img {
  width: 100%;
  height: 100%;
  clip-path: ellipse(50% 50% at 50% 50%);
  object-fit: cover;
}

.food_price {
  position: absolute;
  top: 300px;
  left: 255px;
  width: 114px;
  color: #fff;
  text-align: center;
  line-height: 38px;
  font-weight: bold;
  transform: rotate(34deg);
}
.old_price {
  position: absolute;
  bottom: 170px;
  font-size: 15px;
  color: #fff;
  display: flex;
  align-items: center;
}

.old_price .line {
  text-decoration: line-through;
  padding-inline: 4px;
}

.model_ug .old_price {
  right: 130px;
}

.model_zh .old_price {
  left: 145px;
}

.merchant_qrcode {
  position: absolute;
  bottom: 28px;
  left: 42px;
  width: 98px;
  height: 98px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;
}

.merchant_qrcode img {
  width: 100%;
  height: 100%;
}
</style>
