
<template>
  <div class="model-content" style="display: flex; justify-content: center;">
    <div class="model3">
      <div>
        <img v-if="posterList.lang == 'ug' || !posterList.lang" style="width: 100%;" :src="moban_list.url_ug" />
        <img v-if="posterList.lang == 'zh'" style="width: 100%;" :src="moban_list.url_zh" />
      </div>
      <div class="merchant_name"
        :class="(posterList.lang == 'ug' || !posterList.lang) ? 'rtl merchant_name_ug' : 'merchant_name_ug  zh-font'">
        <div class="merchant_name_font">{{ posterList.merchant_name }}</div>
      </div>
      <div class="date" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'date_ug' : 'date_ug'">
        {{ posterList.date }}
      </div>
      <div class="food_name" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'rtl food_name_ug' : 'food_name_ug'">
        <div class="food_name_font">{{ posterList.food_name }}</div>
      </div>
      <div class="food_img" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'food_img_ug' : 'food_img_ug'">
        <img :src="posterList.food_img" alt="">
      </div>
      <div class="food_price" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'food_price_ug' : 'food_price_ug'">
        <span>{{ posterList.food_price }}</span><span style="font-size: 16px;">￥</span>
      </div>
      <div class="old_price" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'old_price_ug' : 'old_price_zh'">
        <span>{{ posterList.old_price }}</span><span style="font-size: 12px;">￥</span>
      </div>
      <div class="merchant_qrcode"
        :class="(posterList.lang == 'ug' || !posterList.lang) ? 'merchant_qrcode_ug' : 'merchant_qrcode_ug'">
        <img :src="posterList.merchant_qrcode" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      posterList: {},
      moban_list: {
        url_zh: require('../../img/poster/modelzh_10.png'),
        url_ug: require('../../img/poster/modelug_10.png'),
      },
    };
  },
  created() {
    this.canvasfortitle(this.$route.query.merchant_name, this.$route.query.food_name, this.$route.query)
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      let res_name = merchant_name.replaceAll('(', '-')
      res_name = res_name.replaceAll('（', '-')
      res_name = res_name.replaceAll(')', ' ')
      res_name = res_name.replaceAll('）', ' ')
      let food_name = food_names.replaceAll('(', '-')
      food_name = food_name.replaceAll('（', '-')
      food_name = food_name.replaceAll(')', ' ')
      food_name = food_name.replaceAll('）', ' ')
      this.posterList = query;
      this.posterList['merchant_name'] = res_name;
      this.posterList['food_name'] = food_name;
    },
  },
};
</script>

<style scoped>
.model-content {
  width: 375px;
  height: 737px;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.model3 {
  width: 375px;
  height: 737px;
  position: relative;
  overflow: hidden;
}

.merchant_qrcode {
  position: absolute;
  width: 74.81px;
  height: 74.72px;
  border-radius: 29px;
  background-color: #fff;
}

.merchant_qrcode img {
  width: 100%;
  height: 100%;
  border-radius: 29px;
}

.merchant_qrcode_ug {
  left: 49px;
  top: 633px;
}

.food_img {
  position: absolute;
  width: 254px;
  height: 254px;
  border-radius: 127px;
}

.food_img img {
  position: absolute;
  width: 258px;
  height: 260px;
  border-radius: 127px;
  mix-blend-mode: multiply;
}

.food_img_ug {
  left: -30px;
  top: 296px;
}

.food_price {
  position: absolute;
  font-size: 28px;
  color: #434141;
  font-weight: 600;
  width: 117px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateZ(12deg);
}



.food_price_ug {
  right: 25px;
  top: 450px;
}

.old_price {
  position: absolute;
  font-size: 20px;
  color: #fff;
  text-decoration: line-through;
}

.old_price_ug {
  right: 312px;
  top: 572px;
}

.old_price_zh {
  left: 123px;
  top: 568px;
}

.date {
  position: absolute;
  width: 344px;
  height: 40px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  color: #1cb30c;
  font-size: 18px;
  line-height: 43px;
}

.date_ug {
  right: 15px;
  top: 193px;
}

.food_name {
  position: absolute;
  color: #434141;
  font-weight: 600;
  width: 130px;
  height: 37px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 9;
  padding: 0 6px;
  font-size: 18px;
  transform: rotateZ(347deg);
}

.food_name_ug {
  right: 22px;
  top: 314px;
}

.food_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 1;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
  text-align: center;
  line-height: 24px;
}

.merchant_name {
  position: absolute;
  width: 266px;
  height: 61px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  color: #1cb30c;
  font-size: 18px;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: -1px 0px 10px #60626654;
}

.merchant_name_ug {
  right: 47px;
  top: 88px;
}

.merchant_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
  text-align: center;
  line-height: 25px;
}
</style>