
<template>
    <div class="model-content" style="display: flex; justify-content: center;">
        <div class="model5">
            <div>
                <img v-if="posterList.lang == 'ug' || !posterList.lang" style="width: 100%;" :src="moban_list.url_ug" />
                <img v-if="posterList.lang == 'zh'" style="width: 100%;" :src="moban_list.url_zh" />
            </div>
            <div class="top-container">
                <div class="merchant-name" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'rtl' : ' zh-font'">
                    <div class='merchant-name-title'>
                        {{ posterList.merchant_name }}
                    </div>
                </div>
                <div class="date-time" v-if="posterList.date">
                    <div class="date-item">
                        <span> {{ posterList.date }}</span>
                    </div>
                </div>
            </div>

            <div class="food">
                <div class="food_name" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'rtl' : ''">
                    <div class="food_name_text">{{ posterList.food_name }}</div>
                </div>
                <div class="food_img"
                    :class="(posterList.lang == 'ug' || !posterList.lang) ? 'food_img_ug' : 'food_img_zh'">
                    <img :src="posterList.food_img" alt="">
                </div>
                <div class="food_price" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'rtl' : ''">
                    <span>{{ posterList.food_price }}</span><span>￥</span>
                </div>
                <div class="old_price"
                    :class="(posterList.lang == 'ug' || !posterList.lang) ? 'rtl old_price_ug' : 'old_price_zh'">
                    <span>{{ posterList.old_price }}</span><span>￥</span>
                </div>
            </div>
            <div class="merchant_qrcode"
                :class="(posterList.lang == 'ug' || !posterList.lang) ? 'merchant_qrcode_ug' : 'merchant_qrcode_zh'">
                <img :src="posterList.merchant_qrcode" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "App",
    data() {
        return {
            posterList: {},
            moban_list: {
                url_zh: require('../../img/poster/model5_zh.jpg'),
                url_ug: require('../../img/poster/model5_ug.jpg'),
            },
        };
    },
    created() {
        this.canvasfortitle(this.$route.query.merchant_name, this.$route.query.food_name, this.$route.query)
    },
    methods: {
        canvasfortitle(merchant_name, food_names, query) {
            let res_name = merchant_name.replaceAll('(', '-')
            res_name = res_name.replaceAll('（', '-')
            res_name = res_name.replaceAll(')', ' ')
            res_name = res_name.replaceAll('）', ' ')
            let food_name = food_names.replaceAll('(', '-')
            food_name = food_name.replaceAll('（', '-')
            food_name = food_name.replaceAll(')', ' ')
            food_name = food_name.replaceAll('）', ' ')
            this.posterList = query;
            this.posterList['merchant_name'] = res_name;
            this.posterList['food_name'] = food_name;
        },
    },
};
</script>
  
<style scoped>
.model-content {
    width: 339px;
    height: 631px;
    position: relative;
    margin: auto;
}

.model5 {
    width: 339px;
    height: 631px;
    position: relative;
}

.rtl {
    direction: rtl;
}

.top-container {
    position: absolute;
    z-index: 999;
    top: 69px;
    width: 280px;
    left: calc(50% - 140px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.merchant-name {
    position: relative;
    color: #3f9eee;
    width: 240px;
    height: 54px;
    padding: 5px 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.merchant-name-title {
    font-size: 18px;
    width: 100%;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 26px;
    padding: 0 5px;
}

.date-time {
    position: relative;
    top: 14px;
    width: 280px;
    padding: 2px 10px;
    height: 25px;
    color: #3f9eee;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
}

.date-item {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0 4px;
}

.date-title {
    padding: 0 5px;
}

.food {
    position: absolute;
    z-index: 999;
    top: 216px;
    height: 156px;
    width: 100%;
}

.food_name {
    position: absolute;
    width: 148px;
    height: 30px;
    top: 26px;
    right: 30px;
    font-size: 13px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.food_name_text {
    width: 100%;
    display: -webkit-box;
    text-align: center;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.food_img {
    position: absolute;
    top: 6px;
    left: 12px;
    width: 144px;
    height: 144px;
    border-radius: 50%;
    overflow: hidden;
}

.food_img img {
    width: 100%;
    height: 100%;
}

.food_price {
    position: absolute;
    width: 148px;
    height: 65px;
    top: 60px;
    right: 22px;
    font-size: 30px;
    color: #3f9eee;
    width: 148px;
    height: 65px;
    text-align: center;
    line-height: 65px;
    font-weight: bold;
}

.old_price {
    position: absolute;
    font-size: 14px;
    color: #fff;
    text-decoration: line-through;
}

.old_price_ug {
    bottom: 6px;
    right: 124px;
}

.old_price_zh {
    bottom: 6px;
    left: 195px;
}

.merchant_qrcode {
    position: absolute;
    bottom: 23px;
    left: 15px;
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    padding: 3px;
    box-sizing: border-box;
}

.merchant_qrcode img {
    width: 100%;
    height: 100%;
}
</style>