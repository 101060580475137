<template>
    <div id="certificate">
        <div class="item">
            <img class="item-img" @click="previewImg('//vcdn.mulazim.com/company_info/androidkhd.png')"
                src="//vcdn.mulazim.com/company_info/androidkhd.png" />
        </div>
        <div class="item">
            <img class="item-img" @click="previewImg('//vcdn.mulazim.com/company_info/androidpsd.png')"
                src="//vcdn.mulazim.com/company_info/androidpsd.png" />
        </div>

        <div class="item">
            <img class="item-img" @click="previewImg('//vcdn.mulazim.com/company_info/fuwuhoutai.png')"
                src="//vcdn.mulazim.com/company_info/fuwuhoutai.png" />
        </div>


        <div class="item">
            <img class="item-img" @click="previewImg('//vcdn.mulazim.com/company_info/iosv10.png')"
                src="//vcdn.mulazim.com/company_info/iosv10.png" />
        </div>

        <div class="item">
            <img class="item-img" @click="previewImg('//vcdn.mulazim.com/company_info/iosv20.png')"
                src="//vcdn.mulazim.com/company_info/iosv20.png" />
        </div>

        <div class="item">
            <img class="item-img" @click="previewImg('//vcdn.mulazim.com/company_info/wapkhd.png')"
                src="//vcdn.mulazim.com/company_info/wapkhd.png" />
        </div>
    </div>
</template>
<script>
export default {
    name: "certificate",
    methods: {
        previewImg(urls) {
            this.$hevueImgPreview({ name: urls, url: urls });
        },
    },
};
</script>
  
<style scoped>
* {
    margin: 0;
    padding: 0;
}

#app {
    background: #f2f2f2;
}

.item {
    height: 100%;
    width: 100%;
}

.item-img {
    width: 100%;
    height: 100%;
}
</style>
  