<template>
  <div class="model-content">
    <div
      class="model_62"
      :class="
        posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
      "
    >
      <img class="img-box" :src="url" />

      <div class="merchant-name">
        <div class="merchant-name-title">{{ posterList.merchant_name }}</div>
      </div>
      <div class="food_name">
        <div class="food_name_text">{{ posterList.food_name }}</div>
      </div>

      <div class="food_img">
        <img :src="posterList.food_img" alt="" />
      </div>

      <div class="food_price" :style="{ fontSize: posterList.fontSize + 'px' }">
        <span class="number">{{ posterList.food_price }}</span>
        <span
          class="number"
          style="
            font-size: 28px;
            font-weight: normal;
            bottom: -4px;
            position: absolute;
          "
          >￥</span
        >
      </div>
      <div class="new_food_price"></div>

      <div class="old_price">
        <div class="old_price_text" v-if="posterList.lang == 'ug'">
          ئەسلى باھاسى :
        </div>
        <div class="old_price_text" v-if="posterList.lang == 'zh'">原价 :</div>
        <div class="line">
          <span class="number"> {{ posterList.old_price }} </span>
          <span v-if="posterList.lang == 'ug'"> يۈەن </span>
          <span v-if="posterList.lang == 'zh'"> 元 </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_62",
  data() {
    return {
      posterList: {},
      url: require("../../img/poster/model_62.jpg"),
    };
  },
  created() {
    this.canvasfortitle(
      this.$route.query?.merchant_name || "",
      this.$route.query?.food_name || "",
      this.$route.query
    );
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      const replaceParentheses = (str) => {
        return str
          .replaceAll("(", "-")
          .replaceAll("（", "-")
          .replaceAll(")", " ")
          .replaceAll("）", " ");
      };
      const res_name = replaceParentheses(merchant_name);
      const food_name = replaceParentheses(food_names);
      this.posterList = { ...query };
      console.log("res_name", res_name.length);
      if (res_name.length < 40) {
        this.posterList["merchant_name"] = res_name;
      } else {
        this.posterList["merchant_name"] = res_name.substring(0, 40) + "...";
      }
      if (food_name.length <= 36) {
        this.posterList["food_name"] = food_name;
      } else {
        this.posterList["food_name"] = food_name.substring(0, 36) + "...";
      }
      this.posterList.food_price = parseFloat(query.food_price);
      const priceLength = query.food_price.toString().length;
      let fontSize = 48;
      if (priceLength == 3) {
        fontSize = 46;
      } else if (priceLength == 4) {
        fontSize = 40;
      } else if (priceLength == 5) {
        fontSize = 38;
      } else if (priceLength == 6) {
        fontSize = 34;
      }
      this.posterList.fontSize = fontSize;
    },
  },
};
</script>

<style scoped>
@import url("../../css/model_62/title.css");
.model-content {
  width: 750px;
  height: 283px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
}

.model_62 {
  width: 750px;
  height: 283px;
  position: relative;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.img-box {
  width: 100%;
  height: 100%;
}

.merchant-name {
  position: absolute;
  top: 40px;
  left: 10px;
  transform: rotate(-6deg);
  color: #fff;
  width: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.merchant-name-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.model_ug .merchant-name-title {
  font-family: "izbolperwaz" !important;
  font-size: 38px;
  line-height: 30px;
  padding: 6px;
}
.model_zh .merchant-name-title {
  font-family: "FZCQK--GBK1-0" !important;
  line-height: 30px;
  font-size: 26px;
}

.food_name {
  position: absolute;
  width: 290px;
  top: 130px;
  left: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  transform: rotate(-6deg);
}

.food_name_text {
  color: #730000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.model_ug .food_name_text {
  font-family: "izbolperwaz" !important;
  font-size: 40px;
  line-height: 38px;
  padding: 3px;
}
.model_zh .food_name_text {
  font-family: "FZCQK--GBK1-0" !important;
  line-height: 30px;
  font-size: 25px;
}

.food_img {
  position: absolute;
  top: 45px;
  right: 31px;
  width: 310px;
  height: 192px;
  border-radius: 50%;
  overflow: hidden;
}

.food_img img {
  width: 100%;
  height: 100%;
  clip-path: ellipse(50% 50% at 50% 50%);
  object-fit: cover;
}

.food_price {
  position: absolute;
  width: 110px;
  height: 110px;
  top: 155px;
  right: 280px;
  border: 5px dotted #d70000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 2;
  color: #d70000;
  font-weight: bold;
  flex-direction: column;
  font-family: "UzluqEgme" !important;
}
.new_food_price {
  position: absolute;
  width: 128px;
  height: 128px;
  top: 150px;
  right: 276px;
  z-index: 1;
  background-color: white;
  border-radius: 50%;
}
.old_price {
  position: absolute;
  bottom: 18px;
  font-size: 20px;
  color: #e5dd04;
  display: flex;
  align-items: center;
  left: 15px;
}

.old_price .line {
  text-decoration: line-through;
  padding-inline: 4px;
}
.number {
  font-family: "UzluqEgme" !important;
}
</style>
