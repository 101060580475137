<template>
  <div
    id="mini"
    v-loading="loading"
    element-loading-text="跳转中。。。"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  ></div>
</template>

<script>
import Axios from "axios";
export default {
  name: "mini",
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.getMini();
  },
  methods: {
    getMini() {
      this.loading = true;
      const params = "resId=8005";
      Axios.get(
        `https://smart.mulazim.com/ug/v1/college-mini/jump-mini?path=/pages/index/index&query=${params}`
      )
        .then((res) => {
          this.loading = false;
          if (res.data.status == 200) {
            this.$message.success("跳转成功");
            console.log("res.data.data.url", res.data.data.url);
            window.location.href = res.data.data.url;
          } else {
            this.$message.error("请重试");
          }
        })
        .catch((err) => {
          this.$message.error("请重试");
          this.loading = false;
          console.log(err);
        });
    },
  },
  components: {},
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#mini {
  width: 100%;
  height: 100vh;
}
</style>
