<style scoped>
body {
    background-color: #EFF1F6;
}

td {
    border: #000000 solid 1px;
    margin: 0px;
}

.content {
    max-width: 500px;
    width: 100%;
    /* padding: 15px; */
    margin: 15px;
    background-color: #fff;
    border-radius: 15px;
}

.contet_title {
    font-size: 24px;
    padding-top: 15px;
    padding-bottom: 40px;
}

.m-1 {
    margin: 20px;
}

.text-algin {
    text-align: center;
}

.text-bold {
    font-weight: 600;
}
</style>


<template>
    <div style="display: flex;justify-content: center;">
        <div class="content">
            <div class="m-1">
                <div class="text-algin text-bold contet_title">{{ title }}</div>
            </div>
            <div class="m-1">
                <p>更新日期: 2022年8月1日</p>
                <p>生效日期：2022年8月1日</p>
                <p>【引言】</p>
                <p>欢迎您使用我们的产品和服务！我们深知个人信息对您的重要性，您的信任对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息安全可控。有鉴于此，美滋来服务提供者（或简称“我们”）制定本《美滋来隐私权政策》（下称“本政策
                    /本隐私权政策”），帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地做出适当的选择。</p>
                <p>在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以粗体/粗体下划线标识的条款，确保您充分理解和同意之后再开始使用。您使用或继续使用我们的服务，即意味着同意我们按照本政策处理您的相关信息。
                </p>
                <p>本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可以通过美滋来客服与我们联系。</p>
                <p>您同意隐私政策表示您已了解应用提供的功能，以及功能运行所需的必要个人信息，并给予相应的收集使用授权，但并不代表您已单独同意开启附加功能、处理非必要个人信息，相关附加功能的开启、处理非必要个人信息以及处理敏感个人信息，我们会根据您的实际使用情况单独征求您的同意。
                </p>
                <p>&nbsp;</p>
                <p>本政策将帮助您了解以下内容：</p>
                <p>一、 适用范围</p>
                <p>二、 信息收集及使用</p>
                <p>三、 对外提供信息</p>
                <p>四、 您的权利</p>
                <p>五、 信息的存储</p>
                <p>六、 政策的更新</p>
                <p>七、 未成年人保护</p>
                <p>八、 联系我们</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;附录：相关定义</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;一、适用范围</p>
                <p>本政策适用于我们提供的产品或服务，包括美滋来外卖网站、移动客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。如您在接受我们的关联公司及合作方提供的产品或服务时使用了美滋来提供的全部或部分产品或服务（例如使用美滋来账户登录）但未设独立法律声明及隐私权政策的，则本政策同样适用于美滋来提供的全部或部分产品或服务。此外，针对某些特定的产品/服务，我们还将制定单独的隐私政策，向您说明这些产品/服务的特殊政策，如相关的隐私政策与本隐私政策有不一致之处，适用该特定隐私政策。
                </p>
                <p>需要特别说明的是，除本政策说明的相关信息收集使用活动外，本政策不适用于其他第三方向您提供的服务
                    (“其他第三方”，包括但不限于您的交易相对方、任何第三方网站以及第三方服务提供者)，该服务适用其向您另行说明的隐私政策或其他个人信息收集使用规则。例如，当您加入卖家或品牌的会员时，则此类卖家或品牌对会员信息的使用适用其向您专门说明的规则，包括向您进行营销。
                </p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;二、信息收集及使用</p>
                <p>在您使用我们的产品及/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：</p>
                <p>1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用对应的产品及/或服务；</p>
                <p>2、为实现向您提供我们产品及/或服务的附加功能，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。
                </p>
                <p>我们在此特别提醒您：我们致力于打造多样的产品和服务以满足您的需求，因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异。相应的，基本/附加功能及收集使用的个人信息类型、范围会有所区别，请以具体的产品/服务功能为准；
                </p>
                <p>我们会为您提供的各项具体功能场景包括：</p>
            <p>（一）您需要授权我们收集和使用个人信息的场景</p>
            <p>1、帮助您成为我们的用户</p>
            <p>（1）基础服务</p>
            <p>为遵守法律法规的要求，在您注册成为我们的用户时，您需要提供手机号码以创建美滋来账号。如果您仅需使用浏览、搜索及其他基本服务，您可选择不注册成为我们的用户及提供上述信息。</p>
            <p>对于需要通过美滋来账号才能使用的服务，我们可能会根据您提供的上述信息校验您的用户身份，确保我们是在为您本人提供服务。</p>
            <p>（2）附加服务</p>
            <p>完善账号信息：您在使用美滋来时，为维护您的基本资料，改善您的服务体验，您可以提供非注册必须信息来完善网络身份识别信息（如头像、账号、昵称及登录密码）。其中，如果您提供昵称和头像的，您的昵称、头像将公开显示。
            </p>
            <p>如您通过购买或其他方式成为美滋来超级会员/美滋来超级吃货卡用户，您需要提供您的所在城市，我们可以为您提供专享红包、奖励金、专属折扣、专属客服及其他会员服务。如果您不提供这些信息，并不会影响您对美滋来基本功能使用。
            </p>
            <p>授权登录：我们可能经您同意后向第三方共享您的美滋来账号信息（头像、昵称、账号ID及其他页面提示的信息），使您可以便捷地实现第三方账号的注册或登录。此外，我们可能会根据您的授权从第三方处获取您的第三方账号信息，并与您的美滋来账号进行绑定，使您可以通过第三方账号直接登录、使用我们的产品及/或服务，或实现账号之间相关权益互通。我们将根据与第三方的约定，在您授权同意范围内使用您的相关信息。
            </p>
            <p>如果您已拥有美滋来账号，我们可能会在美滋来平台服务中显示您的上述个人信息，以及您在美滋来平台上或与美滋来平台账号相关联的产品和服务中执行的操作（您可通过美滋来账号在我们提供的美滋来首页其他产品/服务入口使用我们及/或关联公司提供的产品或服务），包括通过美滋来平台账号集中展示您的个人资料、优惠权益、交易订单。我们会尊重您对美滋来平台服务和美滋来账号设置所作的选择。
            </p>
            <p>2、为您提供商品或服务信息展示</p>
            <p>在您使用我们服务过程中，为向您提供周边外卖信息及其他服务的展示和搜索服务，向您展示更契合您需求的商品或服务信息，我们会收集和使用您在访问或使用我们的产品/服务时的浏览、搜索记录。为满足您的多元需求，我们会在排序过程中引入多样化推荐技术，拓展推荐的内容，避免同类型内容过度集中。我们也会基于您的偏好特征在美滋来及其他第三方应用程序向您推送您可能感兴趣的商业信息，或者通过您的美滋来账号注册的手机号或您授权接受信息的其他手机号向您发送商业性短信息。
            </p>
            <p>其中，设备信息是您在访问我们及/或使用我们服务过程中，我们接收并记录您所使用的设备相关信息（包括设备型号、设备名称、操作系统版本、设备设置、设备标识符IMEI/IMSI/MEID/IDFA/OAID/IDFV/GAID/android
                ID/IP地址、设备Mac地址、设备环境、已安装移动应用列表及其他软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站及其他传感器信息）。</p>
            <p>服务日志信息是您在访问我们及/或使用我们服务过程中，我们自动收集和记录的您的详细使用情况，包括浏览、点击查看、搜索查询、收藏、添加至购物车、交易、售后、分享信息、发布信息，以及IP地址、浏览器的类型、操作系统的版本、电信运营商、使用的语言、访问日期和时间。
            </p>
            <p>同时，我们通过以下措施努力保障您的隐私体验：</p>
            <p>（1）如您要删除您的浏览和搜索记录，您可以在“搜索框-历史搜索”中选择删除；</p>
            <p>（2）如果您不想接受我们给您发送的商业性信息，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭；</p>
            <p>（3）如您在使用我们提供的站内搜索服务时，需要查看不针对您个人特征的排序，您可以在搜索结果页面点击“综合排序”，选择其中的“通用排序”进行设置；</p>
            <p>此外，我们也会为了不断改进和优化上述的功能来使用您的上述信息。</p>
            <p>3、为您提供加购、关注与分享功能</p>
            <p>在您使用上述功能的过程中，我们会收集包括您的添加购物车的记录、关注、分享历史在内的服务日志信息用于实现上述功能及其他我们明确告知的目的。</p>
            <p>您可以在店铺关注、购物车管理或删除您的关注和加购信息。</p>
            <p>4、帮助您完成下单及订单管理</p>
            <p>当您在我们的产品及/或服务中订购具体商品及/或服务时，我们会通过系统为您生成购买该商品及/或服务的订单。在下单过程中，如涉及配送（例如外卖配送）时，您需至少提供您的收货人姓名、收货地址、收货人联系电话，当收货人联系电话与下单手机号不一致时，还需提供备用手机号（默认填写下单手机号），以便服务提供方无法联系订单收货人时可与备用手机号联系进行服务履约。如您选择使用跑腿业务，在下单过程中，您需同时提供发件人的姓名、性别（用于配送人员联络时称呼，如张女士）、地址、联系电话和收件人的姓名、性别（用于配送人员联络时称呼，如张女士）、地址、联系电话。对于部分特殊类型的商品和服务您还需要提供商品或服务所必需的其他信息，例如您在使用医疗服务时，可能需要根据有关法律规定和相关方要求（医疗机构）提供您的实名身份信息、诊疗信息。
            </p>
            <p>同时该订单中会载明您所购买的商品及/或服务信息、具体订单号、下单时间、您应支付的金额、支付方式，我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客服与售后服务及其他我们明确告知的目的。
            </p>
            <p>您可以通过美滋来为其他人订购商品或服务，您需要提供该实际订购人的前述个人信息。在向美滋来提供该实际订购人的前述个人信息之前，您需确保您已经取得其授权同意。其中涉及未成年人订购的，您需在订购前取得所涉及未成年人的监护人授权同意。
            </p>
            <p>为便于您了解查询订单信息并对订单信息进行管理，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。另外，我们的服务也会通过淘宝网、支付宝、口碑及其他关联方平台，也或通过其它相关商家平台和小程序向您提供，为便于您在这些平台和小程序中查看和管理您使用我们服务产生的订单，我们会基于您在这些平台和小程序中对我们的授权将您通过这些平台和小程序产生的订单信息在这些平台向您展示。同时，为了便于您查看和管理您通过美滋来账号使用我们关联公司和第三方服务产生的订单，您同意我们收集这些订单信息并在美滋来平台向您展示。
            </p>
            <p>您可额外填写/选择包括其他联系电话、收货时间在内的更多附加信息以确保商品或服务的准确送达。</p>
            <p>您可以通过“订单”查看和管理您的订单信息。</p>
            <p>5、帮助您完成支付</p>
            <p>为完成订单支付，您需要选择支付方式（如支付宝、微信支付合作方）并提供第三方支付平台账号，我们会将您的美滋来账号会员名、订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息通过在网站或客户端中嵌入的第三方支付平台软件工具开发包（SDK）及其他安全方式与第三方支付平台公司共享。如您选择由其他金融机构为您提供支付服务的，我们还会将您的包括银行卡号、有效期在内的银行卡支付必要信息与您选择的相应金融机构共享。
            </p>
            <p>您可以请求其他人为您付款，那么您需要提供代付人的第三方支付平台账号及/或手机号码。</p>
            <p>为使我们及时获悉并确认您的支付进度及状态，为您提供售后及争议解决服务，您同意我们可自您选择的交易对象、支付方式所对应的支付机构处收集与支付账号、支付进度相关的信息。</p>
            <p>6、帮助向您完成商品或服务的交付</p>
            <p>根据您与商品及/或服务提供主体的约定，相关配送服务主体（包括商家或第三方配送服务提供商）将为您完成订单的交付，您知悉并同意相应配送服务主体不可避免地获知及使用您的订单相关配送信息（如：收货人姓名、收货地址、收货人联系电话），以保证您购买的商品及/或服务能够顺利、安全、准确送达。
            </p>
            <p>为使我们及时获悉并确认交付进度及状态，为您提供售后及争议解决服务，您同意我们可自您选择的商品及/或服务提供主体、配送服务主体处收集与交付进度相关的信息。</p>
            <p>7、客服及争议处理</p>
            <p>当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账号及系统安全，我们需要您提供必要的个人信息以核验您的用户身份。</p>
            <p>当您需要我们提供与您订单相关的客户服务时，我们可能会查询您的相关订单信息以便给与您适当的帮助和处理；当您需要客服协助您修改有关信息（如配送地址、联系方式）时，您可能还需要提供上述信息外的其他信息以便完成修改。
            </p>
            <p>当您需要我们协助完成保险理赔服务时，您可能需要按照保险公司对具体理赔的要求填写信息、上传理赔材料，包括不限于赔付原因、问题商品照片、收款方式、身份信息（其中涉及敏感个人信息的如身份证、病例、发票我们将为您加密上传），以便完成订单理赔。
            </p>
            <p>为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。
            </p>
            <p>为了提供服务及改进服务质量的合理需要，我们还可能使用您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。</p>
            <p>为了保证服务体验，处理服务纠纷，您在订单履行过程中拨打或者接听开启号码保护的电话时，您与商家、骑手间的通话信息可能会被录音。有关上述信息的收集和使用规则如下，或参见订单页面中的提示内容。</p>
            <p>（1） 经授权，当您与商家、骑手间出现服务纠纷时，美滋来可调取并使用录音信息作为解决纠纷的参考依据；</p>
            <p>（2）
                美滋来将切实保障通话双方的隐私安全，录音将上传至美滋来服务器保存，各方无法自行下载、调取或播放录音；除以下情形外，美滋来不会将录音提供给任何人（包括通话主叫方、被叫方）：a）有权机关依法定程序调取；b）被录音的一方持司法机关出具的法律文件依法调取；
            </p>
            <p>（3） 录音在保存7天后将永久删除，如遇差评、投诉、尚未处理完毕的纠纷等，美滋来将适当延长录音保存期限。</p>
            <p>8、为您提供信息公开发布功能</p>
            <p>您可在通过美滋来对商家的产品/服务进行评价及其他方式公开发布信息，包括但不限于发布图文/视频内容、发表评价，我们将收集您发布的信息，并展示您的用户名、头像和发布内容。如您选择以匿名方式发布评价信息时，我们将不展示您的用户名、头像。
            </p>
            <p>请注意，您发布的信息中可能会涉及您或他人的个人信息甚至敏感个人信息，如您在评价时选择上传包含个人信息的图片，请您更加谨慎地考虑，是否在使用我们的服务时发布分享相关信息。若您公开发布的信息中涉及他人个人信息的，您需在发布前征得他人的同意。若您公开发布的信息中涉及未成年人个人信息的，需在发布前取得所涉及未成年人的监护人授权同意。前述情形下监护人有权通过本政策文末“如何联系我们”提供的联系方式联系我们，要求更正或删除涉及未成年人个人信息的内容。
            </p>
            <p>9.为您提供安全保障</p>
            <p>为履行保障电子商务交易安全的法定义务，提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入风险，更准确地识别违反法律法规或美滋来相关协议、规则的情况：
            </p>
            <p>（1）经您授权我们会获取您的设备相关信息（包括设备型号、设备名称、操作系统版本、设备设置、设备标识符IMEI/IMSI/MEID/IDFA/OAID/ IDFV/GAID/ android
                ID/IP地址、设备Mac地址、设备环境、已安装移动应用列表及其他软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站及其他传感器信息）。</p>
            <p>（2）我们在应用程序中嵌入我们关联公司开发的应用安全SDK收集您的设备信息、服务日志信息，并可能使用或整合您的用户信息、交易信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息（其中，我们可能会在应用后台运行期间收集MAC地址、IMEI及其他设备标识符进行风险校验），来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
            </p>
            <p>（二）您可以选择授权我们收集和使用个人信息的附加服务场景</p>
            <p>1、基于系统权限的附加服务</p>
            <p>如果您不同意开启相关权限，不会影响您使用美滋来的基本服务，但您可能无法获得这些附加服务给您带来的用户体验。</p>
            <p>您可在您的设备设置或我们的相关客户端“设置-隐私-系统权限”中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。</p>
            <p>请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
            </p>
            <p>您可点击此处查看应用权限申请与使用情况说明。</p>
            <p>2、Cookie和同类技术的服务</p>
            <p>Cookie和同类设备信息标识技术是互联网中普遍使用的技术。当您使用我们的服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie主要为了保障产品与服务的安全、高效运转，可以使我们确认您账户与交易的安全状态，排查崩溃、延迟的相关异常情况，帮助您省去重复您填写表单、输入搜索内容的步骤和流程。
            </p>
            <p>大多数浏览器均为用户提供了清楚浏览器缓存数据的功能，您可以进行相应的数据清除操作，或可修改对Cookie的接受程度或拒绝我们的Cookie。您可能因为这些修改，无法使用依赖于Cookie的服务或相应功能。
            </p>
            <p>（三）其他</p>
            <p>1、为了向您提供您所需的特定服务，在您启动我们的产品或访问特定页面时，我们会读取您的剪贴板内容以判断是否有相关口令码（如饿口令）信息，仅当剪贴板有前述口令码信息时，我们才会收集该口令码做云端校验后并向您反馈您所需的内容或绑定相关权益。请您放心，我们不会存储您的剪贴板其他信息，且单独的剪贴板信息无法识别您的特定身份。
            </p>
            <p>2、若您提供的信息中含有其他用户的个人信息，在向美滋来提供这些个人信息之前，您需确保您已经取得合法的授权。</p>
            <p>3、若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，或者我们主动从第三方处获取您的个人信息，均会事先征求您的同意。</p>
            <p>4、若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感个人信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们的专业安全团队对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。
            </p>
            <p>5、征得授权同意的例外</p>
            <p>您充分理解并同意，根据相关法律法规规定，我们在以下情形中收集、使用您的个人信息无需征得您的授权同意：</p>
            <p>1）与我们履行法律、法规规定的义务相关的；</p>
            <p>2）与国家安全、国防安全直接相关的；</p>
            <p>3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p>4）与犯罪侦查、起诉、审判和判决执行等司法或行政执法直接相关的；</p>
            <p>5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
            <p>6）您自行向社会公众公开的个人信息；</p>
            <p>7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
            <p>8）根据与您签订和履行相关协议或其他书面文件所必需的；</p>
            <p>9）用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；</p>
            <p>10）法律法规规定的其他情形。</p>
            <p>请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化后研究、统计分析和预测，用于改善美滋来的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。
            </p>
            <p>6、如我们停止运营美滋来产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。涉及未成年人个人信息的，我们会将停止运营的通知及时告知未成年人监护人。
            </p>
            <p>三、对外提供信息</p>
            <p>（一）共享</p>
            <p>我们不会与美滋来服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：</p>
            <p>1、履行法定义务所必需的共享：我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，对外共享您的个人信息。</p>
            <p>2、取得您同意的情况下共享：基于提供具体业务场景的需要，在获得您的明确同意后，我们会与其他方共享您的个人信息。如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性，并以确认协议、具体场景下的文案确认、弹窗提示及其他形式征得您的同意，或确认第三方已征得您的同意。
            </p>
            <p>3、为订立、履行您作为一方当事人的合同所必需的情况下的共享：您通过美滋来购买商品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关商品和服务的提供者，以实现您的交易、配送及售后服务需求；当您需要进行售后保险理赔时，我们必须向第三方保险机构提供理赔所需必要信息。其中针对您的联络信息和地址信息，我们已经采取了默认开启的“号码保护”及其他举措尽全力保护您的个人信息。
            </p>
            <p>4、与关联公司间共享：为便于我们于关联公司共同向您提供部分服务，我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的敏感个人信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
            </p>
            <p>请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您更加谨慎地考虑并做出决定。</p>
            <p>（二）委托处理</p>
            <p>我们可能委托授权合作伙伴处理您的个人信息，以便授权合作伙伴代表我们为您提供某些服务或履行职能。我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作伙伴将您的信息用于我们未委托的用途，其将单独征得您的同意。
            </p>
            <p>目前，我们的授权合作伙伴包括以下类型：</p>
            <p>（1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享。我们会委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息，但不会提供您的个人身份信息，或者我们将这些信息进行去标识化处理，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。
            </p>
            <p>（2）供应商、服务提供商和其他合作伙伴。我们将信息委托给支持我们业务的供应商、服务提供商和其他合作伙伴处理，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、提供客户服务、支付便利或进行学术研究和调查。
            </p>
            <p>为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序，我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全监测，并与授权合作伙伴约定严格的数据保护措施，令其按照本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。但授权合作伙伴单独获取您的同意的，其为个人信息处理者。
            </p>
            <p>点击查看我们接入授权合作伙伴的SDK详情。</p>
            <p>（三）转让</p>
            <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
            <p>1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
            <p>2、如果我们因合并、分立、解散、被宣告破产的原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式。接收方将继续履行本政策及其他法定义务。接收方变更原先的处理目的、处理方式的，会重新取得您的同意。
            </p>
            <p>（四）公开披露</p>
            <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
            <p>1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</p>
            <p>2、如果我们确定您出现违反法律法规或严重违反美滋来相关协议及规则的情况，或为保护美滋来用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及美滋来已对您采取的措施。
            </p>
            <p>（五）共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
            <p>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
            <p>1、与我们履行法律法规规定的义务相关的；</p>
            <p>2、与国家安全、国防安全直接相关的；</p>
            <p>3、与公共安全、公共卫生、重大公共利益直接想关的；</p>
            <p>4、与犯罪侦查、起诉、审判和判决执行等司法或行政执法直接相关的；</p>
            <p>5、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
            <p>6、您自行向社会公众公开的个人信息；</p>
            <p>7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
            <p>请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。</p>
            <p>四、您的权利</p>
            <p>(一) 查询、更正、补充、删除您的信息</p>
            <p>除法律法规规定的例外情况，您有权查询、更正、补充、删除您的信息，您可以通过以下方式自行进行：</p>
            <p>1.账户信息：您可以登录美滋来App，进入“我的-个人资料”查询、更正、补充您的个人资料及个人账户相关信息，包括您的账号基础信息、账号绑定信息。</p>
            <p>2.地址信息：您可以登录美滋来App，进入“我的—个人资料—收货地址” 中随时添加、更改、删除您的收货地址信息（包括收货人姓名、性别、收货地址、电话号码）。</p>
            <p>3.订单信息：您可以登录美滋来App，进入“订单”，查看、删除您的订单信息，包括已完成订单、待消费订单、待评价订单和退款订单。</p>
            <p>4.搜索浏览信息：您可以在美滋来App中访问或删除您的搜索历史记录以及管理其他数据。</p>
            <p>如果您无法通过上述链接管理个人信息的，您可以随时通过美滋来客服联系我们。</p>
            <p>另外，在以下情形中，您也可以通过与【美滋来客服联系】向我们提出删除个人信息的请求：</p>
            <p>1、 如果我们处理个人信息的行为违反法律法规；</p>
            <p>2、 如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
            <p>3、 如果我们处理个人信息的行为严重违反了与您的约定；</p>
            <p>4、 如果我们的处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
            <p>5、 如果我们停止提供产品或者服务，或者保存期限已届满。</p>
            <p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。</p>
            <p>当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。</p>
            <p>(二) 改变您授权同意的范围</p>
            <p>您可以通过以下方式改变或者撤回您授权我们收集和处理您的个人信息的范围：</p>
            <p>您可以在美滋来App“我的—设置—隐私—系统权限”中管理App使用的系统权限授权。（例如，您可以在“我的—设置—隐私”中收回您“允许美滋来访问相机权限”的用户同意授权）。</p>
            <p>对于您无法直接通过上述方式设置的授权，您可以通过与美滋来App联系进行修改。但请注意，对于部分类型的个人信息，例如实现美滋来基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能没有办法响应您改变授权范围的请求（详见（六）响应您的上述请求）。
            </p>
            <p>当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</p>
            <p>(三) 注销您的账户</p>
            <p>您可以通过以下方式申请注销您的账户：</p>
            <p>1. 您可以自行在“我的-设置-账号安全-注销账号”提交账号注销申请；</p>
            <p>2. 联系美滋来客服寻求帮助，协助您申请注销您的账户。</p>
            <p>在您主动注销账号之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
            <p>(四) 自主管理商业化信息推送</p>
            <p>消息推送：如果您不希望收到美滋来App推送的消息通知，您可以在美滋来App“我的—设置—消息通知”中关闭对应的通知功能。</p>
            <p>短信通知：如您不希望接收我们的平台短信，可通过短信中提供的短信退订方式进行退订，我们根据您的退订记录，不再下发短信。</p>
            <p>备注：我们在获取投诉原因或处理投诉过程中对您进行电话回访，我们该行为不在信息化推送范围内。 </p>
            <p>（五）约束信息系统自动决策</p>
            <p>在某些业务功能中，我们可能仅依据算法在内的非人工自动决策机制做出决定。如果这些决定影响您的合法权益，您可以通过客服联系我们。</p>
            <p>(六) 响应您的上述请求</p>
            <p>对于您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，您可以通过客服联系我们或直接向我们的个人信息保护专职部门发起投诉。我们将在15天内做出答复。
            </p>
            <p>为保障您的账户安全和您的个人信息安全，您向我们提出上述请求时，我们可能会先验证您的身份（如增加账户核验、要求您提供书面请求或其他合理方式），然后再处理您的请求。</p>
            <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无合理理由重复申请的信息，或者需要过多技术手段（如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
            </p>
            <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
            <p>1. 与我们履行法律法规规定的义务相关的；</p>
            <p>2. 与国家安全、国防安全有关的；</p>
            <p>3. 与公共安全、公共卫生、重大公共利益有关的；</p>
            <p>4. 与犯罪侦查、起诉、审判和执行判决等有关的；</p>
            <p>5. 有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
            <p>6. &nbsp;出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</p>
            <p>7. 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
            <p>8. 涉及商业秘密的。</p>
            <p>五、信息的存储</p>
            <p>（一）存储期限</p>
            <p>我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的必要期限内保留您的个人信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。
            </p>
            <p>我们判断个人信息存储期限主要依据以下标准：</p>
            <p>1、完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；</p>
            <p>2、保证我们为您提供服务的安全和质量；</p>
            <p>3、您是否同意更长的留存期间；</p>
            <p>4、是否存在保留期限的其他特别约定或法律法规规定。</p>
            <p>在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
            <p>（二）存储位置</p>
            <p>我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中国境内，但以下情形除外：</p>
            <p>1、法律法规有明确规定的；</p>
            <p>2、单独征得您的授权同意；</p>
            <p>3、您作为一方当事人的跨境电子商务交易及其他类型合同订立和履行所必需的。</p>
            <p>在上述情形中，我们会确保以不低于本政策规定的程度保护您的个人信息。</p>
            <p>（三）存储安全</p>
            <p>1、数据保护技术措施</p>
            <p>我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对美滋来网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。目前，我们的重要信息系统已经通过网络安全等级保护三级认证和ISO/IEC
                27701:2019认证。</p>
            <p>2、数据保护管理组织措施</p>
            <p>我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术的维度提升个人信息的安全性。我们已经设置了个人信息保护专职部门。我们通过培训课程和考试，不断加强员工对于保护个人信息重要性的认识。
            </p>
            <p>3、账户安全风险的防范</p>
            <p>互联网并非绝对安全的环境，使用美滋来服务时，我们强烈建议您不要使用非美滋来推荐的通信方式发送您的信息，以免信息被窃取甚至遭遇电信网络诈骗。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容相关信息的第三方。
            </p>
            <p>在使用美滋来服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账号或密码发生泄露，请您立即联络美滋来客服，以便我们根据您的申请采取相应措施。
            </p>
            <p>请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。</p>
            <p>4、个人信息安全事件的响应</p>
            <p>如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施，以尽可能降低对您个人的影响。在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。事件相关情况我们将以邮件、信函、电话、推送通知方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
            </p>
            <p>同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
            <p>六、政策的更新</p>
            <p>为给您提供更好的产品和服务体验，我们持续努力改进我们的产品、服务及技术。在新的服务及业务流程变更时，我们可能会更新我们的隐私政策以告知您具体的变化。但未经您明确同意，我们不会限制您按照本政策所享有的权利。我们会在专门页面（如美滋来APP“我的-设置-隐私-隐私政策”）上展示最新版的隐私权政策。也请您访问我们以便及时了解最新的隐私政策，若您继续使用我们的服务，即表示同意接受更新后的本政策并受之约束。
            </p>
            <p>对于重大变更，我们还会提供更为显著的通知（包括我们会通过美滋来公示的方式进行通知甚至向您提供弹窗提示）。</p>
            <p>本政策所指的重大变更包括但不限于：</p>
            <p>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式；</p>
            <p>2、业务调整、交易并购引起的隐私政策的主体变更，新主体接收方变更原先的处理目的、处理方式；；</p>
            <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>
            <p>4、用户个人信息权利及其行使方式发生重大变化；</p>
            <p>5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；</p>
            <p>6、个人信息保护影响评估报告表明存在对个人权益有重大影响时。</p>
            <p>七、未成年人保护</p>
            <p>在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人的，我们建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</p>
            <p>一旦我们识别出您是未成年人用户的，我们将通知您的监护人并要求您的监护人同意未成年人个人信息收集使用规则。对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
            </p>
            <p>若您是未成年人的父母或其他监护人，请您关注您监护的未成年人是否是在取得您的授权同意之后使用我们的服务。如您对您所监护的为成年人的个人信息有疑问，请通过文末的联系方式与我们联系。</p>
            <p>八、联系我们</p>
            <p>如对本政策内容有任何疑问、意见或建议，或如发现个人信息可能被泄露，您可以通过以下方式与我们联系，我们将在15天内回复您的请求：</p>
            <p>1、您可以通过美滋来官网(http://www.almas.biz/)下方的电子邮箱/联系电话与我们联系。</p>
            <p>2、通过美滋来客服电话：400-1111-990 联系我们；</p>
            <p>3、我们还设立了个人信息保护专职部门，您可以通过电子邮箱app@almas.com发送电子邮件，或书面邮寄的方式（收件地址：新疆乌鲁木齐市天山区延安路瑞达世贸大厦A座-1236室）联系我们。需注意的是，我们可能不会回复与本政策或您的个人信息权利无关的问题。
            </p>
            <p>如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</p>
            <p>&nbsp;</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;附录：相关定义</p>
            <p>1、美滋来：指美滋来（域名为http://www.almas.biz/）网站、移动客户端、H5页面和小程序。</p>
            <p>2、美滋来服务提供者：指美滋来的互联网信息及软件技术服务提供者新疆金钻软件有限公司,注册地址为新疆乌鲁木齐市天山区延安路瑞达世贸大厦A座-1236室 。</p>
            <p>3、关联公司：指新疆金钻软件有限公司的关联公司。</p>
            <p>4、个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
            <p>5、敏感个人信息：敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹信息，以及不满十四周岁未成年人的个人信息。
            </p>
            <p>6、去标识化：指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。</p>
            <p>7、匿名化：指个人信息经过处理无法识别特定自然人且不能复原的过程。</p>
        </div>
    </div>
</div></template>

<script>


export default {

    data() {
        return {
            title: '',

        }
    },
    created() {
        this.title = this.$route.query.data
    },
    methods: {

    }






}

</script>