var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-img",attrs:{"src":"https://cdn.mulazim.com/images/platform-certificate/jinzuan.jpg"},on:{"click":function($event){return _vm.previewImg(
          'https://cdn.mulazim.com/images/platform-certificate/jinzuan.jpg'
        )}}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-img",attrs:{"src":"https://cdn.mulazim.com/company_info/meizilaiyingyezhijiao.jpg"},on:{"click":function($event){return _vm.previewImg(
          'https://cdn.mulazim.com/company_info/meizilaiyingyezhijiao.jpg'
        )}}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-img",attrs:{"src":"https://cdn.mulazim.com/company_info/shipinxukejing.jpg"},on:{"click":function($event){return _vm.previewImg(
          'https://cdn.mulazim.com/company_info/shipinxukejing.jpg'
        )}}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-img",attrs:{"src":"https://cdn.mulazim.com/images/platform-certificate/001.jpg"},on:{"click":function($event){return _vm.previewImg(
          'https://cdn.mulazim.com/images/platform-certificate/001.jpg'
        )}}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-img",attrs:{"src":"https://cdn.mulazim.com/images/platform-certificate/002.jpg"},on:{"click":function($event){return _vm.previewImg(
          'https://cdn.mulazim.com/images/platform-certificate/002.jpg'
        )}}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-img",attrs:{"src":"https://cdn.mulazim.com/images/platform-certificate/2017-7-26Android-V2.0.jpg"},on:{"click":function($event){return _vm.previewImg(
          'https://cdn.mulazim.com/images/platform-certificate/2017-7-26Android-V2.0.jpg'
        )}}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-img",attrs:{"src":"https://cdn.mulazim.com/images/platform-certificate/2017-7-26peisongyuanAndroid001.jpg"},on:{"click":function($event){return _vm.previewImg(
          'https://cdn.mulazim.com/images/platform-certificate/2017-7-26peisongyuanAndroid001.jpg'
        )}}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-img",attrs:{"src":"https://cdn.mulazim.com/images/platform-certificate/2017-7-25houtai-V2.0.jpg"},on:{"click":function($event){return _vm.previewImg(
          'https://cdn.mulazim.com/images/platform-certificate/2017-7-25houtai-V2.0.jpg'
        )}}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"item-img",attrs:{"src":"https://cdn.mulazim.com/images/platform-certificate/2017-7-4WAP001.jpg"},on:{"click":function($event){return _vm.previewImg(
          'https://cdn.mulazim.com/images/platform-certificate/2017-7-4WAP001.jpg'
        )}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }