var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bodyClass"},[(_vm.lang == 'ug')?_c('div',{class:{ bodyRtl: _vm.lang == 'ug' }},[_vm._m(0),_c('p',{staticStyle:{"text-align":"center"}}),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('p',{staticStyle:{"line-height":"200%"}}),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_vm._m(32),_vm._m(33),_vm._m(34),_c('p',{staticStyle:{"line-height":"200%"}}),_vm._m(35),_vm._m(36)]):_vm._e(),(_vm.lang == 'zh')?_c('div',[_vm._m(37),_vm._m(38),_vm._m(39),_vm._m(40),_vm._m(41),_vm._m(42),_vm._m(43),_vm._m(44),_vm._m(45),_vm._m(46),_vm._m(47),_vm._m(48),_vm._m(49),_vm._m(50),_vm._m(51),_vm._m(52),_vm._m(53),_vm._m(54),_vm._m(55),_vm._m(56),_vm._m(57),_vm._m(58),_vm._m(59),_vm._m(60),_vm._m(61),_c('p'),_c('p')]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","font-size":"15pt"}},[_vm._v("«جۇڭچيۇ بايرىمىلىق مۇكاپاتلىق چەك تارتىش پائالىيىتى»نىڭ پائالىيەت قائىدىسى")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"150%","text-align":"center"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","font-size":"15pt"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"150%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","font-size":"18px"}},[_c('strong',[_vm._v("1. پائالىيەت چۈشەندۈرۈلۈشى")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"150%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("كەڭ خېرىدارلارغا مىننەتدارلىق بىلدۈرۈش يۈزىسىدىن")]),_vm._v(" «تەلەي چاقپەلىكى ئايلاندۇرۇش» مۇكاپاتلىق چەك تارتىش پائالىيىتى ئورۇنلاشتۇرۇلدى. بۇ قېتىملىق پائالىيىتىمىزگە قاتناشقان ھەربىرەيلەننىڭ كاتتا مۇكاپاتلارغا ئېرىشىش پۇرسىتى بار. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong',[_vm._v("2. قاتنىشىش ئۇسۇلى")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"tight",attrs:{"data-tight":"true"}},[_c('li',[_c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong')]),_c('strong',[_vm._v("ئېتىبار بىلەت سېتىۋېلىپ قاتنىشىش: ")]),_vm._v("9.9 يۈەن خەجلەپ ئومۇمىي قىممىتى 30 يۈەنلىك 8 دانە مۇلازىم سۇپىسى ئېتىبار بېلىتى سېتىۋالسىڭىز، بىر قېتىم چەك تارتىش پۇرسىتىگە ئېرىشەلەيسىز. سېتىۋېلىش قېتىم سانىغا چەك قويۇلمايدۇ. ")])]),_c('li',[_c('p',{staticStyle:{"line-height":"200%"}},[_c('strong',[_vm._v("زاكاز چۈشۈرۈپ قاتنىشىش:")]),_vm._v(" مۇلازىم سۇپىسىدىن بىر قېتىمدا ئومۇمىي سوممىسى 60 يۈەندىن يۇقىرى زاكاز چۈشۈرسىڭىز، بىر قېتىم چەك تارتىش پۇرسىتىگە ئېرىشەلەيسىز. ")])]),_c('li',[_c('p',{staticStyle:{"line-height":"200%"}},[_c('strong',[_vm._v("ھەمبەھىرلەپ قاتنىشىش:")]),_vm._v(" پائالىيەت بېتىنى دوسلىرىڭىزغا ھەمبەھىرلەپ بەرگەندىن كېيىن، دوستىڭىز شۇ ئۇلانمىدىن كىرىپ يۇقارقى ئىككى خىل ئۇسۇلدا مەزكۇر پائالىيىتىمىزگە قاتناشسا، بىر قېتىملىق چەك تارتىش پۇرسىتىگە ئېرىشەلەيسىز. بىر دوستىڭىز سىزنى يۇقارقى ئىككى خىل ئۇسۇلنىڭ ھەربىرىدە بىر قېتىملا پۇرسەتكە ئېرىشتۈرەلەيدۇ."),_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v(" ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong',[_vm._v("3. ")])]),_c('span',{staticStyle:{"font-family":"UKIJEkran","font-size":"18px"}},[_c('strong',[_vm._v("پائالىيەت ")])]),_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong',[_vm._v("ئورنى")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("مۇلازىم ئەپچىقى، مۇلازىم ئالما، ئاندروئىد ئەپلىرى.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong',[_vm._v("4. پائالىيەت ۋاقتى ۋە مۇكاپاتلاش ئۇسۇلى")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_c('strong',[_vm._v("پائالىيەت ۋاقتى:")]),_vm._v(" 2024-يىلى 9-ئاينىڭ 21-كۈنى سائەت 0 دىن 2024-يىلى 9-ئاينىڭ 25-كۈنى سائەت 23:59 گىچە.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_c('strong',[_vm._v("مۇكاپاتلاش ئۇسۇلى: ")]),_vm._v("قاتناشقۇچى تەلەي چاقپەلىكىنى ئايلاندۇرۇپ چەك تارتىشقا قاتناشقاندىن كېيىن، چاقپەلەك توختاش بىلەن بىرگە مۇكاپات نەتىجىسى ئاشكارلىنىدۇ. ھەربىر قاتناشقۇچىنىڭ ھەر قېتىمدىكى مۇكاپات چىقىش ئېھتىماللىقى ئايرىم ھېسابلىنىدۇ، باشقا چەك تارتىش پۇرسەتلىرىدىكى مۇكاپات چىقىش ئېھتىماللىقى بىلەن مۇناسىۋەتسىز.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong',[_vm._v("5. چەك تارتىش پۇرسىتىنى ئىشلىتىش")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("قاتناشقۇچى چەك تارتىش پۇرسىتىنى (مەيلى قايسى خىل ئۇسۇلدا ئېرىشكەن بولسۇن) چوقۇم پائالىيەت ئاخىرلىشىشتىن (2024-يىلى 9-ئاينىڭ 25-كۈنى سائەت 23:59) بۇرۇن ئىشلىتىۋېتىشى شەرت. ئەگەر پائالىيەت ئاخىرلىشىشتىن بۇرۇن چەك تارتىش پۇرسىتىنى ئىشلىتىپ بولالمىغان ياكى ئىشلەتمىگەن بولسا، بىردەك چەك تارتىشتىن ۋاز كەچكەن دەپ قارىلىپ، مۇلازىم سۇپىسى بۇ پۇرسەتلەرنى پۇلغا سۇندۇرۇپ ھېساپلىمايدۇ ھەم ھېچقانداق ھەق قايتۇرمايدۇ. قاتناشقۇچىلارنىڭ ۋاقىتنى ياخشى ئورۇنلاشتۇرۇپ، پائالىيەت ئاخىرلىشىشتىن بۇرۇن بارلىق پۇرسەتلىرىنى ئىشلىتىۋېتىشىنى سورايمىز.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong',[_vm._v("6. ئېتىبار بېلىتىنى ئىشلىتىش ئۇسۇلى")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("1) 8 دانە ئېتىبار بىلىتىنىڭ تەپسىلاتى تۆۋەندىكىچە:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("4 دانە ئۈچ يۈەنلىك ئېتىبار بىلىتى، 25 يۈەنلىك زاكازغا ئىشلەتكىلى بولىدۇ.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("2 دانە تۆت يۈەنلىك ئېتىبار بىلىتى، 30 يۈەنلىك زاكازغا ئىشلەتكىلى بولىدۇ.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("2 دانە بەش يۈەنلىك ئېتىبار بىلىتى، 40 يۈەنلىك زاكازغا ئىشلەتكىلى بولىدۇ.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("ئەسكەرتىش: يۇقارقى ئېتىبار بىلەتلىرىنى بىر قېتىمدا پەقەت بىرنىلا ئىشلىتەلەيسىز.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("2) ئېتىبار بېلەتنىڭ ئىناۋەتلىك ۋاقتى 2024 – يىلى 10 – ئاينىڭ 31 – كۈنى 23:59:59 غىچە بولۇپ، بەلگىلەنگەن ۋاقىت ئىچىدە ئىشلەتمىگەنلەر ئۆزلۈكىدىن ئېتىبار بېلەتنى ئىشلىتىشتىن ۋاز كەچكەن ھېسابلىنىدۇ.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong',[_vm._v("7. مۇكاپات تۈرلىرى")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("1) "),_c('strong',[_vm._v("بىرىنجى دەرىجىلىك مۇكاپات:")]),_vm._v(" ئالما 16 يانفون 256GB نۇسخىسى، مۇكاپات سانى 10؛")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("2) "),_c('strong',[_vm._v("ئىككىنجى دەرىجىلىك مۇكاپات:")]),_vm._v(" ئوپپو رېنو يانفون، مۇكاپات سانى 20؛")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("3) "),_c('strong',[_vm._v("ئۈچىنجى دەرىجىلىك مۇكاپات:")]),_vm._v(" شياۋمى ئەقلىي بىلەيزۈكى، مۇكاپات سانى 100؛")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong',[_vm._v("8. مۇكاپات نەقلەشتۈرۈش")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("مۇكاپات بۇيۇملىرى پائالىيەت ئاخىرلىشىپ 15 خىزمەت كۈنى ئىچىدە سۇپىمىز مۇكاپاتقا ئېرىشكۈچى چەك تارتىش پائالىيىتىدە قالدۇرغان ئالاقە نۇمۇرى ئارقىلىق ئالاقىلىشىپ مۇكاپاتنى نەقلەشتۈرۈش ۋاقتى ۋە ئورنىنى ئۇقتۇرىدۇ، شۇڭا چەك تارتىپ مۇكاپات چىققاندىن كېيىن چوقۇم توغرا بولغان مال تاپشۇرۇۋېلىش ئادرېسىڭىز ۋە تېلېفون نومۇرىڭىزنى تولدۇرۇڭ ۋە ئالاقە نۇمۇرىڭىزنىڭ ھەرۋاقىت نورمال ئالاقىلاشقىلى بولۇشىغا كاپالەتلىك قىلىڭ.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong',[_vm._v("9. ئەسكەرتىش")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("1) "),_c('strong',[_vm._v("ئادىل بولۇش:")]),_vm._v(" مەزكۇر پائالىيەتتە ھەرقانداق شەكىلدە مۇكاپات چىقىش نەتىجىسىگە تەسىر كۆرسىتىدىغان تېخنىكىلىق ۋەياكى باشقا شەكىلدىكى ناتوغرا ئۇسۇللارنى قوللىنىش چەكلىنىدۇ. ئەگەر بايقىلىپ قالسا، سۇپىمىز مەزكۇر قاتناشقۇچىنىڭ مۇكاپاتقا ئېرىشىش سالاھىيىتىنى بىكار قىلىپ، تارقىتىلغان ئېتىبار بىلەت ۋە مۇكاپاتنى قايتۇرۇۋېلىش بىلەن بىرگە قانۇنىي جاۋاپكارلىقىنى سۈرۈشتە قىلىدۇ.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("2) "),_c('strong',[_vm._v("پائالىيەت چۈشەندۈرۈش ھوقوقى: ")]),_vm._v("مەزكۇر پائالىيەتنىڭ چۈشەندۈرۈش ھوقوقى مۇلازىم سۇپىسىغا تەۋە. سۇپىمىز پائالىيەت ئەھۋالىغا قاراپ، پائالىيەت قائىدىسىنى تەڭشەشكە ھوقوقلۇق. تەڭشەش ئېلىپ بېرىشتىن بۇرۇن سۇپىمىزدا ئالدىن ئۇقتۇرۇش قىلىمىز.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"200%"}},[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(0, 0, 0)","font-size":"18px"}},[_c('strong',[_vm._v("10. ئالاقە ئۇسۇلى")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-family":"UKIJEkran","color":"rgb(110, 110, 110)"}},[_vm._v("ئەگەر سۇئالىڭىز بولسا ياكى ياردەمگە ئېھتىياجلىق بولسىڭىز، ھەرۋاقىت سۇپىمىز بىلەن ئالاقىلىشىڭ. ئالاقە نۇمۇرى: 990-1111-400.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticStyle:{"line-height":"1.45","text-align":"center"}},[_c('strong',[_vm._v("“中秋节幸运大转盘”抽奖活动规则")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("一、活动概述")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_vm._v("为了回馈广大用户的支持与厚爱，美滋来外卖平台特举办“中秋节幸运大转盘”抽奖活动。本次活动旨在通过趣味互动，让每一位参与者都有机会赢取丰厚奖品，共享欢乐时光。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("二、参与方式")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"tight",attrs:{"data-tight":"true"}},[_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_c('strong',[_vm._v("付费购买抽奖")]),_vm._v("：用户可通过支付9.9元购买总额为30元的8张平台优惠券，即可自动获得一次抽奖机会。")])])]),_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_c('strong',[_vm._v("自然消费抽奖")]),_vm._v("：凡在mulazim外卖平台上单次下单金额超过60元的用户，即可自动获得一次抽奖机会。")])])]),_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_c('strong',[_vm._v("邀请好友助力")]),_vm._v("：用户将活动页面分享给好友，被邀请的好友在美滋来外卖平台通过上述两种方式的任意一种方式参与本次活动后，邀请者可获得一次免费抽奖机会。一个好友通过一种方式参与最多只能给邀请者赠送一次机会。邀请人数不限，抽奖机会可累加，同一个好友只能邀请一次。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("三、参与地点")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_vm._v("美滋来外卖平台微信小程序、苹果App、安卓App端。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("四、活动时间与开奖方式")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"tight",attrs:{"data-tight":"true"}},[_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_c('strong',[_vm._v("活动时间")]),_vm._v("：2024年9月21日00:00至2024年9月25日23:59。")])])]),_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_c('strong',[_vm._v("开奖方式")]),_vm._v("：参与者通过转动幸运大转盘进行抽奖，转盘停止后实时显示中奖结果。每位用户每次抽奖机会独立计算，不可累积使用。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("五、抽奖机会使用")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_vm._v("参与者的抽奖次数（无论是通过消费获得还是付费购买）必须在活动结束时间（即2024年9月25日23:59）之前使用完毕。任何未在此时间之前使用的抽奖次数，均视为主动放弃，且平台不予折算购买机会的金额，同时也不会退还任何费用。请参与者合理安排时间，确保在活动期间内使用完所有抽奖机会。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("六、优惠券使用说明")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_vm._v("1.优惠券详情如下：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"10px"},attrs:{"data-indent":"1"}},[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_vm._v("4张3元优惠券，单笔下单总额满25元即可使用一张；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"10px"},attrs:{"data-indent":"1"}},[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_vm._v("2张4元优惠券，单笔下单总额满30元即可使用一张；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"10px"},attrs:{"data-indent":"1"}},[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_vm._v("2张5元优惠券，单笔下单总额满40元即可使用一张；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_vm._v("2.优惠券使用期为2024年10月31日23:59:59之前， 如未在此时间前使用优惠券，将视为放弃优惠券使用权。平台不予折算现金同时也不会退还购买优惠券的费用。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("七、奖品设置")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"tight",attrs:{"data-tight":"true"}},[_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_c('strong',[_vm._v("一等奖")]),_vm._v("：共10名，奖品为iPhone 16 256G手机（颜色及型号随机）。")])])]),_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_c('strong',[_vm._v("二等奖")]),_vm._v("：共20名，奖品为OPPO Reno手机。")])])]),_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_c('strong',[_vm._v("三等奖")]),_vm._v("：共100名，奖品为小米手环9。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("八、兑奖方式")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"tight",attrs:{"data-tight":"true"}},[_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_vm._v("参与者中奖后，需保持联系方式畅通，以便平台安排现场面对面发放奖品。具体兑奖时间、地点将另行通知。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("九、注意事项")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"tight",attrs:{"data-tight":"true"}},[_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_c('strong',[_vm._v("公平公正")]),_vm._v("：本次活动严禁任何形式的技术篡改中奖结果或利用不正当手段刷取抽奖机会。一经发现，平台有权取消该用户的中奖资格，追回已发放的奖品及优惠券，并保留进一步追究法律责任的权利。")])])]),_c('li',[_c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_c('strong',[_vm._v("活动解释权")]),_vm._v("：本次活动最终解释权归美滋来外卖平台所有。平台有权根据活动实际情况调整规则，并提前在平台公告。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"line-height":"1.45"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_c('strong',[_vm._v("十、联系我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-size":"11.5pt"}},[_vm._v("如有任何疑问或需要帮助，请随时联系美滋来外卖平台客服400-1111-990，我们将竭诚为您服务。")])])
}]

export { render, staticRenderFns }