<template>
  <div class="bodyClass" :class="{ bodyRtl: lang == 'ug' }">
    <h2>
      يەتكۈزگۈچىلەرنىڭ&nbsp;خېرىدار كۆپەيتىش يوللىرى&nbsp;ۋە ھەق ھېسابلىنىش
      ئۇسۇللىرى
    </h2>
    <h4><b>يېڭى خېرىدار كۆپەيتىپ پۇل تېپىش قائىدىسى</b><b></b></h4>
    <p><b></b></p>
    <p>
      1. يەتكۈزگۈچىلەر سۇپىغا يېڭى خېرىدار قوشۇپ ھەم ئۆزىگە باغلاش ئارقىلىق پۇل
      تېپىش
    </p>
    <p>
      يەتكۈزگۈچىلەر ئۆزىنىڭ ئىككىلىك كودى ياكى باشقا تەشۋىقات ماتېرىياللىرىنى
      ئارقىلىق مۇلازىم سۇپىسىغا يېڭىدىن خېرىدار كۆپەيتسە، يەتكۈزگۈچىلەرگە ھەق
      ھېسابلاپ بېرىلىدۇ.
    </p>
    <p>يېڭى خېرىدار كۆپەيتىش ھەققى: 1-5 يۈەن</p>
    <p>2. سۇپىدا بار خېرىدارنى قوزغىتىش ئارقىلىق پۇل تېپىش</p>
    <p>
      &nbsp;يەتكۈزگۈچىلەر ئۆزىنىڭ ئىككىلىك كودى ياكى باشقا تەشۋىقات
      ماتېرىياللىرى ئارقىلىق مۇلازىم سۇپىسىدا ئەسلىي بار، ئەمما سۈكۈتتىكى
      قوزغىتىپ، ئوڭۇشلۇق ھالدا ئۆزىگە باغلىسا يەتكۈزگۈچىلەرگە ھەق ھېسابلاپ
      بېرىلىدۇ.
    </p>
    <p><b></b></p>
    <p>
      ئا يەتكۈزگۈچى&nbsp;مۇلازىم سۇپىسىدىكى كونا خېرىدار XXX&nbsp;نى
      2024&nbsp;-&nbsp;يىلى 4&nbsp;-&nbsp;ئاينىڭ 6&nbsp;-كۈنى ئۆزىنىڭ ئىككىلىك
      كودى ياكى باشقا تەشۋىقات ماتېرىيالى ئارقىلىق ئۆزىگە ئوڭۇشلۇق باغلىۋالغان.
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;2024 - يىلى 4 - ئاينىڭ 26 - كۈنى ب يەتكۈزگۈچى بۇ
      &nbsp;XXX&nbsp;خېرىدارنى ئۆزىنىڭ ئىككىلىك كودى ياكى باشقا تەشۋىقات
      ماتېرىيالى ئارقىلىق ئۆزىگە باغلىماقچى دەپ پەرەز قىلساق:
    </p>
    <p>
      <!--[if-->1.&nbsp;<!--[endif]-->XXX&nbsp;خېرىدار ھېچقانداق يەتكۈزگۈچىگە
      باغلانمىغان بولسا، نۆۋەتتىكى يەتكۈزگۈچىگە باغلىنىپ يەتكۈزگۈچى تەۋە
      رايوننىڭ ھەق بېرىش ئۆلچىمى بويىچە ھەق ھېسابلىنىدۇ.
    </p>
    <p>
      <!--[if-->2.&nbsp;<!--[endif]-->XXX&nbsp;خېرىدارنىڭ باشقا يەتكۈزگۈچىگە
      باغلانغان ۋاقتى 3 ئايدىن ئېشىپ كەتكەن ھەمدە يېقىنقى 3 ئاي ئىچىدە زاكاز
      چۈشۈرمىگەن بولسا، ئۇنداقتا بۇ XXX&nbsp;خېرىدار نۆۋەتتىكى يەتكۈزگۈچىگە
      باغلىنىدۇ ھەم يەتكۈزگۈچى تەۋە رايوننىڭ ھەق بېرىش ئۆلچىمى بويىچە ھەق
      ھېسابلىنىدۇ.
    </p>
    <p>
      <!--[if-->3.&nbsp;<!--[endif]-->ئەگەر XXX&nbsp;خېرىدار يېقىنقى 3 ئاي
      ئىچىدە مەلۇم يەتكۈزگۈچىگە باغلانغان بولسا، ئۇنداقتا بۇ XXX&nbsp;خېرىدار
      ئەسلىدىكى يەتكۈزگۈچىگە تەۋە بولۇپ، نۆۋەتتىكى يەتكۈزگۈچىگە باغلانمايدۇ ھەم
      ھەقمۇ ھېسابلانمايدۇ.
    </p>
    <p>
      <!--[if-->4.&nbsp;<!--[endif]-->بۇ XXX&nbsp;خېرىدارنىڭ مەلۇم يەتكۈزگۈچىگە
      باغلانغان ۋاقتى 3 ئايدىن ئېشىپ كەتكەن، ئەمما &nbsp;بۇ XXX&nbsp;خېرىدار
      يېقىنقى 3 ئاي ئىچىدە زاكاز چۈشۈرگەن بولسا، ئۇنداقتا بۇ XXX&nbsp;خېرىدار
      داۋاملىق ئەسلىدىكى يەتكۈزگۈچىگە تەۋە بولۇپ نۆۋەتتىكى يەتكۈزگۈچىگە
      باغلانمايدۇ ھەم ھەق ھېسابلانمايدۇ.
    </p>
    <p>سۇپىدىكى خېرىدارنى ئويغىتىپ ئۆزىگە باغلاش ھەققى: 0.3-1 يۈەن</p>
    <p><b></b></p>
    <p>
      يەتكۈزگۈچىلەر تەرەققىي قىلدۇرغان خېرىدارلار زاكاز چۈشۈرگەندە يەتكۈزگۈچىگە
      پايدا ئايرىش قائىدىسى
    </p>
    <p>
      1. يېڭىدىن كۆپەيتكەن خېرىدارلار زاكاز چۈشۈرگەندە يەتكۈزگۈچىلەرگە پايدا
      ئايرىش قائىدىسى
    </p>
    <p>
      <!--[if-->5.&nbsp;<!--[endif]-->يېڭى خېرىدارلار سۇپىغا قوشۇلغان كۈندىن
      باشلاپ 90 كۈنگىچە شۇ خېرىدارنى تەرەققىي قىلدۇرغان يەتكۈزگۈچىگە خېرىدار
      چۈشۈرگەن ھەربىر زاكاز سوممىسىنىڭ %2-%5 بويىچە پايدا ئايرىلىدۇ.
    </p>
    <p>
      <!--[if-->6.&nbsp;<!--[endif]-->يېڭى خېرىدارلار سۇپىغا قوشۇلغان 91 -
      كۈنىدىن باشلاپ شۇ خېرىدارنى تەرەققىي قىلدۇرغان يەتكۈزگۈچىگە خېرىدار
      چۈشۈرگەن ھەربىر زاكاز سوممىسىنىڭ %1-%3 بويىچە پايدا ئايرىلىدۇ.
    </p>
    <p>
      <!--[if-->7.&nbsp;<!--[endif]-->يېڭىدىن كۆپەيتكەن خېرىدار زاكاز چۈشۈرگەندە
      يەتكۈزگۈچىلەرگە پايدا ئايرىش ئۆلچىمى: %2-%5
    </p>
    <p>
      2. سۇپىدىكى قايتا ئويغاتقان خېرىدارلار زاكاز چۈشۈرگەندە يەتكۈزگۈچىلەرگە
      پايدا ئايرىش قائىدىسى
    </p>
    <p>
      <!--[if-->8.&nbsp;<!--[endif]-->قايتا ئويغاتقان خېرىدارلار زاكاز
      چۈشۈرگەندە، ئويغاتقان يەتكۈزگۈچىگە چۈشۈرگەن ھەربىر زاكاز سوممىسىنىڭ %1-%3
      بويىچە پايدا ئايرىلىدۇ.
    </p>
    <p>
      <!--[if-->9.&nbsp;<!--[endif]-->يەتكۈزگۈچىلەر مۇلازىم سۇپىسىدا داۋاملىق
      ئىشلىسە، شۇ يەتكۈزگۈچى تەرەققىي قىلدۇرغان بارلىق خېرىدارلىرىنىڭ چۈشۈرگەن
      زاكازلىرىدىن يۇقىرىقى ئۆلچەم بويىچە پايدا ئايرىلىپ مېڭىلىدۇ، پايدا ئايرىش
      ۋاقتى تاكى يەتكۈزگۈچى مۇلازىمدىن ئايرىلغۇچە داۋاملىشىدۇ.
    </p>
    <p><!--[if-->10.&nbsp;<!--[endif]--></p>
    <p>
      <!--[if-->11.&nbsp;<!--[endif]-->سۇپىدىكى كونا خېرىدارلار قايتا
      ئويغىتىلىپ، زاكاز چۈشۈرگەندە يەتكۈزگۈچىگە پايدا ئايرىش ئۆلچىمى : % 1-%3
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: "ug",
    };
  },
  created() {
    this.lang = this.$route?.query?.lang || "ug";
  },
};
</script>

<style scoped>
.bodyClass {
  width: 100%;
  height: calc(100vh - 0px);
  background-color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 10px 10px 20px;
  box-sizing: border-box;
}
.bodyClass h2 {
  margin-top: 2px;
  margin-bottom: 10px;
}
.bodyClass h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.bodyClass p {
  text-align: justify;
}
.bodyRtl {
  direction: rtl;
}
</style>
