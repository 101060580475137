<template>
  <div class="bodyClass" :class="lang == 'ug' ? 'bodyRtl' : 'bodyLtr'">
    <div v-if="lang == 'zh'">
      <h3 style="width: 100%; text-align: center; padding: 10px 0">
        商家自营销协议
      </h3>
      <p>
        1. 店铺优惠活动可以与美滋来外卖平台的其他活动同时进行，如会员活动等 ;
      </p>
      <p>
        2. 您创建的店铺优惠活动的促销成本由您自己承担,
        美滋来外卖仅是活动设置工具的提供方，您不得要求美滋来外卖开具“店铺优惠“等相关发票
        ;
      </p>
      <p>
        3.
        您拥有店铺优惠的实际所有权和归属权,美滋来外卖仅提供系统工具供您使用并代为展示店铺优惠;
      </p>
      <p>
        4. 与商家结算的金额中, 不包括商家店铺自己发放的优惠， 即:
        合同中的结算为净值 (原订单金额-商家店铺优惠) ;
      </p>
      <p>
        5.
        商家承担全部营销活动成本的，活动期间商家可自行调整（上调或下调）营销活动金额或撤销营销活动;
      </p>
      <p>
        6.
        平台分担商家营销活动成本的，商家按照与美滋来平台约定的金额承担营销活动成本，活动期间商家仅可上调承担的营销活动金额或撤销营销活动，美滋来平台有权根据营销活动效果调整（上调或下调）美滋来平台承担营销活动成本金额并通知商家；为提高双方营销活动效益，商家确认：
      </p>
      <p>（1）在发生恶劣天气且爆单等场景时，平台有权暂停自身营销活动补贴；</p>
      <p>
        （2）在恶劣天气或爆单场景消除后，平台有权恢复原营销活动及双方活动补贴 ;
      </p>
      <p>
        7.
        平台分担商家营销活动成本的其他营销活动（不含满减活动、减配活动和折扣商品活动），商家按照与美滋来平台约定的金额承担营销活动成本，活动期间商家不可调整承担的营销活动金额，但可撤销营销活动；美滋来平台有权根据营销活动效果调整（上调或下调）美滋来平台承担营销活动成本金额并通知商家
        ;
      </p>
      <p>为提高双方营销活动效益，商家确认：</p>
      <p>（1）在发生恶劣天气或爆单等场景时，平台有权暂停自身营销活动补贴；</p>
      <p>
        （2）在恶劣天气或爆单场景消除后，平台有权恢复原营销活动及双方活动补贴；
      </p>
      <p>
        （3）如商家在平台暂停活动补贴期间修改或删除营销活动补贴的，则平台不再恢复原营销活动。
      </p>
      <p>
        8. 进行中的店铺优惠, 若商家在预先设置活动结束时间前结束活动,
        则已发放的优惠仍然有效。
      </p>
    </div>
    <div v-if="lang == 'ug'">
      <h4 style="width: 100%; text-align: center; padding: 10px 0">
        دۇكاندار ئېتىبار پائالىيىتى كېلىشىمى
      </h4>
      <p>
        1. ئەزالار ئېتىبار پائالىيىتى قاتارلىق دۇكان ئېتىبار پائالىيەتلىرى
        مۇلازىم سۇپىسى بىلەن بىر ۋاقىتتا ئېلىپ بېرىلىدۇ.
      </p>
      <p>
        2. دۇكاندار قۇرغان دۇكان ئېتىبار پائالىيەتىنىڭ چىقىمىنى دۇكاندار ئۈستىگە
        ئالىدۇ، مۇلازىم سۇپىسى پەقەت پائالىيەت ئېلىپ بېرىدىغان سۇپا بىلەن
        تەمىنلەيدۇ، مۇلازىم سۇپىسى سىزنى ھېچقانداق تالون بىلەن تەمىنلىمەيدۇ.
      </p>
      <p>
        3. دۇكاندار تەكىللىگەن ئېتىبار پائالىيەتنىڭ ئەمەلىي ئىگىدارلىق ھوقۇقى ۋە
        تەۋەلىك ھوقۇقى دۇكانداردا بولىدۇ، مۇلازىم سۇپىسى پەقەت دۇكاندا ئېلىپ
        بارغان ئېتىبار پائالىيەتنى نامايەن قىلىدىغان سۇپا بىلەن تەمىنلەيدۇ؛
      </p>
      <p>
        4. دۇكاندارلار بىلەن ھېسابات قىلىدىغان سومما، دۇكاندارلار تارقاتقان
        ئېتىبار بېلىتىنى ئۆز ئىچىگە ئالمايدۇ، يەنى توختامدىكى ھېسابات سوممىسى =
        (زاكاز سوممىسى - دۇكان ئېتىبار بېلىتى)؛
      </p>
      <p>
        5. بارلىق چىقىمنى دۇكاندارلار ئۈستىگە ئالغان ئېتىبار پائالىيىتىدىكى،
        پائالىيەت سېتىش سوممىسىنى پائالىيەت مەزگىلىدە دۇكاندارلار ئۆزلىرى يۇقىرى
        – تۆۋەن قىلىپ تەڭشىسە ياكى ئېتىبار پائالىيەتنى ئۆزلۈكىدىن ئەمەلدىن
        قالدۇرسا بولىدۇ؛
      </p>
      <p>
        6. چىقىمنىڭ بىر قىسمىنى سۇپا ئۈستىگە ئالغان ئېتىبار پائالىيىتىدە،
        دۇكاندارلار مۇلازىم سۇپىسى بىلەن پۈتۈشكەن سومما بويىچە پائالىيەت
        چىقىمىنى ئۈستىگە ئالىدۇ، پائالىيەت مەزگىلىدە دۇكاندارلار ئۈستىگە ئالغان
        سېتىش سوممىسىنى يۇقىرىغا تەڭشىسە ياكى پائالىيەتنى ئەمەلدىن قالدۇرسا
        بولىدۇ، مۇلازىم سۇپىسى سېتىش پائالىيىتىنىڭ ئۈنۈمىگە ئاساسەن سۇپا ئۈستىگە
        ئالىدىغان پائالىيەت چىقىمىنى يۇقىرى – تۆۋەن تەڭشىسە بولىدۇ ھەمدە
        دۇكاندارلارغا ئۇقتۇرىدۇ، تەرەپلەرنىڭ مەنپەئەتى ئۈچۈن دۇكاندارلار
        مۇقىملاشتۇرۇشى كېرەك؛
      </p>
      <p>
        (1) ناچار ھاۋارايى كۆرۈلگەن ھەمدە زاكاز مىقدارى ئېشىپ كەتكەن ئەھۋالدا،
        سۇپا پائالىيەت تولۇقلىمىسىنى ۋاقتىنچە توختىتىشقا ھوقۇقلۇق؛
      </p>
      <p>
        (2) ناچار ھاۋارايى ياكى زاكاز يۇقىرى بېسىملىق مەزگىلى ئاياغلاشقاندىن
        كېيىن، سۇپا ئەسلىدىكى ئېتىبار پائالىيىتى ۋە پائالىيەت تولۇقلىمىسىنى
        ئەسلىگە كەلتۈرۈشكە ھوقۇقلۇق؛
      </p>
      <p>
        7. سۇپا پائالىيەت چىقىمىنىڭ مەلۇم قىسمىنى ئۈستىگە ئالغان دۇكاندار
        ئېتىبار پائالىيىتىدىن باشقا ئېتىبار پائالىيەتلەردە (شەرتلىك كېمەيتىش،
        كىرا كېمەيتىش ۋە ئېتىبارلىق سېتىش پائالىيىتىنى ئۆز ئىچىگە ئالمايدۇ)
        دۇكاندارلار سۇپا بىلەن كېلىشكەن ئۆلچەم بويىچە پائالىيەت چىقىمىنى ئۈستىگە
        ئالىدۇ، پائالىيەت مەزگىلىدە دۇكاندارلار ئۈستىگە ئالغان پائالىيەت
        چىقىمىنى ئۆزگەرتىشكە بولمايدۇ، لېكىن پائالىيەتىنى ئەمەلدىن قالدۇرسا
        بولىدۇ؛ مۇلازىم سۇپىسى سېتىش پائالىيىتىنىڭ ئۈنۈمىگە ئاساسەن سۇپا ئۈستىگە
        ئالىدىغان پائالىيەت چىقىمىنى يۇقىرى – تۆۋەن تەڭشىسە بولىدۇ ھەمدە
        دۇكاندارلارغا ئۇقتۇرىدۇ، تەرەپلەرنىڭ مەنپەئەتى ئۈچۈن دۇكاندارلار
        مۇقىملاشتۇرۇشى كېرەك.
      </p>
      <p>
        (1) ناچار ھاۋارايى كۆرۈلگەن ھەمدە زاكاز مىقدارى ئېشىپ كەتكەن ئەھۋالدا،
        سۇپا پائالىيەت تولۇقلىمىسىنى ۋاقتىنچە توختىتىشقا ھوقۇقلۇق؛
      </p>
      <p>
        (2) ناچار ھاۋارايى ياكى زاكاز يۇقىرى بېسىملىق مەزگىلى ئاياغلاشقاندىن
        كېيىن، سۇپا ئەسلىدىكى ئېتىبار پائالىيىتى ۋە پائالىيەت تولۇقلىمىسىنى
        ئەسلىگە كەلتۈرۈشكە ھوقۇقلۇق؛
      </p>
      <p>
        (3) سۇپا پائالىيەت تولۇقلىسىمىنى ۋاقتىنچە توختاتقان مەزگىلدە ئەگەر
        دۇكاندارلار پائالىيەت تولۇقلىمىسىنى ئۆزگەرتكەن ياكى ئەمەلدىن
        قالدۇرۇۋەتكەن بولسا، سۇپا ئەسلىدىكى ئېتىبار پائالىيەتنى ئەسلىگە
        كەلتۈرمەيدۇ.
      </p>
      <p></p>
      <p>
        8. ئەگەر دۇكاندارلار ئېلىپ بېرىلىۋاتقان دۇكان ئېتىبار پائالىيىتىنى
        ئالدىن بېكىتىلگەن پائالىيەت ئاخىرلىشىش ۋاقتىدىن بۇرۇن ئاخىرلاشتۇرسا،
        تارقىتىلىپ بولغان ئېتىبار بېلىتى يەنىلا كۈچكە ئىگە.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: "ug",
    };
  },
  created() {
    this.lang = this.$route?.query?.lang || "ug";
  },
};
</script>

<style scoped>
.bodyClass {
  box-sizing: border-box;
  margin: 14px;
}
.bodyRtl {
  direction: rtl;
}
</style>
