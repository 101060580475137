<template>
  <div class="model-content">
    <div
      class="model_66"
      :class="
        posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
      "
    >
      <img
        class="img-box"
        :src="posterList.lang == 'ug' || !posterList.lang ? url_ug : url_zh"
      />

      <div class="prize_img">
        <img :src="posterList.prize_image" alt="" />
      </div>

      <div class="prize_name">
        <div class="prize_name_text">{{ posterList.prize_name }}</div>
      </div>

      <div class="user-info">
        <div class="user-avatar">
          <div class="user-image">
            <img :src="posterList.avatar" alt="" />
          </div>
          <div class="user-name">
            <div class="user-name-text">{{ posterList.name }}</div>
            <div class="user-name-addr">{{ posterList.area }}</div>
          </div>
        </div>
        <div class="user-tel">{{ posterList.mobile }}</div>
        <div class="user-prize">
          <svg
            width="19.000000"
            height="19.000000"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <desc>Created with Pixso.</desc>
            <defs />
            <circle
              id="Oval Copy 2"
              cx="9.975586"
              cy="9.974976"
              r="9.025000"
              fill="#FFC850"
              fill-opacity="1.000000"
            />
            <circle
              id="Oval"
              cx="9.025391"
              cy="10.925049"
              r="7.125000"
              fill="#DD992B"
              fill-opacity="1.000000"
            />
            <circle
              id="Oval Copy 3"
              cx="9.974609"
              cy="9.974976"
              r="7.125000"
              fill="#FFDC64"
              fill-opacity="1.000000"
            />
            <path
              id="Shape"
              d="M14.17 14.68C14.11 14.6 14.01 14.55 13.9 14.55C9.91 14.42 6.71 11.19 6.71 7.22C6.71 6.16 6.94 5.16 7.35 4.25C7.48 3.96 7.16 3.68 6.89 3.85C4.84 5.16 3.55 7.54 3.83 10.18C4.16 13.17 6.53 15.62 9.55 16.07C11.22 16.32 12.78 15.96 14.07 15.19C14.25 15.09 14.3 14.85 14.17 14.68L14.17 14.68Z"
              fill="#FFC850"
              fill-opacity="1.000000"
              fill-rule="evenodd"
            />
            <path
              id="moon-stars-path"
              d="M5.11 5.08L3.79 8.55L2.48 5.08L0 4.27L2.48 3.46L3.79 0L5.11 3.46L7.59 4.27L5.11 5.08L5.11 5.08Z"
              fill="#FFFFFF"
              fill-opacity="1.000000"
              fill-rule="evenodd"
            />
            <path
              id="¥"
              d="M12.54 6.38Q12.53 6.27 12.26 6.19Q11.93 6.11 11.5 6.14Q11.38 6.14 11.29 6.18Q11.12 6.25 11 6.44Q10.9 6.58 10.74 6.84L10.45 7.23L10.18 7.61Q9.94 7.93 9.84 8.05Q9.74 8.17 9.67 8.28L9.13 7.61L8.87 7.23Q8.66 6.95 8.31 6.44Q8.18 6.24 7.99 6.17Q7.9 6.14 7.8 6.14Q7.34 6.11 7 6.19Q6.76 6.25 6.74 6.34Q6.74 6.35 6.74 6.36Q6.74 6.44 6.87 6.59L7.79 7.83L8 8.12L8.48 8.79L8.66 9.03L7.29 9.04Q7.12 9.04 7.04 9.09Q6.96 9.14 6.96 9.31Q6.96 9.5 6.98 9.68Q6.99 9.86 7.04 9.97Q7.06 10.01 7.08 10.03Q7.21 10.21 7.59 10.2Q8.07 10.17 8.65 10.17L9.03 10.17L9.03 10.91L8.62 10.91Q8.32 10.92 7.29 10.92Q7.12 10.92 7.04 10.97Q6.96 11.02 6.96 11.19Q6.96 11.38 6.98 11.56Q6.99 11.74 7.04 11.85Q7.06 11.88 7.08 11.91Q7.21 12.09 7.59 12.08Q8.07 12.05 8.65 12.05L9.03 12.05L9.03 13.58Q9.03 13.79 9.09 13.89Q9.1 13.91 9.11 13.92Q9.18 14 9.33 14Q9.93 14 10.07 13.98Q10.13 13.98 10.17 13.95Q10.29 13.87 10.29 13.61L10.28 13.35L10.28 12.05L11.93 12.05Q12.14 12.05 12.24 11.99Q12.26 11.98 12.27 11.97Q12.34 11.9 12.34 11.75Q12.34 11.26 12.33 11.12Q12.33 11.04 12.29 10.99Q12.21 10.89 12.02 10.89L11.7 10.91L10.28 10.91L10.28 10.17L11.93 10.17Q12.14 10.17 12.24 10.11Q12.26 10.11 12.27 10.1Q12.34 10.03 12.34 9.87Q12.34 9.37 12.33 9.24Q12.33 9.16 12.29 9.11Q12.22 9.02 12.02 9.02L11.7 9.03L10.66 9.03Q10.69 8.99 10.73 8.93Q10.78 8.86 10.82 8.79L11.54 7.83L12.45 6.59Q12.54 6.44 12.54 6.39Q12.54 6.38 12.54 6.38Z"
              fill="#EE9400"
              fill-opacity="1.000000"
              fill-rule="evenodd"
            />
          </svg>
          <span>￥{{ parseInt(posterList.amount) / 100 || 0 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_66",
  data() {
    return {
      posterList: {},
      url_ug: require("../../img/poster/model_ug_66.png"),
      url_zh: require("../../img/poster/model_zh_66.png"),
    };
  },
  created() {
    this.posterList = this.$route.query;
  },
};
</script>

<style scoped>
.model-content {
  width: 391px;
  height: 778px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
}

.model_66 {
  width: 391px;
  height: 778px;
  position: relative;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.img-box {
  width: 100%;
  height: 100%;
}

.prize_img {
  position: absolute;
  top: 230px;
  left: 50%;
  transform: translateX(-50%);
  width: 260px;
  height: 280px;
}

.prize_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prize_name {
  position: absolute;
  width: 300px;
  height: 38px;
  top: 520px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
}

.prize_name_text {
  font-size: 18px;
  color: #000000;
}

.user-info {
  position: absolute;
  width: 320px;
  height: 38px;
  top: 580px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 6px;
  color: #fff;
  font-size: 14px;
}

.user-info .user-avatar {
  display: flex;
}

.user-info .user-name {
  padding: 0px 8px;
}

.user-info .user-avatar .user-image {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
}

.user-info .user-avatar .user-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-info .user-tel {
  direction: ltr;
}

.user-name .user-name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
}

.user-name .user-name-addr {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
}

.user-prize {
  direction: ltr;
  display: flex;
  align-items: center;
  column-gap: 4px;
}
</style>
