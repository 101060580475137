<template>
  <div class="model-content">
    <div
      class="model_32"
      :class="
        posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
      "
    >
      <div class="img-box">
        <img style="width: 100%" :src="url" />
      </div>

      <div class="merchant-name">
        <div class="merchant-name-title">{{ posterList.merchant_name }}</div>
      </div>

      <div class="poster-title">
        <div
          class="poster-title-text"
          v-if="posterList.lang == 'ug' || !posterList.lang"
        >
          تالىشىپ سېتىۋېلىش
        </div>
        <div class="poster-title-text" v-if="posterList.lang == 'zh'">
          秒杀活动
        </div>
      </div>

      <div class="date">
        <div class="date_text">{{ posterList.date }}</div>
      </div>

      <div class="food_name">
        <div class="food_name_text">{{ posterList.food_name }}</div>
      </div>

      <div class="food_img">
        <img :src="posterList.food_img" alt="" />
      </div>

      <div class="food-price-box">
        <div
          class="food_price"
          :style="{ fontSize: posterList.fontSize + 'px' }"
        >
          <span>{{ posterList.food_price }}</span>
          <span
            v-if="posterList.lang == 'ug'"
            :style="{ fontSize: posterList.fontSize - 4 + 'px' }"
          >
            يۈەن</span
          >
          <span v-if="posterList.lang == 'zh'"> 元</span>
        </div>
      </div>

      <div class="old_price">
        <div class="old_price_text" v-if="posterList.lang == 'ug'">
          ئەسلى باھاسى :
        </div>
        <div class="old_price_text" v-if="posterList.lang == 'zh'">原价 :</div>
        <div class="line">
          <span class="number"> {{ posterList.old_price }} </span>
          <span v-if="posterList.lang == 'ug'"> يۈەن </span>
          <span v-if="posterList.lang == 'zh'"> 元 </span>
        </div>
      </div>

      <div class="merchant_qrcode">
        <img :src="posterList.merchant_qrcode" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_32",
  data() {
    return {
      posterList: {},
      url: require("../../img/poster/model_32.jpg"),
    };
  },
  created() {
    this.canvasfortitle(
      this.$route.query.merchant_name,
      this.$route.query.food_name,
      this.$route.query
    );
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      let res_name = merchant_name.replaceAll("(", "-");
      res_name = res_name.replaceAll("（", "-");
      res_name = res_name.replaceAll(")", " ");
      res_name = res_name.replaceAll("）", " ");
      let food_name = food_names.replaceAll("(", "-");
      food_name = food_name.replaceAll("（", "-");
      food_name = food_name.replaceAll(")", " ");
      food_name = food_name.replaceAll("）", " ");
      this.posterList = query;
      this.posterList["merchant_name"] = res_name;
      this.posterList["food_name"] = food_name;
      this.posterList.food_price = parseFloat(query.food_price);
      const price = query.food_price.toString();
      let fontSize = 20;
      if (price.length <= 2) {
        fontSize = 30;
      } else if (price.length == 3) {
        fontSize = 28;
      } else if (price.length == 4) {
        fontSize = 24;
      } else if (price.length == 5) {
        fontSize = 23;
      } else if (price.length == 6) {
        fontSize = 20;
      }
      this.posterList.fontSize = fontSize;
    },
  },
};
</script>

<style scoped>
.model-content {
  width: 333px;
  height: 666px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
}

.model_32 {
  width: 333px;
  height: 666px;
  position: relative;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.img-box {
  width: 100%;
  height: 100%;
}

.merchant-name {
  position: absolute;
  top: 70px;
  right: 50%;
  transform: translateX(50%);
  color: #fffc00;
  width: 260px;
  height: 56px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.merchant-name-title {
  font-size: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.poster-title {
  position: absolute;
  top: 152px;
  right: 50%;
  transform: translateX(50%);
  color: #ee2d2d;
  background-color: #fff;
  border-radius: 8px 8px 0px 0px;
  min-width: 160px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.date {
  position: absolute;
  top: 182px;
  right: 50%;
  transform: translateX(50%);
  color: #403c3c;
  background-color: #fff;
  border-radius: 30px;
  width: max-content;
  padding: 0px 15px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.3);
  direction: ltr;
}

.food_name {
  position: absolute;
  top: 214px;
  right: 50%;
  transform: translateX(50%);
  color: #fff;
  width: 260px;
  height: 56px;
  padding: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: bold;
  text-align: center;
}

.food_name_text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.food_img {
  position: absolute;
  top: 289px;
  left: 9px;
  width: 318px;
  height: 206px;
  border-radius: 50%;
  overflow: hidden;
}

.food_img img {
  width: 100%;
  height: 100%;
  clip-path: ellipse(50% 50% at 50% 50%);
  object-fit: cover;
}

.food-price-box {
  position: absolute;
  top: 424px;
  right: 2px;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
}

.food_price {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 90px;
  height: 90px;
  color: #ee2d2d;
  border: 1px dashed #000;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}
.old_price {
  position: absolute;
  top: 504px;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  left: 20px;
}

.old_price .line {
  text-decoration: line-through;
  padding-inline: 4px;
}

.merchant_qrcode {
  position: absolute;
  top: 577px;
  left: 18px;
  width: 71px;
  height: 71px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;
}

.merchant_qrcode img {
  width: 100%;
  height: 100%;
}
</style>
