
<template>
  <div class="model-content" style="display: flex; justify-content: center;">
    <div class="model3">
     <div>
       <img v-if="posterList.lang == 'ug' || !posterList.lang" style="width: 100%;" :src="moban_list.url_ug" />
       <img v-if="posterList.lang == 'zh'" style="width: 100%;" :src="moban_list.url_zh" />
     </div>
     <div class="step">
      <div class="stepTitle" :class="(posterList.lang == 'ug' || !posterList.lang)?'rtl left':'right'">{{ posterList.title }}</div>
      <div class="step-item" v-if="posterList.step1" :class="(posterList.lang == 'ug' || !posterList.lang)?'left':'right'">
        {{ posterList.step1 }}
      </div>
      <div class="step-item" v-if="posterList.step2"  :class="(posterList.lang == 'ug' || !posterList.lang)?'left':'right'">
        {{ posterList.step2 }}
      </div>
      <div class="step-date-time" v-if="posterList.end && posterList.start"  :class="(posterList.lang == 'ug' || !posterList.lang)?'':'rtl'">
         <div class="step-date-title">
           {{ (posterList.lang == 'ug' || !posterList.lang)?': پائالىيەت ۋاقتى':' : 活动时间' }}
         </div>
         <div class="step-date-item">
           <span> {{ posterList.start }}</span>
           <span>~</span>
           <span>{{ posterList.end }}</span>
         </div>
      </div>
     </div>

     <div class="merchant-name" :class="(posterList.lang == 'ug' || !posterList.lang)?'merchant-name':'merchant-name-zh'">
      <div  :class="(posterList.lang == 'ug' || !posterList.lang)?'rtl merchant-name-title':'merchant-name-title-zh'">
        {{ posterList.merchant_name }}
      </div>
      </div>
      <div class="merchant_img"  :class="(posterList.lang == 'ug' || !posterList.lang)?'merchant_img_ug':'merchant_img_zh'">
        <img :src="posterList.merchant_img" alt="">
      </div>
      <div class="merchant_qrcode"   :class="(posterList.lang == 'ug' || !posterList.lang)?'merchant_qrcode_ug':'merchant_qrcode_zh'">
        <img :src="posterList.merchant_qrcode" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      posterList: {},
      moban_list:{
        url_zh: require('../../img/poster/model3_zh.png'),
        url_ug: require('../../img/poster/model3_ug.png'),
      },
    };
  },
  created() {
    this.canvasfortitle(this.$route.query.merchant_name, this.$route.query)
  },
  methods: {
    canvasfortitle(merchant_name , query) {
      let res_name = merchant_name.replaceAll('(','-')
      res_name = res_name.replaceAll('（','-')
      res_name = res_name.replaceAll(')',' ')
      res_name = res_name.replaceAll('）',' ')
      this.posterList = query;
      this.posterList['merchant_name'] = res_name;
    },
  },
};
</script>

<style  scoped>
.model-content{
  width: 339px;
  height: 596px;
  position: relative;
  margin: auto;
}
.model3{
  width: 339px;
  height: 596px;
  position: relative;
}
.step{
    width: 277px;
    background: #fff;
    padding: 0px 10px 10px;
    position: absolute;
    z-index: 999;
    top: 291.03px;
    right: 21px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.stepTitle{
  color:#000000;
  font-size: 15px;
  text-align: right;
  display: -webkit-box;  /*弹性伸缩盒子*/
  -webkit-box-orient: vertical;/*垂直排列*/
  line-clamp: 6;
  -webkit-line-clamp: 2;  /*只显示6行*/
  overflow: hidden;   /*溢出隐藏*/
  text-overflow: ellipsis;   /*省略号代替*/
  padding: 0 4px;
}
.step-item{
  width: -webkit-fill-available;
  background: rgb(244, 244, 244);
  box-shadow: inset 0px 2px 3px rgb(0 0 0 / 25%);
  border-radius: 5px;
  font-size: 11px;
  padding: 10px;
  margin: 8px auto;
}
.step-date-time{
  width: -webkit-fill-available;
}
.step-date-time{
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row-reverse;
}
.step-date-item{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0 4px;
  color: #F23636;
  font-size: 11px;
  margin-top: 2px;
}
.step-date-title{
  font-size: 11px;
  color: #000000;
  padding: 0 5px;
}
.rtl{
  direction: rtl;
}
.merchant-name{
  position: absolute;
  z-index: 999;
  color: #fff;
  top: 468px;
  width: -webkit-fill-available;
}
.merchant-name-title{
    width: 172px;
    font-size: 15px;
    text-align: center;
    margin-left: 106px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.merchant_img{
  position: absolute;
  width: 36px;
  height: 36px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 7px;
}
.merchant_img img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}
.merchant_qrcode{
  position: absolute;
  width: 80.81px;
  height: 80.72px;
  border-radius: 29px;
}
.merchant_qrcode img{
  width: 100%;
  height: 100%;
  border-radius: 29px;
}
.left{
  text-align: right;
}
.right{
  text-align: left;
}
.merchant_img_zh{
  right: 284px;
  top: 510px;
}
.merchant_img_ug{
  left: 283px;
  top: 488px;
}
.merchant_qrcode_zh{
  right: 24.79px;
  top: 489px;
}

.merchant_qrcode_ug{
  left: 24.79px;
  top: 485.37px;
}
.merchant-name-title-zh {
    width: 150px;
    font-size: 15px;
    text-align: left;
    margin-left: 72px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}
.merchant-name-zh {
    position: absolute;
    z-index: 999;
    color: #fff;
    top: 506px;
    width: -webkit-fill-available;
}
</style>