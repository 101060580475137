<template>
  <div id="app">
    
    <div class="haeder">
      <img src="../img/imageszh/img_zh1.jpg" alt="" />
    </div>
    <div class="body-phone">
      <a style="text-decoration: none" href="tel:4000061699">
        <div class="phone1">☏ 电话咨询</div></a
      >
    </div>
    <div class="body-form">
      <el-form
        label-position="top"
        label-width="80%"
        class="demo-ruleForm"
        :rules="rules"
        :model="rulesForm"
        status-icon
        ref="ruleForm"
      >
        <el-form-item label="姓名" prop="username">
          <el-input
            type="text"
            placeholder="请输入姓名"
            v-model="rulesForm.username"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone">
          <el-input
            maxlength="11"
            minlength="1"
            type="number"
            placeholder="请输入手机号"
            v-model="rulesForm.phone"
          ></el-input>
        </el-form-item>

        <el-form-item label="描述">
          <el-input
            type="textarea"
            placeholder="请输入描述（选填）"
            v-model="rulesForm.content"
          ></el-input>
        </el-form-item>
        <div style="padding-top: 20px">
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  name: "App",
  data() {
    return {
      rulesForm: {
        username: "",
        phone: "",
        content: "",
      },
      rules: {
        username: [{ required: true, message: "请输入姓名", trigger: "blur" }],

        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 1, max: 11, message: "长度1-11", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      let obj = this.rulesForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //如果校检通过，在这里向后端发送用户名和密码
          let data = {
            username: obj.username,
            phone: obj.phone,
            info: obj.content,
          };
          Axios.post(
            `https://smart.mulazim.com/ug/v1/hediye/add-user`,
            data
          ).then((res) => {
            console.log("成功", res);
            if (res.data.status == 200) {
              this.$notify({
                title: "成功",
                message: "报名成功",
                type: "success",
              });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              this.$notify.error({
                title: res.data.msg,
                message: res.data.msg,
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  components: {},
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  padding: 0 !important;
}
.haeder {
  width: 100%;
}
.haeder img {
  width: 100%;
  height: 100%;
}
.body-form {
  margin: 0 30px;
}
.el-textarea__inner {
  height: 100px !important;
  overflow-y: auto;
}
.body {
  margin: 10px 20px;
}
.el-button--primary {
  width: 100% !important;
  background-color: rgba(74, 104, 236, 0.99) !important;
  border-radius: 30px !important;
  border-color: rgba(74, 104, 236, 0.99) !important;
  padding: 16px 20px !important;
}
.body-phone .phone1 {
  font-family: "microsoft yahei";
  padding: 0px;
  color: rgb(242, 242, 242);
  font-size: 0.3rem;
  background: linear-gradient(90deg, rgb(110, 102, 255), rgb(88, 81, 204));
  width: 250px;
  height: 45px;
  margin: 15px auto 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-size: 18px;
  animation: 600ms ease 0ms 1 normal both running fadeInNormal;
}

.el-form-item {
  margin-bottom: 30px !important;
}
.el-form--label-top .el-form-item__label {
  padding: 0 0 1px !important;
  font-size: 19px !important;
}
.el-form-item__error {
  font-size: 15px !important;
}
.el-radio__inner {
  width: 24px !important;
  height: 24px !important;
}
.el-avatar,
.el-cascader-panel,
.el-radio,
.el-radio--medium.is-bordered .el-radio__label,
.el-radio__label {
  font-size: 18px !important;
}
.el-checkbox__inner {
  width: 20px !important;
  height: 20px !important;
}
.el-checkbox__label {
  font-size: 18px !important;
}
.el-checkbox__inner::after {
  height: 11px !important;
  left: 7px !important;
}
.title {
  color: darkorange;
  font-size: 28px;
  padding: 20px 0 10px;
  text-align: center;
}

.neirong {
  color: slateblue;
  font-size: 27px;
  padding: 10px 0 0;
  text-align: center;
}
</style>
