<style scoped>
body {
    background-color: #EFF1F6;
}

.content {
    max-width: 500px;
    width: 100%;
    /* padding: 15px; */
    margin: 15px;
    background-color: #fff;
    border-radius: 15px;
}
.contet_title{
    font-size: 24px;
    padding-top: 15px;
    padding-bottom: 40px;
}
.m-1 {
    margin: 20px;
}

.text-algin {
    text-align: center;
}

.text-bold {
    font-weight: 600;
}
</style>


<template>
    <div style="display: flex;justify-content: center;">
        <div class="content">
            <div class="m-1">
                <div class="text-algin text-bold contet_title">{{ title }}</div>
            </div>
            <div class="m-1">

                <p><b>第一章 概述</b></p>
                <p>第一条【目的和依据】为促进买卖双方基于真实的交易做出公正、客观、真实的评价，进而为其他消费者在购买决策过程中和商户经营过程中提供参考，根据《中华人民共和国电子商务法》以及其他相关法律法规和《美滋来平台用户服务协议》、《美滋来平台用户服务条款》等相关协议、规范以及《隐私政策》的规定，制定本规范。本规范中所提到的平台是指美滋来平台，规范中简称“平台”。
                </p>
                <p>第二条【适用范围】本规范适用于平台所有用户和商户。</p>
                <p>第三条【美滋来平台评价】凡是展示在平台（包括全部网页端与手机端、小程序等）平台界面，可供浏览上述界面的平台用户看到的具有评价功能的全部信息内容均属于本规范，称之平台评价（简称“评价”）。除发生本规范规定的违法违规评价外，平台不会删除合法正常评价。任何用户或商户不得干预平台的信用评价体系。
                </p>
                <p>&nbsp;</p>
                <p><b>第二章 用户评价</b></p>
                <p>第四条【评价资格】为保证评价的及时性，平台用户基于真实有效的交易订单，在交易成功后7×24小时内（7天），用户有权对交易的商户与配送进行评价。</p>
                <p>第五条【评价内容】评价包括“配送评价”和“商户评价”；“配送评价”包括“满意/不满意”和“评价标签”；“商户评价”包括“评分等级”、“文字评论”、“图片评论”、“视频评论”及“商品评价”等。</p>
                <p>第六条【商户评分】商户评分由用户对商户做出，包括对商品/服务的质量、口味、包装、服务态度等方面的评分指标。每项店铺评分均为动态指标，为近30天内所有评分的算术平均值。每个自然月，相同买、卖双方之间交易，商户店铺评分仅计取前3次。店铺评分一旦做出，无法修改。
                </p>
                <p>第七条【追加评论】自交易成功之日起7天（含）内，用户可在做出“配送评价”和“商户评价”后追加评论。</p>
                <p>第八条【主动删除】用户可在评价后，可对信用评价进行删除。删除后视为用户放弃评价权利，无法重新进行评价。</p>
                <p><!--[if--><b>第三章 </b><!--[endif]--><b>基本原则</b></p>
                <p>第九条【禁发评价】用户理解其评价将成为其他用户对该商户、商品或服务的重要判断依据，并承诺提交真实有效的评价，不得发表任何违法违规评价内容。禁止发布的内容，包括但不限于如下：</p>
                <p>1、包含暴力恐怖、犯罪、赌博、毒品等违法相关信息；</p>
                <p>2、包含政治敏感、反政府言论、宣传宗教迷信等相关信息；</p>
                <p>3、非本订单评价或与本订单商家、商品或服务无直接关系的评价；</p>
                <p>4、仅包含纯数字、无规则字母、乱码、标点符号、表情、无意义的灌水等信息；</p>
                <p>5、包含淫秽、色情等低俗或其他有违公序良俗等相关信息的评价；</p>
                <p>6、过激言论，出现人身攻击、恶意诋毁、辱骂、泄露个人信息等相关评价；</p>
                <p>7、侵犯他人知识产权、人格权、肖像权等内容，以及内容重复超过60%以上，无参考意义的评价；</p>
                <p>8、非本平台上的其他商品、商家信息包括但不仅限于店铺、商品链接、联系信息等；</p>
                <p>9、店铺链接、二维码等广告宣传信息内容的；</p>
                <p>10、图片、视频非本商品/服务相关或图片、视频不清晰的；以及未经他人同意，涉及盗用他人文字、图片、视频并编辑后发布的；</p>
                <p>11、以物质或金钱承诺为条件鼓励、引导消费者进行“好评”的营销内容，包括但不限于：好评返现、好评免单、好评返红包、好评返优惠券等违法信息；</p>
                <p>12、其他违反法律法规、平台协议和/或管理规范等的评价。
                </p>
                <p><b>第四章 评价处理</b></p>
                <p>第十条【评价处理原则】为了确保评价体系的公正性、客观性和真实性，平台将基于现有的技术手段，对违规交易评价、恶意评价、不当评价等破坏平台信用评价体系、侵犯消费者知情权、恶意诋毁商户名誉权等的行为予以坚决打击。
                </p>
                <p>第十一条【违法交易评价】平台有权处理违规交易产生的评价，包括但不限于本规范中规定的发布骗取他人财物、虚假交易等违规行为所涉及的订单对应的评价。</p>
                <p>第十二条【恶意评价】如用户、商户同行竞争者等评价人被发现以给予与事实不符的差评、负面评论等方式谋取额外财物或其它不当利益的恶意评价行为，平台有权处理该违规评价。</p>
                <p>第十三条【不当评价】平台有权依法处理交易评价和售后评价内容中所包含违反本规范第九条的内容。</p>
                <p>第十四条【异常评价处理方式】平台对排查到的异常评价有权依法进行处理。</p>
            <p>第十五条【评价人处理】针对发生用户前述违法违规评价行为，除对产生的评价作相应处理外，平台将视情形对用户采取一定的限制评价、限制用户交易行为等处理措施。若用户对处理结果有异议，可联系平台客服咨询。</p>
            <p>第十六条【评价投诉】如商户发现用户评价不实或违法违规的，则商户须在用户做出评价的30天内进行投诉；未在规定时间内投诉的，不予受理。</p>
            <p>第十七条【判定权】平台依据法律法规及用户协议、平台管理规范等，以中立的立场对相关评价之真实性、合法性、正当性、恶意与否进行判定。用户和商户有义务配合向平台提供相关证据资料，并认可平台有权行使该判定权。</p>
            <p>第十八条【法律责任】用户及商户应遵守国家法律、行政法规、部门规章等相关规定。对任何涉嫌违反国家法律、行政法规、部门规章的行为，本规则已有规定的，适用本规则。本规则尚无规定的，平台有权酌情处理。但平台对用户及商户的处理并不免除其应承担的法律责任。用户及商户平台的任何行为，应同时遵守与平台及其关联公司签订的各项协议。
            </p>
            <p>用户或商户因违反法律法规或者本规范之规定，发布违法、侵权、违规评价信息的，应当自行承担法律责任。因此给平台、用户、商户造成损失的，该用户或商户应承担全部赔偿责任，包括且不限于财产损害赔偿、名誉损害赔偿、诉讼费、律师费、公证费、交通费等因维权而产生的合理费用。&nbsp;
            </p>
            <p><b>第五章 其他</b></p>
            <p>第十九条【规范变更】平台有权依法对本规范内容进行修改、补充或更新，并在该规范生效前通过平台客户端、小程序以公告等方式予以征求意见和公示，无需另行单独通知用户，上述公告一经发布即视为通知所有使用美滋来平台服务的用户；若用户在本规范内容修改、补充或更新公告后有意见的，应及时以书面形式反馈意见。如用户未反馈意见并继续使用平台提供的服务，视为用户同意遵守修改、补充或更新的规范。
            </p>
            <p>第二十条【规范效力】本规范作为平台用户服务条款补充，与用户服务条款具有相同法律效力。用户违反本规范的，按照本规范、平台用户服务条款、其他管理规范及隐私政策进行处理。</p>
            <p>第二十一条【规范溯及力】发生在本规范生效之日前的行为，适用当时的规范进行处理；发生在本规范生效之日后的，适用本规范。</p>
            <p>

            </p>
            <p>第二十二条【生效】本规范发布日期为<u>&nbsp;&nbsp;&nbsp;
                </u>年<u>&nbsp; </u>月<u>&nbsp;&nbsp; </u>日，自发布时起公示7日，并在公示期间征求相关意见，期间如无意见及修改等特殊情形，将于7日后生效，如有修改，将另行公示。</p>




        </div>

    </div>
</div></template>

<script>


export default {

    data() {
        return {
            title: '',

        }
    },
    created() {
        this.title = this.$route.query.data
    },
    methods: {

    }






}

</script>