<template>
  <div id="app">
    <div class="body-content">
      <div class="footers">
        <div class="day">
          <img src="../img/day.png" alt="">
        </div>
        <div class="preferential">
          <img src="../img/preferential.png" alt="">
        </div>
      </div>
      <div class="titles">
        سېتىۋالغان زاكاز سوممىڭىز<span style="color:#FF3939;padding: 0 3px;">100</span>يۈئەنگە توشسا باس باس
        كىنوخانىسىنىڭ<span style="color:#FF3939;padding: 0 3px;">7</span>كۈنلۈك ئالىي ئەزالىقى سوۋغا قىلىنىدۇ
      </div>
      <div class="card-btn">
        <div class="imgs">
          <img src="../img/mulazim.png" alt="">
        </div>
        <div class="btn-card">
          <div class="btn-item">سۈرئەت</div>
          <div class="btn-item">سۈپەت</div>
          <div class="btn-item-da">ئەلا مۇلازىمەت</div>
        </div>
      </div>
      <div class="order-btn">تاماق بۇيرۇتاي</div>
      <div class="footers-title">مۇلازىم تاماق بۇيرۇتۇش سۇپىسى</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      title: ''
    };
  },
  methods: {

  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.body-content {
  position: relative;
  width: 100%;
  height: 100vh;
  background: rgb(241, 242, 245);
}

.footers {
  width: 100%;
  height: 50vh;
  background: linear-gradient(180.00deg, rgba(44, 178, 66, 1.00) 0%, rgba(44, 178, 66, 0.00) 100%);
}

.titles {
  color: #5DB33B;
  width: 90%;
  margin: auto;
  height: 7vh;
  margin-top: 6vh;
  text-align: justify;
  text-align-last: end;
}

.day {
  width: 60%;
  height: 6vh;
  padding-left: 17px;
  padding-top: 4vh;
}

.day img {
  width: 100%;
  height: 100%;
}

.preferential {
  width: 90%;
  height: 46vh;
  margin: -20px auto 0;
}

.preferential img {
  width: 100%;
  height: 100%;
}

.card-btn {
  height: 22vh;
  width: 79%;
  margin-left: 18%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: -2vh;
}

.btn-card {
  width: 140px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.card-btn .imgs {
  width: 157px;
  height: 133.63px;
}

.card-btn .imgs img {
  width: 100%;
  height: 100%;
}

.btn-item {
  width: 66.27px;
  height: 28.64px;
  background: rgb(44, 178, 66);
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.btn-item-da {
  width: 140px;
  height: 28.64px;
  background: rgb(44, 178, 66);
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-btn {
  width: 76%;
  height: 7vh;
  background: rgb(44, 178, 66);
  border-radius: 27.5px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  margin-top: -1vh;
}

.footers-title {
  width: 100%;
  color: rgb(128, 128, 128);
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  height: 6vh;
  line-height: 6vh;
  margin-top: 2vh;
}
</style>
