<style scoped>
body {
    background-color: #EFF1F6;
}
b{font-size: 20px; margin: 10px; line-height: 45px; }
.content {
    max-width: 500px;
    width: 100%;
    /* padding: 15px; */
    margin: 15px;
    background-color: #fff;
    border-radius: 15px;
}
.contet_title{
    font-size: 24px;
}

.m-1 {
    margin: 20px;
}

.text-algin {
    text-align: center;
}
.contet_title{
    font-size: 24px;
    padding-top: 15px;
    padding-bottom: 40px;
}
.text-bold {
    font-weight: 600;
}
</style>


<template>
    <div style="display: flex;justify-content: center;">
        <div class="content">
            <div class="m-1">
                <div class="text-algin text-bold contet_title">{{ title }}</div>
            </div>
            <div class="m-1">

                <p style="font-weight: 600;">总则</p>
                <p>第一条<b>【制定目的及依据】</b>为保障入网餐饮商户食品安全，践行“帮大家吃得更 好，生活更好” 的企业使命，依法履行平台食品安全管理责任，持续提升入网餐
                    饮商户在线经营规范化水平，依据《食品安全法》、《中共中央国务院关于深化 改革加强食品安全工作的意见》、《网络餐饮服务食品安全监督管理办法》、《网
                    络食品安全违法行为查处办法》等，结合业务实际，制定《美滋来网络订餐食品安 全管理办法》。</p>
                <p>第二条<b>【基本原则和目标】</b>美滋来网络订餐食品安全管理工作坚持依法履责、智慧 管理、合作共治的基本原则，充分发挥平台大数据技术优势，推进与社会各界的
                    广泛合作，建立并持续完善美滋来网络订餐食品安全智慧管理体系。通过做好食品 安全管理工作，实现商户规范、用户满意、监管认可、行业示范的关键结果，持 续提升网络订餐行业“安全”“安心” 的高质量发展水平。</p>
                <p>第三条<b>【适用范围】</b>本办法的适用范围是：以美滋来平台（以下简称“平台” ）为载 体开展的网络订餐服务，具体指线下餐饮商户通过入驻美滋来旗下各类平台，接受
                    注册为平台用户的消费者订餐需求后制作餐饮，并完成餐品配送的网络订餐服务 活动。</p>
                <p><b>第一章&nbsp;&nbsp; 入网餐饮商户的食品安全要求</b></p>
                <p>第四条<b>【资质要求】</b>入网餐饮商户应当具有实体店经营资格，依法取得符合国家、 地方法律法规和监管要求的有效资质证件，包括但不限于营业执照、食品经营许 可证、登记备案凭证、核准证等合法经营资格。</p>
                <p>第五条<b>【资质公示与更新】</b>入网餐饮商户应当做好相关资质证照上传、公示工作， 并保证上传资质证照真实、合法、有效，资质证件信息发生变化的，应当及时更 新。入网餐饮商户还应依法公示量化分级信息。</p>
                <p>第六条<b>【食品安全协议】</b>入网餐饮商户应当与平台签订食品安全协议，按照法律 法规规章和协议载明的要求，依法开展网络餐饮服务活动，确保向消费者提供符 合食品安全要求的餐品。</p>
                <p>第七条 <b>【合规经营】</b>入网餐饮商户应当严格按照许可证、登记备案凭证、核准 证等资质证件载明的事项从事网络餐饮服务活动，不得超范围、超期限经营。</p>
                <p>第八条<b>【过程规范】</b>入网餐饮商户应当做好原材料采购和餐品的食品安全控制， 鼓励餐饮商户建立稳定的供货渠道。鼓励商户使用美滋来或其他供应商提供的餐饮 ERP&nbsp;
                    系统，对“从农田到餐桌”全过程进行信息化管理，推动建立“餐饮安全追溯&nbsp; 机制”。</p>
                <p>入网商户应积极学习和践行《餐饮服务食品安全操作规范》规定的各项操作指引 和规定，规范操作和经营，履行食品安全主体责任，提升食品安全管理能力，保 证餐饮食品安全。不得加工法律、法规明令禁止生产经营的食品。</p>
                <p>第九条<b>【明厨亮灶】</b>鼓励餐饮商户在平台经营活动页面向用户展示店面和厨房环 境。对于有效展示真实信息的商户，平台将通过加注标签等方式，给予商户更多 的发展支持。</p>
                <p>第十条<b>【食品安全保险】</b>商户应积极处理消费者食品安全投诉，提升消费者服务 体验，保障消费者消费权益。鼓励餐饮商户积极加入美滋来网络订餐“放心吃”计划，
                    通过购买食品安全责任保险，为消费者提供更好的消费保障。</p>
                <p>第十一条<b>【食品安全知识学习】</b>鼓励商户积极参加平台组织的线上、线下食品安 全法律法规、操作规范等学习培训活动，不断提升食品安全意识和操作水平。</p>
                <p>第十二条<b>【网络订餐封签】</b>入网餐饮商户应当使用无毒、清洁的食品容器、餐具 和包装材料，并对餐饮食品进行包装。鼓励通过加贴封签或者采取其他适宜的方 式，提升配送过程的规范化水平。</p>
                <p><b>第二章 平台的食品安全管理建设</b></p>
                <p>第十三条<b>【完善保障机制】</b>平台设立首席食品安全官，成立食品安全专门机构， 组建并优化食品安全管理工作组织体系，确保食品安全管理工作保障有力、推进 有序，不断推进平台食品安全管理能力建设。</p>
                <p>第十四条<b>【食品安全制度建设】</b>平台严格按照法律法规等要求，建立健全食品安 全管理各项制度、规则并定期更新，确保制度、规则落实落地。平台发现入网餐
                    饮商户存在食品安全违法行为或潜在风险时，有权依据相关制度规则等，进行必 要处置。</p>
                <p>第十五条<b>【食品安全文化建设】</b>平台积极加强平台食品安全文化建设，通过教育 培训、评奖评优等多种手段，培育美滋来生态的食品安全文化，提升全平台的食品 安全意识和能力。</p>
                <p>第十六条<b>【入网审查与实名登记】</b>平台建立并持续完善餐饮商户入网审查制度， 对入网餐饮商户进行实名登记，利用人工+ 电子系统双重校验，并努力探索通过
                    天网系统与监管部门证照数据库建立比对验真等方式，不断提升入网餐饮商户资 质证件审查的水平和效率。</p>
                <p>第十七条<b>【在网管理】</b>平台建立并持续完善餐饮商户在网管理机制，借鉴监管部 门推行的“双随机、一公开”机制、“神秘买家”机制等，实现对入网餐饮商户网络
                    经营行为的动态抽查和监测。研发智慧管理系统，加强对资质证件临期换证提醒， 超期限超范围经营、证照造假仿冒等违规行为的定期重点监测、核查和处置。</p>
                <p>第十八条<b>【违法行为制止及报告】</b>平台建立入网餐饮商户违法行为制止及报告制 度，发现入网餐饮商户存在违法行为的，及时制止并向有关部门报告。</p>
                <p>第十九条<b>【严重违法行为停止平台服务】</b>平台建立严重违法行为停止平台服务制 度。如商户出现：涉嫌食品安全犯罪被立案侦查或提起公诉，或被处以刑罚；因
                    食品安全违法行为被公安机关拘留或给予其他治安处罚，或被食品安全监管部门 吊销许可证或责令停产停业等严重违法犯罪行为等严重违法行为的，平台将立即 将商户下线，停止为其提供平台服务。</p>
                <p>第二十条<b>【事故处置】</b>平台建立食品安全事故处置制度，入网餐饮商户出现食品 安全事故的，及时启动事故流程机制，确保问题及时处置。</p>
                <p>第二十一条<b>【投诉处理】</b>平台建立食品安全投诉处理专门机制，核实处理消费者 因接受网络订餐服务所产生的投诉举报等相关问题。</p>
                <p>第二十二条<b>【商户普法】</b>平台利用“袋鼠学院”等线上、线下渠道，研发面向餐饮 商户的食安培训体系，普及食品安全法律法规、操作规范等知识，持续帮助商户 提升食品安全意识和规范经营水平。</p>
                <p>第二十三条<b>【智慧管理】</b>平台利用大数据、云计算等能力，不断优化天网、天眼 系统等智慧管理方案，推进网络餐饮服务食品安全的智慧管理体系建设。</p>
                <p>第二十四条<b>【社会共治】</b>平台积极参与食品安全社会共治，与监管部门、行业协 会、科研机构、学术团体等力量共同探索和实践推进餐饮行业高质量发展的方案，
                    致力于促进解决行业发展中的问题。如通过与政府食品经营许可数据库的对接， 提升入网餐饮商户资质审查的准确性；配合监管部门，帮助潜在优质商户共同解
                    决证照办理难题；帮助商户优化原材料采购供应链；联合第三方机构推广餐饮行 业食品安全解决方案等。</p>
                <p><b>第三章 配送规范与安全</b></p>
                <p>第二十五条<b>【配送人员】</b>送餐人员应保持个人卫生并满足所在地对于送餐人员的 卫生健康的相关要求，上岗时确保工作衣帽整洁。</p>
                <p>第二十六条<b>【骑手培训】</b>加强送餐人员送餐规范、食品安全等方面的教育培训， 提升送餐人员的食品安全意识。</p>
                <p>第二十七条<b>【容器清洁】</b>送餐时，应使用安全无毒的配送容器，保持容器清洁， 并定期清洗消毒。</p>
                <p>第二十八条<b>【配送规范】</b>配送过程中，不得将食品与有毒有害物品混装存放、配 送，同时保证餐品安全所需的温度、湿度等要求。</p>
                <p><b>第四章 消费者权益保障</b></p>
                <p>第二十九条<b>【食安科普】</b>加强面向消费者的食品安全知识普及，利用 APP 、微信 公众号、印发科普图册等形式，对消费者进行餐饮食品安全提示、知识普及。如 夏天应少选冷食生食，餐品送到应及时食用等。
                </p>
                <p>第三十条<b>【投诉举报】</b>消费者接受美滋来网络订餐服务，发现入网餐饮商户提供的 餐品存在食品安全问题的，可通过 APP 在线客服、消费者服务热线等进行投诉&nbsp;
                    举报。平台接到消费者投诉举报的，应当立即开展调查核实和处理工作，依法维 护消费者的合法权益。</p>
                <p><b>附则</b></p>
                <p>


            </p>
            <p>第三十一条<b>【生效时间】</b>本办法自 2019 年 11 月 21&nbsp; 日起生效实施，由美滋来负责 解释。 自本办法生效之日起，原《美滋来餐饮安全管理办法》 自行废止。</p>
        </div>

    </div>
</div></template>

<script>


export default {

    data() {
        return {
            title: '',

        }
    },
    created() {
        this.title = this.$route.query.data
    },
    methods: {

    }






}

</script>