<template>
  <div class="bg-poster">
    <div class="card-poster" style="width: 100%; text-align: center;   max-width: 414px;">
      <img v-if="posterList.lang == 'ug' || !posterList.lang" class="poster" mode="a" :src="moban_list.url_ug">
      <img v-if="posterList.lang == 'zh'" class="poster" mode="a" :src="moban_list.url_zh">
      <div class="axhana-title">
        <div>
          <img mode="aspectFill" style="width: 90px;height: 90px; border-radius: 8px;" :src="posterList.merchant_img" />
        </div>
        <div class="title1">{{ posterList.merchant_name }}</div>
      </div>
      <img class="cur flex" style="width: 160px;height: 160px; top: 385px;" mode="scaleToFill"
        :src="posterList.merchant_qrcode" />
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      moban_list:
      {
        url_zh: require('../../img/poster/model1_zh.png'),
        url_ug: require('../../img/poster/model1_ug.png'),

      },

      posterList: null,
    };
  },
  created() {
    this.canvasfortitle(this.$route.query.merchant_name, this.$route.query)
  },
  methods: {
    canvasfortitle(merchant_name, query) {
      let res_name = merchant_name.replaceAll('(', '-')
      res_name = res_name.replaceAll('（', '-')
      res_name = res_name.replaceAll(')', ' ')
      res_name = res_name.replaceAll('）', ' ')
      this.posterList = query;
      this.posterList['merchant_name'] = res_name;
    },
  },
};
</script>
  
<style scoped>
.bg-poster {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  /*默认为 row，这时要设置 width: 100%*/
  height: 100%;
}

.poster {
  height: 100%;
  width: 100%;
}


.card-poster {
  width: 100%;
  text-align: center;
  max-width: 414px;
  display: flex;
  justify-content: center;
}

.title1 {
  margin-top: 10px;
  color: #333333;
  font-size: 20px !important;
  max-width: 328px;
  text-align: center;
}

.flex {
  display: flex;
}

.axhana-title {
  position: absolute;
  z-index: 999;
  justify-content: center;
  top: 110px;
  width: 100%;
  display: grid;


}

.bac-poster {
  padding: 50px;
  text-align: center;
  background: #fff;
  justify-content: center;
}

.cur {
  position: absolute;
}
</style>
  