<template>
    <div class="model-content">
        <div class="model_57" :class="posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
            ">
            <div class="img-box">
                <img style="width: 100%" :src="url" />
            </div>

            <div class="merchant-name">
                <div class="merchant-name-title">{{ posterList.merchant_name }}</div>
            </div>

            <div class="tag-box">
                <div class="tag-text" v-if="posterList.lang == 'ug' || !posterList.lang">
                    تاماقنى مۇلازىمدىن بۇيرۇتسىڭىز تېخىمۇ قولايلىق
                </div>
                <div class="tag-text" v-else>用美滋来平台订餐更快，更方便！</div>
            </div>

            <div class="food_name">
                <div class="food_name_text">{{ posterList.food_name }}</div>
            </div>

            <div class="food_img">
                <img :src="posterList.food_img" alt="" />
            </div>

            <div class="food_price" :style="{ fontSize: posterList.fontSize + 'px' }">
                <span :style="{ fontSize: posterList.fontSize - 10 + 'px' }">￥</span>
                <span>{{ posterList.food_price }}</span>
            </div>

            <div class="old_price">
                <div class="old_price_text" v-if="posterList.lang == 'ug'">
                    ئەسلى باھاسى:
                </div>
                <div class="old_price_text" v-if="posterList.lang == 'zh'">原价 :</div>
                <div class="line">
                    <span class="number"> {{ parseFloat(posterList.old_price) }} </span>
                    <span v-if="posterList.lang == 'ug'"> يۈەن </span>
                    <span v-if="posterList.lang == 'zh'"> 元 </span>
                </div>
            </div>

            <div class="merchant_qrcode">
                <img :src="posterList.merchant_qrcode" alt="" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "model_57",
    data() {
        return {
            posterList: {},
            url: require("../../img/poster/model_57.jpg"),
        };
    },
    created() {
        this.canvasfortitle(
            this.$route.query.merchant_name,
            this.$route.query.food_name,
            this.$route.query
        );
    },
    methods: {
        canvasfortitle(merchant_name, food_names, query) {
            let res_name = merchant_name.replaceAll("(", "-");
            res_name = res_name.replaceAll("（", "-");
            res_name = res_name.replaceAll(")", " ");
            res_name = res_name.replaceAll("）", " ");
            let food_name = food_names.replaceAll("(", "-");
            food_name = food_name.replaceAll("（", "-");
            food_name = food_name.replaceAll(")", " ");
            food_name = food_name.replaceAll("）", " ");
            this.posterList = query;
            this.posterList["merchant_name"] = res_name;
            this.posterList["food_name"] = food_name;
            this.posterList.food_price = parseFloat(query.food_price);
            const price = query.food_price.toString();
            let fontSize = 20;
            if (price.length <= 2) {
                fontSize = 30;
            } else if (price.length == 3) {
                fontSize = 28;
            } else if (price.length == 4) {
                fontSize = 24;
            } else if (price.length == 5) {
                fontSize = 23;
            } else if (price.length == 6) {
                fontSize = 20;
            }
            this.posterList.fontSize = fontSize;
        },
    },
};
</script>

<style scoped>
.model-content {
    width: 351px;
    height: 667px;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
}

.model_57 {
    width: 351px;
    height: 667 px;
    position: relative;
}

.model_ug {
    direction: rtl;
}

.model_zh {
    direction: ltr;
}

.img-box {
    width: 100%;
    height: 100%;
}

.merchant-name {
    position: absolute;
    top: 106px;
    right: 50%;
    transform: translateX(50%);
    color: #b51818;
    width: 300px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.merchant-name-title {
    font-size: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*垂直排列*/
    line-clamp: 1;
    -webkit-line-clamp: 1;
    /*只显示6行*/
    overflow: hidden;
    /*溢出隐藏*/
    text-overflow: ellipsis;
    /*省略号代替*/
}

.tag-box {
    padding: 4px 14px;
    background-color: #fcff00;
    border-radius: 30px;
    position: absolute;
    top: 155px;
    font-size: 12px;
    right: 50%;
    transform: translateX(50%);
    white-space: nowrap;
}

.food_name {
    position: absolute;
    width: 260px;
    height: 56px;
    top: 200px;
    right: 50%;
    transform: translateX(40%);
    font-size: 18px;
    color: #3b3939;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: bold;
    text-align: center;
}

.food_name_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*垂直排列*/
    line-clamp: 2;
    -webkit-line-clamp: 2;
    /*只显示6行*/
    overflow: hidden;
    /*溢出隐藏*/
    text-overflow: ellipsis;
    /*省略号代替*/
}

.food_img {
    position: absolute;
    top: 258px;
    left: 17px;
    width: 243px;
    height: 223px;
    border-radius: 50%;
    overflow: hidden;
}

.food_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.food_price {
    position: absolute;
    top: 440px;
    left: 234px;
    color: #fff;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.old_price {
    position: absolute;
    top: 492px;
    left: 45px;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.old_price .line {
    text-decoration: line-through;
    padding-inline: 4px;
}

.merchant_qrcode {
    position: absolute;
    top: 558px;
    left: 36px;
    width: 88px;
    height: 88px;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
}

.merchant_qrcode img {
    width: 100%;
    height: 100%;
}
</style>