<template>
  <div class="model-content">
    <div
      class="model_45"
      :class="
        posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
      "
    >
      <div class="img-box">
        <img
          v-if="posterList.lang == 'ug' || !posterList.lang"
          style="width: 100%"
          :src="urlUg"
        />
        <img v-if="posterList.lang == 'zh'" style="width: 100%" :src="urlZh" />
      </div>

      <div class="merchant_qrcode" v-html="posterList.qrcode"></div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcode-svg";
export default {
  name: "model_45",
  data() {
    return {
      posterList: {},
      urlUg: require("../../img/poster/model_45_ug.jpg"),
      urlZh: require("../../img/poster/model_45_zh.jpg"),
    };
  },
  created() {
    this.posterList = this.$route.query;
    const svg = new QRCode({
      content: this.$route.query.shipper_qrcode,
      padding: 2,
      width: 72,
      height: 72,
      color: "#000000",
      background: "#ffffff",
      ecl: "M",
    }).svg();
    this.posterList.qrcode = svg;
  },
};
</script>

<style scoped>
.model-content {
  width: 333.33px;
  height: 656.31px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
}

.model_45 {
  width: 333.33px;
  height: 656.31px;
  position: relative;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.img-box {
  width: 100%;
  height: 100%;
}

.merchant_qrcode {
  position: absolute;
  bottom: 71px;
  left: 41px;
  width: 72px;
  height: 72px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  transform: rotate(38deg);
}

.merchant_qrcode img {
  width: 100%;
  height: 100%;
}
</style>
