<template>
  <div class="model-content" style="display: flex; justify-content: center">
    <div class="model3">
      <div>
        <img style="width: 100%" :src="url_img" />
      </div>
      <div class="merchant_name">
        <div
          class="merchant_name_font"
          :class="posterList.lang == 'ug' || !posterList.lang ? 'name_ltr' : ''"
        >
          {{ posterList.merchant_name }}
        </div>
      </div>
      <div class="date">
        <span>{{ posterList.date }}</span>
      </div>
      <div class="food_name">
        <div
          class="food_name_font"
          :class="posterList.lang == 'ug' || !posterList.lang ? 'name_ltr' : ''"
        >
          {{ posterList.food_name }}
        </div>
      </div>
      <div class="food_img">
        <img :src="posterList.food_img" alt="" />
      </div>

      <div class="food-price-box">
        <div class="food_price">
          <span class="food_price_num">{{ posterList.food_price }}</span>
          <span class="food_price_icon">￥</span>
        </div>
      </div>

      <div class="old_price">
        <div v-if="posterList.lang == 'ug'">:ئەسلى باھاسى</div>
        <div v-if="posterList.lang == 'zh'">:原价</div>
        <div class="line">{{ posterList.old_price }}</div>
      </div>
      <div class="merchant_qrcode">
        <img :src="posterList.merchant_qrcode" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      posterList: {},
      url_img: require("../../img/poster/model_img_35.jpg"),
    };
  },
  created() {
    this.$route.query.food_price =
      Math.round((this.$route.query.food_price || 0) * 100) / 100;
    this.$route.query.old_price =
      Math.round((this.$route.query.old_price || 0) * 100) / 100;
    this.canvasfortitle(
      this.$route.query.merchant_name,
      this.$route.query.food_name,
      this.$route.query
    );
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      let res_name = merchant_name.replaceAll("(", "-");
      res_name = res_name.replaceAll("（", "-");
      res_name = res_name.replaceAll(")", " ");
      res_name = res_name.replaceAll("）", " ");
      let food_name = food_names.replaceAll("(", "-");
      food_name = food_name.replaceAll("（", "-");
      food_name = food_name.replaceAll(")", " ");
      food_name = food_name.replaceAll("）", " ");
      this.posterList = query;
      this.posterList["merchant_name"] = res_name;
      this.posterList["food_name"] = food_name;
    },
  },
};
</script>

<style scoped>
.model-content {
  width: 353px;
  height: 758px;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.model3 {
  width: 353px;
  height: 758px;
  position: relative;
  overflow: hidden;
}

.merchant_qrcode {
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #fff;
  left: 30px;
  bottom: 28px;
}

.merchant_qrcode img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.food-price-box {
  position: absolute;
  top: 504px;
  right: 6px;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
}

.food_price {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 88px;
  height: 88px;
  color: #696969;
  border: 2px dashed #4caf50;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}
.food_price_num {
  font-size: 30px;
  margin-top: 6px;
}
.food_price_icon {
  font-size: 17px;
  margin-top: -5px;
}

.food_img {
  position: absolute;
  width: 318px;
  height: 280px;
  top: 318px;
  left: 17px;
}

.food_img img {
  width: 318px;
  height: 280px;
  border-radius: 140px;
}

.old_price {
  position: absolute;
  font-size: 18px;
  color: #dddddd;
  left: 8px;
  bottom: 128px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.old_price .line {
  padding: 0 6px;
  text-decoration: line-through;
  font-weight: bold;
}

.date {
  position: absolute;
  width: 353px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  color: #f31919;
  font-size: 18px;
  top: 168px;
}
.date span {
  background-color: #fff;
  border-radius: 6px;
  padding: 4px 12px;
}

.food_name {
  position: absolute;
  color: #d3d3d3;
  font-weight: 600;
  width: 353px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0px 12px;
  font-size: 20px;
  box-sizing: border-box;
  top: 255px;
}

.food_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
  text-align: center;
  line-height: 24px;
}

.merchant_name {
  position: absolute;
  width: 280px;
  height: 54px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  font-size: 19px;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  background-image: linear-gradient(to top, #ffeb3b, #ff9800);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  right: 36.5px;
  top: 100px;
}

.merchant_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
  text-align: center;
  line-height: 25px;
}
.name_ltr {
  direction: rtl;
}
</style>
