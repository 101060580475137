<template>
  <div class="model-content">
    <div
      class="model_66"
      :class="
        posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
      "
    >
      <img
        class="img-box"
        :src="posterList.lang == 'ug' || !posterList.lang ? url_ug : url_zh"
      />

      <div class="user-price">
        <span>￥{{ parseInt(posterList.amount) / 100 || 0 }}</span>
      </div>

      <div class="user-info">
        <div class="user-avatar">
          <div class="user-image">
            <img :src="posterList.avatar" alt="" />
          </div>
          <div class="user-name">
            <div class="user-name-text">{{ posterList.name }}</div>
            <div class="user-name-addr">{{ posterList.area }}</div>
          </div>
        </div>
        <div class="user-tel">{{ posterList.mobile }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_66",
  data() {
    return {
      posterList: {},
      url_ug: require("../../img/poster/model_ug_67.png"),
      url_zh: require("../../img/poster/model_zh_67.png"),
    };
  },
  created() {
    this.posterList = this.$route.query;
  },
};
</script>

<style scoped>
.model-content {
  width: 391px;
  height: 778px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
}

.model_66 {
  width: 391px;
  height: 778px;
  position: relative;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.img-box {
  width: 100%;
  height: 100%;
}

.user-info {
  position: absolute;
  width: 320px;
  height: 38px;
  top: 580px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  color: #fff;
  font-size: 14px;
}

.user-info .user-avatar {
  display: flex;
}

.user-info .user-name {
  padding: 0px 8px;
}

.user-info .user-avatar .user-image {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
}

.user-info .user-avatar .user-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-info .user-tel {
  direction: ltr;
}

.user-name .user-name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}

.user-name .user-name-addr {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}

.user-price {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 320px;
  height: 38px;
  top: 510px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 34px;
  color: #fff;
}
</style>
