<template>
  <div class="bodyClass">
    <div
      :class="{ bodyRtl: lang == 'ug' }"
      v-if="lang == 'ug'"
    >
      <p style="text-align: center">
        <span style="font-family: UKIJEkran; font-size: 15pt"
          >«جۇڭچيۇ بايرىمىلىق مۇكاپاتلىق چەك تارتىش
          پائالىيىتى»نىڭ&nbsp;پائالىيەت قائىدىسى</span
        >
      </p>
      <p style="text-align: center"></p>
      <p style="line-height: 150%; text-align: center">
        <span style="font-family: UKIJEkran; font-size: 15pt">&nbsp;</span>
      </p>
      <p style="line-height: 150%">
        <span style="font-family: UKIJEkran; font-size: 18px"
          ><strong>1. پائالىيەت چۈشەندۈرۈلۈشى</strong></span
        >
      </p>
      <p style="line-height: 150%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >كەڭ خېرىدارلارغا&nbsp;مىننەتدارلىق بىلدۈرۈش&nbsp;يۈزىسىدىن</span
        >
        «تەلەي چاقپەلىكى ئايلاندۇرۇش» مۇكاپاتلىق چەك تارتىش
        پائالىيىتى&nbsp;ئورۇنلاشتۇرۇلدى. بۇ قېتىملىق پائالىيىتىمىزگە قاتناشقان
        ھەربىرەيلەننىڭ كاتتا مۇكاپاتلارغا ئېرىشىش پۇرسىتى بار.
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >&nbsp;</span
        >
      </p>
      <p style="line-height: 200%">
        <span
          style="font-family: UKIJEkran; color: rgb(0, 0, 0); font-size: 18px"
          ><strong>2. قاتنىشىش ئۇسۇلى</strong></span
        >
      </p>
      <ol class="tight" data-tight="true">
        <li>
          <p style="line-height: 200%">
            <span
              style="
                font-family: UKIJEkran;
                color: rgb(0, 0, 0);
                font-size: 18px;
              "
              ><strong> </strong></span
            ><strong>ئېتىبار بىلەت سېتىۋېلىپ قاتنىشىش: </strong>9.9 يۈەن خەجلەپ
            ئومۇمىي قىممىتى 30 يۈەنلىك 8 دانە مۇلازىم سۇپىسى ئېتىبار بېلىتى
            سېتىۋالسىڭىز، بىر قېتىم چەك تارتىش پۇرسىتىگە ئېرىشەلەيسىز. سېتىۋېلىش
            قېتىم سانىغا چەك قويۇلمايدۇ.
          </p>
        </li>
        <li>
          <p style="line-height: 200%">
            <strong>زاكاز چۈشۈرۈپ قاتنىشىش:</strong> مۇلازىم سۇپىسىدىن بىر
            قېتىمدا ئومۇمىي سوممىسى 60 يۈەندىن يۇقىرى زاكاز چۈشۈرسىڭىز، بىر
            قېتىم چەك تارتىش پۇرسىتىگە ئېرىشەلەيسىز.
          </p>
        </li>
        <li>
          <p style="line-height: 200%">
            <strong>ھەمبەھىرلەپ قاتنىشىش:</strong> پائالىيەت بېتىنى دوسلىرىڭىزغا
            ھەمبەھىرلەپ بەرگەندىن كېيىن، دوستىڭىز شۇ ئۇلانمىدىن كىرىپ يۇقارقى
            ئىككى خىل ئۇسۇلدا مەزكۇر پائالىيىتىمىزگە قاتناشسا، بىر قېتىملىق چەك
            تارتىش پۇرسىتىگە ئېرىشەلەيسىز. بىر دوستىڭىز سىزنى يۇقارقى ئىككى خىل
            ئۇسۇلنىڭ ھەربىرىدە بىر قېتىملا پۇرسەتكە ئېرىشتۈرەلەيدۇ.<span
              style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
              >&nbsp;</span
            >
          </p>
        </li>
      </ol>
      <p style="line-height: 200%">
        <span
          style="font-family: UKIJEkran; color: rgb(0, 0, 0); font-size: 18px"
          ><strong>3. </strong></span
        ><span style="font-family: UKIJEkran; font-size: 18px"
          ><strong>پائالىيەت </strong></span
        ><span
          style="font-family: UKIJEkran; color: rgb(0, 0, 0); font-size: 18px"
          ><strong>ئورنى</strong></span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >مۇلازىم ئەپچىقى، مۇلازىم ئالما، ئاندروئىد ئەپلىرى.</span
        >
      </p>
      <p style="line-height: 200%"></p>
      <p style="line-height: 200%">
        <span
          style="font-family: UKIJEkran; color: rgb(0, 0, 0); font-size: 18px"
          ><strong>4. پائالىيەت ۋاقتى ۋە مۇكاپاتلاش ئۇسۇلى</strong></span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          ><strong>پائالىيەت ۋاقتى:</strong> 2024-يىلى 9-ئاينىڭ 21-كۈنى سائەت 0
          دىن 2024-يىلى 9-ئاينىڭ 25-كۈنى سائەت 23:59 گىچە.</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          ><strong>مۇكاپاتلاش ئۇسۇلى: </strong>قاتناشقۇچى تەلەي چاقپەلىكىنى
          ئايلاندۇرۇپ چەك تارتىشقا قاتناشقاندىن كېيىن، چاقپەلەك توختاش بىلەن
          بىرگە مۇكاپات نەتىجىسى ئاشكارلىنىدۇ. ھەربىر قاتناشقۇچىنىڭ ھەر
          قېتىمدىكى مۇكاپات چىقىش ئېھتىماللىقى ئايرىم ھېسابلىنىدۇ، باشقا چەك
          تارتىش پۇرسەتلىرىدىكى مۇكاپات چىقىش ئېھتىماللىقى بىلەن
          مۇناسىۋەتسىز.</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >&nbsp;</span
        >
      </p>
      <p style="line-height: 200%">
        <span
          style="font-family: UKIJEkran; color: rgb(0, 0, 0); font-size: 18px"
          ><strong>5. چەك تارتىش پۇرسىتىنى ئىشلىتىش</strong></span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >قاتناشقۇچى چەك تارتىش پۇرسىتىنى (مەيلى قايسى خىل ئۇسۇلدا ئېرىشكەن
          بولسۇن) چوقۇم پائالىيەت ئاخىرلىشىشتىن (2024-يىلى 9-ئاينىڭ 25-كۈنى
          سائەت 23:59) بۇرۇن ئىشلىتىۋېتىشى شەرت. ئەگەر پائالىيەت ئاخىرلىشىشتىن
          بۇرۇن چەك تارتىش پۇرسىتىنى ئىشلىتىپ بولالمىغان ياكى ئىشلەتمىگەن بولسا،
          بىردەك چەك تارتىشتىن ۋاز كەچكەن دەپ قارىلىپ، مۇلازىم سۇپىسى بۇ
          پۇرسەتلەرنى پۇلغا سۇندۇرۇپ ھېساپلىمايدۇ ھەم ھېچقانداق ھەق قايتۇرمايدۇ.
          قاتناشقۇچىلارنىڭ ۋاقىتنى ياخشى ئورۇنلاشتۇرۇپ، پائالىيەت ئاخىرلىشىشتىن
          بۇرۇن بارلىق پۇرسەتلىرىنى ئىشلىتىۋېتىشىنى سورايمىز.</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >&nbsp;</span
        >
      </p>
      <p style="line-height: 200%">
        <span
          style="font-family: UKIJEkran; color: rgb(0, 0, 0); font-size: 18px"
          ><strong>6. ئېتىبار بېلىتىنى ئىشلىتىش ئۇسۇلى</strong></span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >1) 8 دانە ئېتىبار بىلىتىنىڭ تەپسىلاتى تۆۋەندىكىچە:</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >4 دانە ئۈچ يۈەنلىك ئېتىبار بىلىتى، 25 يۈەنلىك زاكازغا ئىشلەتكىلى
          بولىدۇ.</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >2 دانە تۆت يۈەنلىك ئېتىبار بىلىتى، 30 يۈەنلىك زاكازغا ئىشلەتكىلى
          بولىدۇ.</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >2 دانە بەش يۈەنلىك ئېتىبار بىلىتى، 40 يۈەنلىك زاكازغا ئىشلەتكىلى
          بولىدۇ.</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >ئەسكەرتىش: يۇقارقى ئېتىبار بىلەتلىرىنى بىر قېتىمدا پەقەت بىرنىلا
          ئىشلىتەلەيسىز.</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >2) ئېتىبار بېلەتنىڭ ئىناۋەتلىك ۋاقتى&nbsp;2024 – يىلى 10 – ئاينىڭ 31
          – كۈنى 23:59:59&nbsp;غىچە&nbsp;بولۇپ، بەلگىلەنگەن ۋاقىت ئىچىدە
          ئىشلەتمىگەنلەر ئۆزلۈكىدىن ئېتىبار بېلەتنى ئىشلىتىشتىن ۋاز كەچكەن
          ھېسابلىنىدۇ.</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >&nbsp;</span
        >
      </p>
      <p style="line-height: 200%">
        <span
          style="font-family: UKIJEkran; color: rgb(0, 0, 0); font-size: 18px"
          ><strong>7. مۇكاپات تۈرلىرى</strong></span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >1) <strong>بىرىنجى دەرىجىلىك مۇكاپات:</strong> ئالما 16
          يانفون&nbsp;256GB&nbsp;نۇسخىسى، مۇكاپات سانى 10؛</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >2) <strong>ئىككىنجى دەرىجىلىك مۇكاپات:</strong> ئوپپو رېنو يانفون،
          مۇكاپات سانى 20؛</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >3) <strong>ئۈچىنجى دەرىجىلىك مۇكاپات:</strong> شياۋمى ئەقلىي
          بىلەيزۈكى، مۇكاپات سانى 100؛</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >&nbsp;</span
        >
      </p>
      <p style="line-height: 200%">
        <span
          style="font-family: UKIJEkran; color: rgb(0, 0, 0); font-size: 18px"
          ><strong>8. مۇكاپات نەقلەشتۈرۈش</strong></span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >مۇكاپات بۇيۇملىرى&nbsp;پائالىيەت ئاخىرلىشىپ 15 خىزمەت كۈنى ئىچىدە
          سۇپىمىز مۇكاپاتقا ئېرىشكۈچى چەك تارتىش پائالىيىتىدە قالدۇرغان ئالاقە
          نۇمۇرى ئارقىلىق ئالاقىلىشىپ مۇكاپاتنى نەقلەشتۈرۈش ۋاقتى ۋە ئورنىنى
          ئۇقتۇرىدۇ، شۇڭا چەك تارتىپ مۇكاپات چىققاندىن كېيىن چوقۇم توغرا بولغان
          مال تاپشۇرۇۋېلىش ئادرېسىڭىز ۋە تېلېفون نومۇرىڭىزنى تولدۇرۇڭ ۋە ئالاقە
          نۇمۇرىڭىزنىڭ ھەرۋاقىت نورمال ئالاقىلاشقىلى بولۇشىغا كاپالەتلىك
          قىلىڭ.</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >&nbsp;</span
        >
      </p>
      <p style="line-height: 200%; text-align: justify">
        <span
          style="font-family: UKIJEkran; color: rgb(0, 0, 0); font-size: 18px"
          ><strong>9. ئەسكەرتىش</strong></span
        >
      </p>
      <p>
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >1) <strong>ئادىل بولۇش:</strong> مەزكۇر پائالىيەتتە ھەرقانداق شەكىلدە
          مۇكاپات چىقىش نەتىجىسىگە تەسىر كۆرسىتىدىغان تېخنىكىلىق ۋەياكى باشقا
          شەكىلدىكى ناتوغرا ئۇسۇللارنى قوللىنىش چەكلىنىدۇ. ئەگەر بايقىلىپ قالسا،
          سۇپىمىز مەزكۇر قاتناشقۇچىنىڭ مۇكاپاتقا ئېرىشىش سالاھىيىتىنى بىكار
          قىلىپ، تارقىتىلغان ئېتىبار بىلەت ۋە مۇكاپاتنى قايتۇرۇۋېلىش بىلەن بىرگە
          قانۇنىي جاۋاپكارلىقىنى سۈرۈشتە قىلىدۇ.</span
        >
      </p>
      <p style="line-height: 200%">
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >2) <strong>پائالىيەت چۈشەندۈرۈش ھوقوقى: </strong>مەزكۇر پائالىيەتنىڭ
          چۈشەندۈرۈش ھوقوقى مۇلازىم سۇپىسىغا تەۋە. سۇپىمىز پائالىيەت ئەھۋالىغا
          قاراپ، پائالىيەت قائىدىسىنى تەڭشەشكە ھوقوقلۇق. تەڭشەش ئېلىپ بېرىشتىن
          بۇرۇن سۇپىمىزدا ئالدىن ئۇقتۇرۇش قىلىمىز.</span
        >
      </p>
      <p style="line-height: 200%"></p>
      <p style="line-height: 200%">
        <span
          style="font-family: UKIJEkran; color: rgb(0, 0, 0); font-size: 18px"
          ><strong>10. ئالاقە ئۇسۇلى</strong></span
        >
      </p>
      <p>
        <span style="font-family: UKIJEkran; color: rgb(110, 110, 110)"
          >ئەگەر سۇئالىڭىز بولسا ياكى ياردەمگە ئېھتىياجلىق بولسىڭىز، ھەرۋاقىت
          سۇپىمىز بىلەن ئالاقىلىشىڭ. ئالاقە نۇمۇرى: 990-1111-400.</span
        >
      </p>
    </div>
    <div v-if="lang == 'zh'">
      <h3 style="line-height: 1.45; text-align: center">
        <strong>“中秋节幸运大转盘”抽奖活动规则</strong>
      </h3>
      <h4 style="line-height: 1.45">
        <span style="font-size: 18px"><strong>一、活动概述</strong></span>
      </h4>
      <p>
        <span style="font-size: 11.5pt"
          >为了回馈广大用户的支持与厚爱，美滋来外卖平台特举办“中秋节幸运大转盘”抽奖活动。本次活动旨在通过趣味互动，让每一位参与者都有机会赢取丰厚奖品，共享欢乐时光。</span
        >
      </p>
      <h4 style="line-height: 1.45">
        <span style="font-size: 18px"><strong>二、参与方式</strong></span>
      </h4>
      <ol class="tight" data-tight="true">
        <li>
          <p>
            <span style="font-size: 11.5pt"
              ><strong>付费购买抽奖</strong
              >：用户可通过支付9.9元购买总额为30元的8张平台优惠券，即可自动获得一次抽奖机会。</span
            >
          </p>
        </li>
        <li>
          <p>
            <span style="font-size: 11.5pt"
              ><strong>自然消费抽奖</strong
              >：凡在mulazim外卖平台上单次下单金额超过60元的用户，即可自动获得一次抽奖机会。</span
            >
          </p>
        </li>
        <li>
          <p>
            <span style="font-size: 11.5pt"
              ><strong>邀请好友助力</strong
              >：用户将活动页面分享给好友，被邀请的好友在美滋来外卖平台通过上述两种方式的任意一种方式参与本次活动后，邀请者可获得一次免费抽奖机会。一个好友通过一种方式参与最多只能给邀请者赠送一次机会。邀请人数不限，抽奖机会可累加，同一个好友只能邀请一次。</span
            >
          </p>
        </li>
      </ol>
      <h4 style="line-height: 1.45">
        <span style="font-size: 18px"><strong>三、参与地点</strong></span>
      </h4>
      <h4 style="line-height: 1.45">
        <span style="font-size: 11.5pt"
          >美滋来外卖平台微信小程序、苹果App、安卓App端。</span
        >
      </h4>
      <h4 style="line-height: 1.45">
        <span style="font-size: 18px"
          ><strong>四、活动时间与开奖方式</strong></span
        >
      </h4>
      <ul class="tight" data-tight="true">
        <li>
          <p>
            <span style="font-size: 11.5pt"
              ><strong>活动时间</strong
              >：2024年9月21日00:00至2024年9月25日23:59。</span
            >
          </p>
        </li>
        <li>
          <p>
            <span style="font-size: 11.5pt"
              ><strong>开奖方式</strong
              >：参与者通过转动幸运大转盘进行抽奖，转盘停止后实时显示中奖结果。每位用户每次抽奖机会独立计算，不可累积使用。</span
            >
          </p>
        </li>
      </ul>
      <h4 style="line-height: 1.45">
        <span style="font-size: 18px"><strong>五、抽奖机会使用</strong></span>
      </h4>
      <h4 style="line-height: 1.45">
        <span style="font-size: 11.5pt"
          >参与者的抽奖次数（无论是通过消费获得还是付费购买）必须在活动结束时间（即2024年9月25日23:59）之前使用完毕。任何未在此时间之前使用的抽奖次数，均视为主动放弃，且平台不予折算购买机会的金额，同时也不会退还任何费用。请参与者合理安排时间，确保在活动期间内使用完所有抽奖机会。</span
        >
      </h4>
      <h4 style="line-height: 1.45">
        <span style="font-size: 18px"><strong>六、优惠券使用说明</strong></span>
      </h4>
      <h4 style="line-height: 1.45">
        <span style="font-size: 11.5pt">1.优惠券详情如下：</span>
      </h4>
      <p style="text-indent: 10px" data-indent="1">
        <span style="font-size: 11.5pt"
          >4张3元优惠券，单笔下单总额满25元即可使用一张；</span
        >
      </p>
      <p style="text-indent: 10px" data-indent="1">
        <span style="font-size: 11.5pt"
          >2张4元优惠券，单笔下单总额满30元即可使用一张；</span
        >
      </p>
      <p style="text-indent: 10px" data-indent="1">
        <span style="font-size: 11.5pt"
          >2张5元优惠券，单笔下单总额满40元即可使用一张；</span
        >
      </p>
      <h4 style="line-height: 1.45">
        <span style="font-size: 11.5pt"
          >2.优惠券使用期为2024年10月31日23:59:59之前，
          如未在此时间前使用优惠券，将视为放弃优惠券使用权。平台不予折算现金同时也不会退还购买优惠券的费用。</span
        >
      </h4>
      <h4 style="line-height: 1.45">
        <span style="font-size: 18px"><strong>七、奖品设置</strong></span>
      </h4>
      <ul class="tight" data-tight="true">
        <li>
          <p>
            <span style="font-size: 11.5pt"
              ><strong>一等奖</strong>：共10名，奖品为iPhone 16
              256G手机（颜色及型号随机）。</span
            >
          </p>
        </li>
        <li>
          <p>
            <span style="font-size: 11.5pt"
              ><strong>二等奖</strong>：共20名，奖品为OPPO Reno手机。</span
            >
          </p>
        </li>
        <li>
          <p>
            <span style="font-size: 11.5pt"
              ><strong>三等奖</strong>：共100名，奖品为小米手环9。</span
            >
          </p>
        </li>
      </ul>
      <h4 style="line-height: 1.45">
        <span style="font-size: 18px"><strong>八、兑奖方式</strong></span>
      </h4>
      <ul class="tight" data-tight="true">
        <li>
          <p>
            <span style="font-size: 11.5pt"
              >参与者中奖后，需保持联系方式畅通，以便平台安排现场面对面发放奖品。具体兑奖时间、地点将另行通知。</span
            >
          </p>
        </li>
      </ul>
      <h4 style="line-height: 1.45">
        <span style="font-size: 18px"><strong>九、注意事项</strong></span>
      </h4>
      <ol class="tight" data-tight="true">
        <li>
          <p>
            <span style="font-size: 11.5pt"
              ><strong>公平公正</strong
              >：本次活动严禁任何形式的技术篡改中奖结果或利用不正当手段刷取抽奖机会。一经发现，平台有权取消该用户的中奖资格，追回已发放的奖品及优惠券，并保留进一步追究法律责任的权利。</span
            >
          </p>
        </li>
        <li>
          <p>
            <span style="font-size: 11.5pt"
              ><strong>活动解释权</strong
              >：本次活动最终解释权归美滋来外卖平台所有。平台有权根据活动实际情况调整规则，并提前在平台公告。</span
            >
          </p>
        </li>
      </ol>
      <h4 style="line-height: 1.45">
        <span style="font-size: 18px"><strong>十、联系我们</strong></span>
      </h4>
      <p>
        <span style="font-size: 11.5pt"
          >如有任何疑问或需要帮助，请随时联系美滋来外卖平台客服400-1111-990，我们将竭诚为您服务。</span
        >
      </p>
      <p></p>
      <p></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: "ug",
    };
  },
  created() {
    this.lang = this.$route?.query?.lang || "ug";
  },
};
</script>

<style scoped>
.bodyClass {
  width: 100%;
  height: calc(100vh - 0px);
  background-color: #fff;
  padding: 10px 15px 40px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.bodyClass h2 {
  margin-top: 2px;
  margin-bottom: 10px;
}
.bodyClass h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.bodyClass p {
  text-align: justify;
}
.bodyRtl {
  direction: rtl;
}
li {
    margin: 0 18px;
}

</style>
