<template>
  <div class="model-content">
    <div
      class="model_27"
      :class="
        posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
      "
    >
      <div class="bg_img_container">
        <img style="width: 100%" :src="url" />
      </div>
      <div
        class="merchant_name"
        :style="{ fontSize: posterList.merchantNameFontSize + 'px' }"
      >
        <div class="merchant_name_font">{{ posterList.merchant_name }}</div>
      </div>
      <div class="food_img">
        <img :src="posterList.food_img" alt="" />
      </div>
      <div
        class="food_name"
        :style="{ fontSize: posterList.foodNameFontSize + 'px' }"
      >
        <div class="food_name_font">{{ posterList.food_name }}</div>
      </div>
      <div class="food_price">
        <span class="food_price_number">{{
          parseFloat(posterList.food_price)
        }}</span>
        <span>{{
          posterList.lang == "ug" || !posterList.lang ? "يۈەن" : "元"
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_27",
  data() {
    return {
      posterList: {},
      url: require("../../img/poster/model_27.jpg"),
    };
  },
  created() {
    this.canvasfortitle(
      this.$route.query.merchant_name,
      this.$route.query.food_name,
      this.$route.query
    );
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      this.posterList = query;

      let res_name = merchant_name.replaceAll("(", "-");
      res_name = res_name.replaceAll("（", "-");
      res_name = res_name.replaceAll(")", " ");
      res_name = res_name.replaceAll("）", " ");
      this.posterList["merchant_name"] = res_name;

      let food_name = food_names.replaceAll("(", "-");
      food_name = food_name.replaceAll("（", "-");
      food_name = food_name.replaceAll(")", " ");
      food_name = food_name.replaceAll("）", " ");
      this.posterList.food_name = food_name;

      if (query.lang == "ug") {
        if (res_name.length < 30) {
          this.posterList.merchantNameFontSize = 32;
        } else {
          this.posterList.merchantNameFontSize = 22;
        }
        if (food_name.length < 30) {
          this.posterList.foodNameFontSize = 32;
        } else {
          this.posterList.foodNameFontSize = 22;
        }
      } else {
        if (res_name.length < 10) {
          this.posterList.merchantNameFontSize = 32;
        } else {
          this.posterList.merchantNameFontSize = 22;
        }
        if (food_name.length < 10) {
          this.posterList.foodNameFontSize = 32;
        } else {
          this.posterList.foodNameFontSize = 22;
        }
      }
    },
  },
};
</script>

<style scoped>
.model-content {
  width: 750px;
  height: 320px;
  display: flex;
  justify-content: center;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.model_27 {
  width: 750px;
  height: 320px;
  position: relative;
  overflow: hidden;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.bg_img_container {
  width: 100%;
  height: 100%;
}

.bg_img_container img {
  width: 100%;
}

.merchant_name {
  width: 390px;
  height: 76px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  color: #ffd800;
  padding: 8px 12px;
  box-sizing: border-box;
  position: absolute;
  right: 336px;
  top: 88px;
}

.merchant_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.food_img {
  position: absolute;
  height: 201px;
  width: 323px;
  overflow: hidden;
  right: 9px;
  top: 58px;
}

.food_img img {
  width: 100%;
  height: 100%;
  clip-path: ellipse(50% 50% at 50% 50%);
  object-fit: cover;
}

.food_name {
  width: 390px;
  height: 76px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  color: #ffd800;
  padding: 8px 12px;
  box-sizing: border-box;
  position: absolute;
  right: 336px;
  top: 160px;
}

.food_name .food_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.food_price {
  color: #303030;
  background-color: #aa7c0d;
  border-radius: 20px;
  padding: 0px 10px;
  box-sizing: border-box;
  position: absolute;
  right: 444px;
  top: 234px;
  min-width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.food_price .food_price_number {
  padding: 0px 4px;
}
</style>
