<style>
body {
    background-color: #EFF1F6;
}

.content {
    max-width: 500px;
    width: 100%;
    /* padding: 15px; */
    margin: 15px;
    background-color: #fff;
    border-radius: 15px;
}

.m-1 {
    margin: 20px;
}

.etims_center {
    align-items: center;
}

.between {
    justify-content: space-between
}
</style>


<template>
    <div style="display: flex;justify-content: center;">
        <div class="content">

            <div v-for="(item, index) in institution_list" :key="index">
                <div class="flex etims_center between m-1" @click="path(item.path,item.title)">
                    <div>{{ item.title }}</div>
                    <img :src="item.incone" alt="">
                </div>


            </div>

        </div>
    </div>
</template>

<script>
import icon_right from './right.svg'

export default {

    data() {
        return {
            institution_list: [
                {
                    title: "美滋来平台商户入驻协议",
                    incone: icon_right,
                    path: "/registration_norms"
                },
                {
                    title: "入网餐饮服务提供者审查登记规范",
                    incone: icon_right,
                    path: "/Merchant_residence_agreement"
                },
                {
                    title: "美滋平台商户信息发布管理规范",
                    incone: icon_right,
                    path: "/Release_management_norms"
                },
                {
                    title: "美滋来平台商户违规作弊管理规范",
                    incone: icon_right,
                    path: "/Cheating_management_standard"
                },
                {
                    title: "美滋来平台交易规则",
                    incone: icon_right,
                    path: "Transaction_rules"
                },
                {
                    title: "美滋来平台用户服务协议",
                    incone: icon_right,
                    path: "User_service_agreement"
                },
                {
                    title: "美滋来平台消费者权益保障措施",
                    incone: icon_right,
                    path: "Protection_of_rights_and_interests"
                },
                {
                    title: "美滋来平台用户评价规范",
                    incone: icon_right,
                    path: "User_evaluation_specification"
                },
                {
                    title: "美滋来平台隐私权政策",
                    incone: icon_right,
                    path: "Privacy_policy"
                },
                {
                    title: "美滋来平台食品安全管理办法",
                    incone: icon_right,
                    path: "Food_safety_management"
                }
               

            ]

        }
    },
    created() {
        document.title = '服务协议及相关规则'; // 修改页面的标题
    },
    methods: {
        path(url,title) {
            this.$router.push({
                path: url,
                query: {
                    data:title
                }
                
            },)
           

        }
    }






}

</script>