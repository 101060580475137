
<template>
  <div class="model-content">
    <div class="model7" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'model_ug' : 'model_zh'">
      <div class="bg_img_container">
        <img v-if="posterList.lang == 'ug' || !posterList.lang" style="width: 100%" :src="moban_list.url_ug" />
        <img v-if="posterList.lang == 'zh'" style="width: 100%" :src="moban_list.url_zh" />
      </div>
      <div class="merchant_name">
        <div class="merchant_name_font">{{ posterList.merchant_name }}</div>
      </div>
      <div class="food_name">
        <div class="food_name_font">{{ posterList.food_name }}</div>
      </div>
      <div class="food_img">
        <img :src="posterList.food_img" alt="">
      </div>
      <div class="food_price" :style="{fontSize: posterList.fontSize + 'px'}">
        <div class="price">
          <span>{{ posterList.food_price }}</span>
        </div>
      </div>
      <div class="old_food_price">
        <div class="price">
          <span>{{ posterList.old_price }}</span><span v-if="posterList.lang == 'zh'">元</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_17",
  data() {
    return {
      posterList: {},
      moban_list: {
        url_zh: require('../../img/poster/model_17_zh.jpg'),
        url_ug: require('../../img/poster/model_17_ug.jpg'),
      },
    };
  },
  created() {
    this.canvasfortitle(this.$route.query.merchant_name, this.$route.query.food_name, this.$route.query);
  },
  methods: {
    canvasfortitle(merchant_name, food_names, query) {
      let res_name = merchant_name.replaceAll('(', '-')
      res_name = res_name.replaceAll('（', '-')
      res_name = res_name.replaceAll(')', ' ')
      res_name = res_name.replaceAll('）', ' ')
      let food_name = food_names.replaceAll('(', '-')
      food_name = food_name.replaceAll('（', '-')
      food_name = food_name.replaceAll(')', ' ')
      food_name = food_name.replaceAll('）', ' ')
      this.posterList = query;
      this.posterList['merchant_name'] = res_name;
      this.posterList['food_name'] = food_name;
      const price = this.posterList.food_price.toString();
      let fontSize = 24;
      if(price.length <= 2) {
        fontSize = 50;
      } else if(price.length == 3) {
        fontSize = 45;
      } else if(price.length == 4) {
        fontSize = 40;
      } else if(price.length == 5) {
        fontSize = 35;
      } else if(price.length == 6) {
        fontSize = 30;
      }
      this.posterList.fontSize = fontSize;
    },
  },
};
</script>

<style scoped>
.model-content {
  width: 750px;
  height: 320px;
  display: flex; 
  justify-content: center;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.model7 {
  width: 750px;
  height: 320px;
  position: relative;
  overflow: hidden;
}

.model_ug {
  direction: rtl;
}

.model_zh {
  direction: ltr;
}

.bg_img_container {
  width: 100%;
  height: 100%;
}

.bg_img_container img {
  width: 100%;
}

.merchant_name {
  width: 370px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #272624;
  font-size: 22px;
  padding: 8px 12px;
  box-sizing: border-box;
}

.merchant_name {
  position: absolute;
  right: 312px;
  top: 48px;
}

.merchant_name_font {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.food_name {
  position: absolute;
  color: #fff;
  width: 222px;
  height: 92px;
  top: 124px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding: 8px 10px;
  font-size: 22px;
  box-sizing: border-box;
}

.model_ug .food_name {
  right: 285px;
}

.model_zh .food_name {
  width: 198px;
  left: 46px;
}

.food_name_font {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.model_ug .food_name_font {
  text-align: right;
}

.model_zh .food_name_font {
  text-align: left;
}

.food_img {
  position: absolute;
  height: 100%;
  width: 280px;
  overflow: hidden;
  right: 0px;
  top: 0px;
}

.food_img img {
  width: 100%;
  height: 100%;
  clip-path: circle(83% at right center);
}

.food_price {
  position: absolute;
  width: 100px;
  height: 94px;
  font-size: 30px;
  font-weight: bold;
  color: #453c3d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.model_ug .food_price {
  top: 123px;
  left: 143px;
}

.model_zh .food_price {
  top: 123px;
  left: 244px;
}

.old_food_price {
  position: absolute;
  width: 69px;
  height: 35px;
  font-size: 22px;
  font-weight: bold;
  color: #453c3d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.model_ug .old_food_price {
  bottom: 32px;
  left: 321px;
}

.model_zh .old_food_price {
  width: 144px;
  bottom: 37px;
  left: 380px;
  font-size: 28px;
  justify-content: flex-start;
}

</style>