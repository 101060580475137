<template>
  <div class="model-content">
    <div
      class="mode25"
      :class="
        posterList.lang == 'ug' || !posterList.lang ? 'model_ug' : 'model_zh'
      "
    >
      <div
        class="header-color"
        :style="posterList.steps.length == 5 ? 'margin-bottom: 0px' : ''"
      >
        {{ posterList.merchant_name }}
        {{ posterList.lang == "ug" ? "دىن تاماق بۇيرۇتسىڭىز" : "餐厅订餐" }}
        {{ posterList.lang == "ug" ? "تاماق سوممىسى" : "美食总额满" }}
        {{ posterList.start_order_price }}
        {{ posterList.lang == "ug" ? "يۈەنگە توشسا" : "元" }}
      </div>

      <div class="contents">
        <div
          v-for="(item, index) in posterList.steps"
          :key="index"
          class="step"
        >
          <div class="tags">
            <span v-if="item.distance_end == '999000'">
              <span>{{ posterList.lang == "ug" ? "" : "距离" }}</span>
              <span style="padding: 0 2px">{{
                posterList.steps[posterList.steps.length - 2].distance_end /
                1000
              }}</span>
              <span>Km</span>
              <span style="padding: 0 2px">{{
                posterList.lang == "ug" ? "دىن يۇقىرىسىغا" : "以上"
              }}</span>
            </span>
            <span v-else>
              <span>{{ posterList.lang == "ug" ? "" : "距离" }}</span>
              <span style="padding: 0 2px">{{ item.distance_end / 1000 }}</span>
              <span>Km</span>
              <span style="padding: 0 2px">{{
                posterList.lang == "ug" ? "ئىچىدە" : "内"
              }}</span>
            </span>
          </div>
          <div
            class="UkijKufiYay step-color"
            :style="posterList.steps.length == 1 ? 'font-size:30px;' : ''"
          >
            {{ posterList.lang == "ug" ? "كىرادا" : "配送费立减" }}
            <span class="UkijKufiYay">{{ item.price_reduce / 100 }}</span>
            {{ posterList.lang == "ug" ? "يۈەن ئېتىبار قىلىنىدۇ" : "元" }}
          </div>
        </div>
      </div>

      <div class="imageWidth" :style="getStyle">
        <img :src="posterList.tagImage" />
      </div>
      <div class="imageWidth" :style="getMotoStyle">
        <img :src="posterList.moto" />
      </div>
      <div class="merchant-name">
        <div class="merchant-name-title UkijKufiYay">
          {{ posterList.merchant_name }}
        </div>
      </div>
      <div class="merchant_img">
        <img :src="posterList.merchant_img" alt="" />
      </div>
      <div class="merchant_qrcode">
        <img :src="posterList.merchant_qrcode" alt="" />
      </div>
      <div class="footer-title">
        <div>叫外卖，就用美滋来!</div>
        <div class="padding-mds"></div>
        <div>تاماقنى مۇلازىمدىن بۇيرۇتۇڭ</div>
      </div>
      <div class="footer"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "model_25",
  data() {
    return {
      posterList: {},
      moban_list: {
        url_zh: require("../../img/poster/model_24_zh.jpg"),
        url_ug: require("../../img/poster/model_24_ug.jpg"),
      },
    };
  },
  created() {
    this.canvasfortitle(this.$route.query.merchant_name, this.$route.query);
  },
  computed: {
    getStyle() {
      if (this.posterList.steps.length == 1) {
        return "bottom: 120px;left: -31px;width: 338px;";
      } else if (this.posterList.steps.length == 2) {
        return "bottom: 100px;left: -76px;width: 338px;";
      } else if (this.posterList.steps.length == 3) {
        return "bottom: 120px;left: -40px;width: 340px;";
      } else if (this.posterList.steps.length == 4) {
        return "bottom: 90px;left: -20px;width: 300px;";
      } else {
        return "bottom: 52px;left: -20px;width: 300px;";
      }
    },
    getMotoStyle() {
      if (this.posterList.steps.length == 1) {
        return "bottom: 115px;width: 260px;right: -54px;z-index: 1;";
      } else if (this.posterList.steps.length == 2) {
        return "bottom: 115px;width: 260px;right: -54px;z-index: 1;";
      } else if (this.posterList.steps.length == 3) {
        return "bottom: 115px;width: 190px;right: -18px;z-index: 1;";
      } else if (this.posterList.steps.length == 4) {
        return "bottom: 115px;width: 155px;right: -24px;z-index: 1;";
      } else {
        return "bottom: 112px;width: 100px;right: 5px;z-index: 1;";
      }
    },
  },
  methods: {
    canvasfortitle(merchant_name, query) {
      let res_name = merchant_name.replaceAll("(", "-");
      res_name = res_name.replaceAll("（", "-");
      res_name = res_name.replaceAll(")", " ");
      res_name = res_name.replaceAll("）", " ");
      this.posterList = query;
      this.posterList["merchant_name"] = res_name;
      this.posterList["steps"] = JSON.parse(this.$route.query.steps);
      this.posterList["moto"] = require("../../img/poster/model25/moto.png");
      this.posterList["tagImage"] =
        this.posterList.steps.length > 2
          ? require("../../img/poster/model25/step_2.png")
          : require("../../img/poster/model25/step_1.png");
    },
  },
};
</script>

<style scoped>
.model-content {
  width: 370px;
  height: 610px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    rgb(246, 44, 44),
    rgb(249, 110, 41),
    53%,
    #10c35a
  );
  align-items: center;
  border-radius: 18px;
}

.imageWidth {
  position: absolute;
}
.imageWidth img {
  width: 100%;
  height: 100%;
}
.model_ug .contents {
  direction: rtl;
}
.mode25 {
  width: 360px;
  height: 600px;
  position: relative;
  background-color: #eff1f6;
  overflow: hidden;
  border-radius: 16px;
}

.tags {
  border-radius: 3px;
  background: linear-gradient(
    90deg,
    rgb(246, 44, 44) 3.817%,
    rgb(249, 110, 41) 100%
  );
  width: max-content;
  padding: 4px 5px;
  font-size: 11px;
  color: #fff;
}
.step-color {
  color: #f62c2cff;
  font-size: 24px;
  margin-bottom: 10px;
}
.merchant_img {
  position: absolute;
  z-index: 1;
  width: 49.07px;
  height: 49.07px;
  border-radius: 7.75px;
  bottom: 82px;
  right: 102px;
  overflow: hidden;
}
.merchant_img img {
  width: 100%;
  height: 100%;
}
.padding-mds {
  padding: 0 5px;
}

.footer-title {
  position: absolute;
  z-index: 1;
  font-size: 12.54px;
  color: #fff;
  bottom: 10px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  position: absolute;
  width: 100%;
  height: 160px;
  border-radius: 18px 18px 0 0;
  background: rgb(16, 195, 90);
  bottom: 0px;
}

.merchant-name {
  position: absolute;
  z-index: 1;
  bottom: 30px;
  right: 6px;
  width: 240px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.model_ug .merchant-name {
  direction: rtl;
}

.model_zh .merchant-name {
  direction: ltr;
}
.merchant-name-title {
  color: #fff;
  font-size: 17px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*垂直排列*/
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /*只显示6行*/
  overflow: hidden;
  /*溢出隐藏*/
  text-overflow: ellipsis;
  /*省略号代替*/
}

.merchant_qrcode {
  position: absolute;
  z-index: 1;
  width: 89.64px;
  height: 89.64px;
  left: 16.04px;
  bottom: 42.26px;
  padding: 9.44px;
  border-radius: 6.61px;
  box-sizing: border-box;
  background: #fff;
}

.merchant_qrcode img {
  width: 100%;
  height: 100%;
}
.header-color {
  color: #f62c2cff;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.model_ug .header-color {
  direction: rtl;
}
.step {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
