<template>
  <div class="model-content">
    <div class="teacherPoster">
      <div class="img-box">
        <img style="width: 100%" :src="poster" />
      </div>

      <div class="teacher-img">
        <img :src="posterList.photo" alt="" />
      </div>

      <div class="teacher-name">
        <div class="teacher-name-title">{{ posterList.name }}</div>
      </div>

      <div class="teacher-content">
        <div class="teacher-content-title">{{ posterList.description }}</div>
      </div>

      <div class="qr-code" v-html="posterList.share_url"></div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcode-svg";
export default {
  name: "teacherPoster",
  data() {
    return {
      posterList: {},
      poster: require("../../img/poster/teacherPoster.jpg"),
    };
  },
  created() {
    this.posterList = this.$route.query;
    const svg = new QRCode({
      content: this.posterList?.share_url,
      padding: 2,
      width: 79,
      height: 79,
      color: "#000000",
      background: "#ffffff",
      ecl: "M",
    }).svg();
    this.posterList["share_url"] = svg;
  },
};
</script>

<style scoped>
.model-content {
  width: 408px;
  height: 856px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
}

.teacherPoster {
  width: 408px;
  height: 856px;
  position: relative;
}

.img-box {
  width: 100%;
  height: 100%;
}

.teacher-name {
  position: absolute;
  top: 385px;
  color: #ffffff;
  width: 205px;
  left: 40px;
}

.teacher-name-title {
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.teacher-content-title {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 10;
  -webkit-line-clamp: 10;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teacher-img {
  position: absolute;
  top: 339px;
  right: 29px;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  overflow: hidden;
}

.teacher-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-code {
  position: absolute;
  bottom: 21px;
  left: 40px;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
}

.teacher-content {
  position: absolute;
  top: 494px;
  width: 334px;
  left: 38px;
  box-sizing: border-box;
  padding: 0px 12px 0px;
  text-align: justify;
}
</style>
