<template>
  <div class="bodyClass" :class="{ bodyLtr: lang == 'zh' }">
    <!-- 三个标签 -->
    <div class="tap" :class="{ dir: lang == 'ug' }">
      <div
        class="item"
        :class="{ fontColor: index == active }"
        v-for="(item, index) in tapList"
        :key="index"
        @click="onTapClick(index)"
      >
        {{ item[`title_${lang}`] }}
      </div>
      <div class="active" :style="`${lang=='ug'?'right':'left'}: calc(5% + (42% - 9%) * var(--active-index, ${ active })); transition: ${lang=='ug'?'right':'left'} 0.3s;`"></div>
    </div>
    <div class="contents">
      <div
        class="titles"
        :class="{ dir: lang == 'ug' }"
        ref="scroll_0">
        <span class="borders"></span>
        <span class="titles-text">{{
          contentList[0][`introduce_${lang}`]
        }}</span>
      </div>
      <div class="content-images">
        <img src="../../img/eventDetails/introduce_content_1_ug.png" />
      </div>
      <div class="content_titles" :class="lang == 'ug' ? 'right' : 'left'">
        {{ contentList[0][`introduce_content_${lang}`] }}
      </div>
      <div class="titles" :class="{ dir: lang == 'ug' }">
        <span class=""></span>
        <span class="titles-text">{{
          contentList[0][`introduce_mini_${lang}`]
        }}</span>
      </div>

      <div
        class="content-images"
        v-for="item in contentList[0].introduceImgList"
        :key="item.img_ug"
      >
        <img :src="item.img_ug" />
      </div>

      <div class="titles" :class="{ dir: lang == 'ug' }" ref="scroll_1">
        <span class="borders"></span>
        <span class="titles-text">{{
          contentList[0][`recommend_${lang}`]
        }}</span>
      </div>
      <div
        class="content-images"
        v-for="item in contentList[0].recommendImgList"
        :key="item.img_ug"
      >
        <img :src="item.img_ug" />
      </div>

      <div class="titles" :class="{ dir: lang == 'ug' }" ref="scroll_2">
        <span class="borders"></span>
        <span class="titles-text">{{ contentList[0][`rule_${lang}`] }}</span>
      </div>
      <div style="width: 100%; height: 100px;"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: "ug",
      active: 0,
      tapList: [
        {
          id: 1,
          title_ug: "چۈشەندۈرۈلىشى",
          title_zh: "介绍",
        },
        {
          id: 2,
          title_ug: "تەۋسىيە",
          title_zh: "推荐",
        },
        {
          id: 3,
          title_ug: "پائالىيەت قائىدىسى",
          title_zh: "活动规则",
        },
      ],
      contentList: [
        {
          introduce_content_ug:
            "خېرىدارغا يارىدەم قىلىپ يەتكۈزۈش ھەققىنىڭ بىر قىسمىنى كۆتۈرۈش ئارقىلىق تېخىمۇ كۆپ خېرىدارلارنى جەلىپ قىلالايسىز",
          introduce_mini_ug: "مۇلازىم ئەپچاقتا كۆرۈنۈش ئورنى",
          introduce_mini_zh: "美滋来外卖小程序显示地址",
          introduceImgList: [
            {
              img_ug: require("../../img/eventDetails/introduce_content_2_ug.png"),
              img_zh: require("../../img/eventDetails/introduce_content_2_ug.png"),
            },
            {
              img_ug: require("../../img/eventDetails/introduce_content_3_ug.png"),
              img_zh: require("../../img/eventDetails/introduce_content_3_ug.png"),
            },
            {
              img_ug: require("../../img/eventDetails/introduce_content_4_ug.png"),
              img_zh: require("../../img/eventDetails/introduce_content_4_ug.png"),
            },
            {
              img_ug: require("../../img/eventDetails/introduce_content_5_ug.png"),
              img_zh: require("../../img/eventDetails/introduce_content_5_ug.png"),
            },
          ],
          introduce_ug: "پائالىيەت چۈشەندۈرۈلىشى",
          introduce_zh: "活动介绍",
          recommend_ug: "بىزدىن تەۋسىيە",
          recommend_zh: "推荐",
          recommendImgList: [
            {
              img_ug: require("../../img/eventDetails/recommend_content_1_ug.png"),
              img_zh: require("../../img/eventDetails/recommend_content_1_ug.png"),
            },
          ],
          ruleImgList: [],
          rule_ug: "پائالىيەت قائىدىسى",
          rule_zh: "活动规则",
        },
      ],
      elements: [
        { ref: "scroll_0", active: 0 },
        { ref: "scroll_1", active: 1 },
        { ref: "scroll_2", active: 2 },
      ],
    };
  },
  created() {
    this.lang = this.$route?.query?.lang || "ug";
  },
  mounted() {
    this.shouldRunHandleScroll = true; // 初始化标志位为false
    window.addEventListener("scroll", () => {
      this.handleScroll();
    });
    let timer = null;

    window.addEventListener("scroll", () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.shouldRunHandleScroll = true; // 开始运行 handleScroll() 方法
      }, 200); // 设置延迟时间，单位为毫秒
    });
  },
  methods: {
    // 滚动事件
    handleScroll() {
      const that = this;
      that.elements.forEach((element) => {
        const targetElement = that.$refs[element.ref];
        const elementTop = targetElement.getBoundingClientRect().top;
        // 当元素顶部进入视口时触发事件
        if (elementTop <= 70) {
          if (that.shouldRunHandleScroll) {
            that.setActive(element.active);
          }
        }
      });
    },
    // 设置当前点击的元素
    setActive(id) {
      this.active = id;
    },
    // 点击事件
    onTapClick(id) {
      this.shouldRunHandleScroll = false; // 停止运行 handleScroll() 方法
      this.setActive(id);
      const element = this.$refs[`scroll_${id}`];
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        // element.style.scrollMargin = '60px';
      } else {
        console.log(`没有找到具有 id 为 ${id} 的元素`);
      }
    },
  },
};
</script>

<style scoped>
.bodyClass {
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #fff;
  margin-top: 60px;
}
.tap {
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  line-height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.tap .item {
  width: calc(100% / 3);
  text-align: center;
  font-size: 18px;
  color: #8d8c8c;
}
.active {
  position: absolute;
  bottom: 10px;
  height: 4px;
  background: rgb(16, 195, 90);
  border-radius: 20px 20px 0px 0px;
  width: 24%; /* 将宽度设置为22% */
}
.fontColor {
  color: #333333 !important;
}
.dir {
  direction: rtl;
}
.titles {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333333;
  padding: 15px 8px;
  scroll-margin-top: 65px;
}
.borders {
  border-radius: 0px 10px 10px 0px;
  background: rgb(16, 195, 90);
  width: 4px;
  height: 20px;
}
.titles-text {
  padding: 0 8px;
}
.content_titles {
  color: rgb(141, 140, 140);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  padding: 0px 10px 0px 10px;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.content-images {
  padding: 10px;
  box-sizing: border-box;
}
.content-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bodyLtr .borders {
  border-radius: 10px 0px 0px 10px !important;
}
</style>
