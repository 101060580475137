<style >
body {
    background-color: #EFF1F6;
}

.content {
    max-width: 500px;
    width: 100%;
    /* padding: 15px; */
    margin: 15px;
    background-color: #fff;
    border-radius: 15px;
}
.contet_title{
    font-size: 24px;
    padding-top: 15px;
    padding-bottom: 40px;
}
.m-1 {
    margin: 20px;
}

.etims_center {
    align-items: center;
}

.between {
    justify-content: space-between
}

.m-1 {
    margin: 20px;
}

.text-algin {
    text-align: center;
}

.text-bold {
    font-weight: 600;
}
</style>


<template>
    <div style="display: flex;justify-content: center;">
        <div class="content">
            <div class="m-1">
                <div class="text-algin text-bold contet_title">{{ title }}</div>
            </div>
            <div class="m-1">
                <h2><a>第一章 总则</a></h2>
                <p>&nbsp;<b></b></p>
                <h3><a>一、目的</a></h3>
                <p>为了保护用户、商家和配送员等交易主体的合法权益，规范交易行为，实现交易合同目的，维护平台经营秩序，促进绿色消费，根据相关国家法律法规，特此制定本规则。 </p>
                <h3><a>二、适用范围</a></h3>
                <p>本规则适用于在美滋来外卖平台 (以下称平台) 上开展经营的所有商家
                    (以下称卖家)、在美滋来外卖平台进行下单的所有用户(以下称买家)和进行商品配送的配送员（以下称配送方)。</p>
                <h3><a>三、</a>基本定义</h3>
                <p>平台：指美滋来外卖为商户提供外卖展示及交易功能的网络服务平台，包括但不限于美滋来外卖客户端、美滋来外卖小程序等。</p>
                <p>买家：指在美滋来外卖平台注册、接受平台规则，并可通过美滋来外卖平台与卖家达成交易的 消费者。</p>
                <p>卖家：指在美滋来外卖平台开展经营活动并向买家提供商品、服务的法人、自然人或其他组 织。</p>
                <p>&nbsp;&nbsp;配送方：负责商品配送和交付的网约配送员、快递或物流工作人员。</p>
                <p>不可抗力：本规则所称不可抗力，是指不能预见、不能避免并且不能克服的客观情况。&nbsp; 如暴雨大雪、台风、大雾及其他自然灾害；疫情、骚乱及其他社会异常事件；征收、征用及其他政府行为。</p>
                <h2><a>第二章 售前阶段</a></h2>
                <p><u>买家进入平台</u><u>进行卖家店铺浏览，完成支付之前的阶段。</u></p>
                <h3><a>一、卖家的义务</a></h3>
                <p><!--[if--><b>1.</b><!--[endif]--><b>商品/服务价格</b><b></b></p>
                <p><!--[if-->1.1&nbsp; <!--[endif]-->卖家进行价格活动，应当遵守中华人民共和国相关法律法规，执行依法制定的政府指导价、政府定价和法定的价格干预措施、紧急措施。</p>
                <p><!--[if-->1.2&nbsp; <!--[endif]-->卖家出售的商品或提供的服务的定价当遵循公平、合法和诚实信用的原则。</p>
                <p><!--[if-->1)<!--[endif]-->卖家出售商品或提供服务，应当按照政府价格主管部门的规定明码标价，注明商品的品名、 产地、规格、等级、计价单位、价格或者服务的项目、收费标准等有关情况；</p>
                <p><!--[if-->2)<!--[endif]-->卖家搭售商品或者服务，应当以显著方式提醒买家注意，不得将搭售商品或者服务作为默 认同意的选项；</p>
                <p><!--[if-->3)<!--[endif]-->卖家不得对出售的商品或提供的服务在标价之外进行加价，不得收取任何未予标明的费用。</p>
                <p><!--[if-->1.3&nbsp; <!--[endif]-->卖家不得有以下不正当价格行为：</p>
                <p><!--[if-->1)<!--[endif]-->通过虚构原价、虚假折扣等虚假的或者使人误解的价格手段，诱骗买家与其进行交易；</p>
                <p><!--[if-->2)<!--[endif]-->提供相同商品或者服务，无合理理由情况下，对具有同等交易条件的不同买家实行价格差别待遇；</p>
                <p><!--[if-->3)<!--[endif]-->通过改变商品质量或数量等手段向买家出售商品或者提供服务，来变相提高价格； &nbsp;</p>
                <p><!--[if-->4)<!--[endif]-->国家法律法规禁止的其他不正当价格行为。</p>
                <p><!--[if-->1.4&nbsp; <!--[endif]-->卖家因价格不当行为致使买家多付价款的，应按照相关法律规定向买家承担退还价款、赔偿损失等责任，平台有权在核实情况后向买家&nbsp;
                    “先行赔付”。因此产生的所有损失及合理费用由卖家承担。</p>
                <p><!--[if--><b>2.</b><!--[endif]--><b>商品/服务等相关信息</b></p>
                <p><!--[if-->2.1&nbsp;
                    <!--[endif]-->卖家应当按照法律规定在平台展示自己的经营地址和联系方式，以及商品或者服务的数量和质量、有效期限、价款或者费用明细、订单的具体履行期限和方式、安全注意事项和风险警示、售后服务、民事责任等信息。
                </p>
                <p><!--[if-->2.2&nbsp;
                    <!--[endif]-->卖家执行2.1规定时，应当保证信息的真实、全面、准确，不得作虚假、过期或者引人误解的宣传。关键信息必须在首页相关醒目位置披露，不得进行隐瞒或提供不符合当前实 际情况的信息。</p>
                <p><!--[if--><b>3.</b><!--[endif]--><b>商品/服务品质和数量</b><b></b></p>
                <p><!--[if-->3.1&nbsp; <!--[endif]-->卖家出售商品或提供服务的品质应当符合国家法律法规的要求。第十七条 卖家应当保证其出售的商品或者服务的品质符合保障买家的人身和财产安全的要求。
                    对可能危及买家的人身和财产安全的商品和服务，应当提前向买家做出真实的说明和明确的警示，并说明和标明正确使用商品或者接受服务的方法以及防止危害发生的方法。</p>
                <p><!--[if-->3.2&nbsp;
                    <!--[endif]-->卖家应当保证在正常使用商品或者接受服务的情况下其提供的商品或者服务应当具有的质量、性能、用途和有效期限；但买家在购买该商品或者接受该服务前已经知道其存在瑕疵，且存在该瑕疵不违反法律强制性规定的除外。
            </p>
            <p><!--[if-->3.3&nbsp; <!--[endif]-->卖家出售的商品的质量要求不明确的，按照国家标准、行业标准履行；没有国家标准、行业标准的，按照通常标准或者符合合同目的的特定标准履行。</p>
            <p><!--[if-->3.4&nbsp; <!--[endif]-->卖家应当保证其实际提供的商品或者服务的实际内容、质量、性能、用途与店铺 (商 品) 详情页、平台广告等卖家发布信息的地方所表明的状况相符。
            </p>
            <p><!--[if-->3.5&nbsp; <!--[endif]-->卖家出售的商品，应当保证计量准确和足量，不得在计量上弄虚作假或短斤少两。</p>
            <p><!--[if--><b>4.</b><!--[endif]--><b>服务过程</b><b></b></p>
            <p><!--[if-->4.1&nbsp; <!--[endif]-->卖家在出售商品或提供服务时，不得对买家使用侮辱性语言或实施人身侵害性等行为。</p>
            <p><!--[if-->4.2&nbsp;&nbsp; <!--[endif]-->卖家应当及时回复买家发起的关于本店铺的商品或服务的质量、内容、优惠活动等咨询，应当做出真实、明确的答复。</p>
            <p><!--[if-->4.3&nbsp; <!--[endif]-->卖家未经买家同意或者请求，或者买家明确表示拒绝的，不得向其发送商业性信息。</p>
            <p><!--[if--><b>5.</b><!--[endif]--><b>交易</b><b>合同订立和履行</b><b></b></p>
            <p><!--[if-->5.1&nbsp; <!--[endif]-->卖家应该根据实际经营情况调整自己的店铺状态。当卖家店铺处于在线状态时，视
                为进入营业状态，可承接买家订单。</p>
            <p><!--[if-->5.2&nbsp; <!--[endif]-->卖家应当保证订单详情页中由卖家提供的全部信息(包括但不限于外卖商品/服务的 名称、
                数量、价格等)的真实性、准确性、有效性。由此产生的对买家的违约责任由卖家承担。
            </p>
            <p><!--[if-->5.3&nbsp; <!--[endif]-->买家下单后，交易合同成立。买家支付完成时，交易合同开始生效，卖家应当立即 开始履行合同义务。预售类商品或买卖双方另有约定生效时间 除外。
            </p>
            <p><!--[if-->5.4&nbsp; <!--[endif]-->有下列情形之一的，卖家有权不履行合同义务，且不承担违约责任：</p>
            <p><!--[if-->1)<!--[endif]-->买家对订单的内容做出了实质性变更；</p>
            <p><!--[if-->2)<!--[endif]-->买家购买商品或服务并不是以个人消费为目的；</p>
            <p><!--[if-->3)<!--[endif]-->违背了公序良俗或违反了国家相关的法律法规。</p>
            <h3><a>二、买家权利和义务</a></h3>
            <p><!--[if-->1.<!--[endif]-->买家有权自主选择需要购买商品或者服务内容，不接受商品搭售和强买强卖等行为，有权进行比较、鉴别和挑选。</p>
            <p><!--[if-->2.<!--[endif]-->买家有权要求卖家出售的商品或提供服务，符合保障人身、财产安全的要求。</p>
            <p><!--[if-->3.<!--[endif]-->买家在购买商品或服务时，有权获得质量保障、 价格公平合理、 计量准确无误等公 平交易条件，有权拒绝卖家的强制交易。</p>
            <p><!--[if-->4.<!--[endif]-->买家有权知悉其购买、使用的商品或者接受的服务的真实情况。包括但不限于商品 的价格、规格、主要成份、售后服务或服务的内容、规格、费用等有关情况。</p>
            <p><!--[if-->5.<!--[endif]-->买家有权知悉其购买、使用的商品或接受的服务的优惠活动内容。第三十四条 买家实际最终支付的成交价格不得高于商品或服务的展示价格，买卖双方有特殊约
                定的除外。</p>
            <p><!--[if-->6.<!--[endif]-->买家在购买、使用商品和接受服务时，享有其人身安全不被侵害、人格尊严等民事
                权益得到尊重和保护的权利。</p>
            <p><!--[if-->7.<!--[endif]-->买家提交订单前应当仔细阅读并确认外卖订单的全部内容后方可点击确认订单并付 款，提交订单即视为已知晓、同意并接受外卖订单中的全部内容，与卖家订立外卖合同。
            </p>
            <p><!--[if-->8.<!--[endif]-->因买家提供的收货地址或通讯方式的不明确或不正确而导致的卖家无法发货、无法 配送或无法交付的风险及责任由买家承担。</p>
            <h2><a>第三章 售中</a></h2>
            <p><u>&nbsp;</u></p>
            <p><u>买家付款</u><u>后到卖家完成对买家的交付的阶段。</u></p>
            <h3><a>一、</a>卖家的义务和权利</h3>
            <p><!--[if--><b>1.&nbsp; </b><!--[endif]--><b>合同履行开始</b></p>
            <p><!--[if-->1.1&nbsp; <!--[endif]-->卖家对买家做出了更有利于买家权益的承诺，应当履行承诺。</p>
            <p><!--[if-->1.2&nbsp; <!--[endif]-->卖家的商品的备货出货、商品移交给配送方开始配送、配送方送达等行为应当符合交易合同或交易习惯的要求。</p>
            <p><!--[if-->1.3&nbsp; <!--[endif]-->卖家应当遵循诚信原则，根据订单内容和交易习惯履行对买家通知、协助、个人信息保密等义务：</p>
            <p><!--[if-->1)<!--[endif]-->卖家应当主动将履行过程中的关键行为信息及时、准确、真实、 全面的通知到买家；</p>
            <p><!--[if-->2)<!--[endif]-->卖家应当及时回复买家发起的有关履行的咨询，回复内容应当准确、真实，全面；</p>
            <p><!--[if-->3)<!--[endif]-->卖家应当协助买家解决履行过程中发生的实际问题，积极实现合同目的；</p>
            <p><!--[if-->4)<!--[endif]-->卖家应当遵守法律法规有关个人信息保护的规定，应当对买家的个人信息保密，不得泄露、出售或非法向他人提供。</p>
            <p><!--[if-->1.4&nbsp; <!--[endif]-->卖家不履行合同义务或者履行合同义务不符合合同约定或交易习惯的，应当向买家 承担继续履行、采取补救措施、支付违约金或者赔偿损失等违约责任。
            </p>
            <p><!--[if-->1.5&nbsp; <!--[endif]-->卖家因不可抗力不能履行合同义务应当及时通知买家，以减轻可能给买家造成的损
                失，并应当在合理期限内向买家提供证明。买家认可证明有效之后，卖家不承担对买家的违约责
                任，但法律另有规定的除外。在卖家延迟履行之后再发生不可抗力的，不能免除卖家对买家的违 约责任。</p>
            <p><!--[if-->1.6&nbsp;
                <!--[endif]-->卖家在履行过程中，如果需要进行合同内容实质性变更(如商品内容、数量、质量、价款或者报酬、履行期限、履行地点和方式等)，应当及时主动联系买家，与买家协商一致之后，方可进行合同变更。
                如果未和买家协商或协商不一致的情况下，卖家自行变更合同，则卖家承担
                对买家的违约责任。</p>
            <p><!--[if-->1.7&nbsp; <!--[endif]-->卖家和买家经过协商之后达成一致，可以取消订单，解除合同。&nbsp; 如果未和买家协商
                或协商不一致的情况下，卖家解除合同，则卖家承担对买家的违约责任。</p>
            <p><!--[if-->1.8&nbsp; <!--[endif]-->履行过程中，因卖家发生意外情况导致为实现合同目的而产生了额外费用，由卖家承担。</p>
            <p><!--[if-->1.9&nbsp; <!--[endif]-->&nbsp;交易成功后，卖家应当根据买家要求，依法出具纸质发票或者电子发票等购货凭证
                或者服务单据。</p>
            <p><!--[if--><b>2.&nbsp; </b><!--[endif]--><b>&nbsp;</b><b>商品交付</b></p>
            <p><!--[if-->2.1&nbsp; <!--[endif]-->卖家应当按照合同约定、交易习惯或承诺中与买家约定的方式、时限向买家交付商
                品或者服务，</p>
            <p>并承担商品配送中的风险和责任。</p>
            <p><!--[if-->2.2&nbsp; <!--[endif]-->卖家应当按照约定的包装方式交付商品。对包装方式没有约定或者约定不明确，应
                当按照通用的方式包装；没有通用方式的，应当采取足以保护商品且有利于节约资源、保护生态 环境的包装方式。</p>
            <p><!--[if-->2.3&nbsp; <!--[endif]-->商品毁损、变质等风险，在完成向买家交付之前由卖家承担，交付之后由买家承担，但是法律另有规定或者双方另有约定的除外。</p>
            <p><!--[if-->2.4&nbsp; <!--[endif]-->卖家交付的商品不符合质量要求的，卖家应当承担退款、重做或者赔偿损失等违约
                责任。</p>
            <p><!--[if-->2.5&nbsp; <!--[endif]-->卖家因为配送方的原因造成违约的，应当由卖家向买家先行承担违约责任。卖家和
                配送方之间的纠纷，依照法律规定或者合同约定另行解决。</p>
            <h3><a>二、买家的权利和义务</a></h3>
            <p><!--[if-->1.&nbsp;&nbsp;
                <!--[endif]-->买家完成支付后，卖家明确表示或者以自己的行为表明不履行合同义务的，买家可以要求其承担违约责任。
            </p>
            <p><!--[if-->2.&nbsp;&nbsp;
                <!--[endif]-->买家完成支付后，有权知悉履行过程中的关键行为信息。
            </p>
            <p><!--[if-->3.&nbsp;&nbsp;
                <!--[endif]-->买家有权享受卖家对其作出的更有利于买家权益的承诺。第五十九条
                买家和卖家经过协商之后达成一致，可以取消订单、解除合同。如果未和卖家协商、协商不一致或无本规则第六十条规定的情形之一的，买家解除合同，则买家承担违约责任。
            </p>
            <p><!--[if-->4.&nbsp;&nbsp; <!--[endif]-->有下列情形之一的，买家可以单方面解除合同，要求卖家承担违约责任：</p>
            <p><!--[if-->1)&nbsp;&nbsp; <!--[endif]-->在履行期限届满前，卖家明确表示或者以自己的行为表明不履行义务；</p>
            <p><!--[if-->2)&nbsp;&nbsp; <!--[endif]-->卖家延迟履行义务，经催告后在合理期限内仍未履行；&nbsp;&nbsp;&nbsp; </p>
            <p><!--[if-->3)&nbsp;&nbsp; <!--[endif]-->卖家有其他违约行为致使不能实现合同目的；</p>
            <p><!--[if-->4)&nbsp;&nbsp; <!--[endif]-->法律规定的其他情形。</p>
            <p><!--[if-->5.&nbsp;&nbsp; <!--[endif]-->买家提交订单的时候，应当保证收货地址的明确性和准确性。卖家将商品交付给配
                送方后，由于买家提供的地址不明确或不正确导致的，商品毁损、丢失、变质的风险由买家承担。</p>
            <p><!--[if-->6.&nbsp;&nbsp;
                <!--[endif]-->买家应当保证通讯信息的正确性和通讯工具畅通，便于接收配送方的交付通知，完成商品交付，实现合同目的。因买家通讯不畅导致配送方无法和买家完成当面交付，配送方将按
                照合同约定或者买家要求将商品置于指定交付地点，由此产生的商品的毁损、丢失、变质的风险
                由买家承担。</p>
            <p><!--[if-->7.&nbsp;&nbsp; <!--[endif]-->配送方在配送过程中，遇到路径或地形复杂、物业阻拦等意外情况向买家求助，出于实现合同目的，买家应当协助解决。</p>
            <p><!--[if-->8.&nbsp;&nbsp; <!--[endif]-->履行过程中，买家需要修改交付时间或地点，应当主动及时将变更需求通知到配送
                方和卖家。卖家同意之后，视为双方协商一致，合同变更生效。正在配送中的配送方可根据实际配送能力来选择是否继续配送。如卖家不同意变更合同，买家可选择继续执行合同或取消合同，取消合同的，买家需承担对卖家的违约责任。
            </p>
            <p><!--[if-->9.&nbsp;&nbsp; <!--[endif]-->买家应当及时领取商品，配送方按照合同约定或者买家要求将商品置于指定交付地
                点后，买家违反约定没有及时收取的，商品毁损、丢失、变质的风险自违反约定时起由买家承担。 第六十六条 因买家原因导致商品未按照约定的期限或地点交付的，买家应当自违反约定时起承 担商品毁损、丢失、变质等风险。
            </p>
            <h3><a>三、配送方的义务和权利</a></h3>
            <p><!--[if--><b>1.&nbsp; </b><!--[endif]--><b>配送方取货</b><b></b></p>
            <p><!--[if--><b>1.1 </b><!--[endif]-->配送方接单后，配送义务开始履行，应当及时到达正确的取货地点。</p>
            <p><!--[if--><b>1.2 </b><!--[endif]-->配送方应当及时完成取货并开始配送。<b></b></p>
            <p><!--[if--><b>2.&nbsp; </b><!--[endif]--><b>配送方配送</b></p>
            <p><!--[if--><b>2.1 </b><!--[endif]-->配送过程中，配送方应当及时、准确、真实的回复买家关于配送相关信息的咨询。<b></b></p>
            <p><!--[if--><b>2.2
                </b><!--[endif]-->配送过程中，配送方如遇到不可抗力导致无法继续配送或配送超时，应当及时通知买家和卖家，以减轻可能给对方造成的损失，并应当在合理期限内向卖家提供证明，卖家认可证明有效之后，配送方免除对卖家的违约责任。<b></b>
            </p>
            <p><!--[if--><b>2.3 </b><!--[endif]-->配送过程中，配送方如遇到意外情况(非不可抗力 )
                无法继续配送，配送方应当及时通知买家和卖家。由此产生的违约责任，由卖家先行承担。卖家和配送方之间的纠纷，依照法律规定或者按照约定处理。<b></b></p>
            <p><!--[if--><b>3.&nbsp; </b><!--[endif]--><b>配送方完成交付</b></p>
            <p><!--[if--><b>3.1 </b><!--[endif]-->配送方应当按照约定期限向买家完成商品交付，双方另有约定的除外。约定交付期限的，配送方不得晚于约定时间交付。因
                配送方配送超时而产生的违约责任，由卖家先行承担。卖家和配送方之间的纠纷，依照法律规定或者按照约定处理。<b></b></p>
            <p><!--[if--><b>3.2 </b><!--[endif]-->买家向配送方提出小范围的变更交付时间和地点，出于实现合同目的，配送方可以根据实际情况判断和选择是否予以配合。</p>
            <p><!--[if--><b>3.3 </b><!--[endif]-->配送方应当按照约定地点交付商品交付。如因买家原因放置在买家指定地点，配送方不承担商品的毁损、丢失、变质等风险。</p>
            <p><!--[if--><b>3.4 </b><!--[endif]-->配送方不应当向买家索取额外履行费用。在配送过程或交付过程中，因实现合同目的而产生了额外费用，配送方应向卖家收取。</p>
            <p><!--[if--><b>3.5 </b><!--[endif]-->配送方在配送和交付时，不得对买家使用侮辱性语言，不得对买家实施人身安全侵害性行为。</p>
            <p><!--[if--><b>3.6 </b><!--[endif]-->配送方应当在完成商品交付之后点击确认送达，完成交付时间的确认。</p>
            <p><!--[if--><b>3.7 </b><!--[endif]-->配送方在取货、配送和交付时，享有其人身安全不被侵害、人格尊严等民事权益得到尊重和保护的权利。</p>
            <h2><a>第四章 售后</a></h2>
            <p><u>&nbsp;</u></p>
            <p><u>买家获取</u><u>商品后到消费使用的阶段。</u></p>
            <h3><a>一、卖家的义务和权利</a></h3>
            <p><!--[if-->1.<!--[endif]-->卖家应当听取买家对其提供的商品或者服务的意见，接受买家的合理的监督和批评。<b></b></p>
            <p><!--[if-->2.<!--[endif]-->卖家应当及时响应买家发起的合同履行纠纷争议处理诉求，承担违约责任，积极主动的实现合同<b></b></p>
            <p>目的。<b></b></p>
            <p><!--[if-->3.<!--[endif]-->卖家应当及时响应买家发起的维权主张，在规定的期限内积极主动完成对买家权益的保障。</p>
            <p><!--[if-->4.<!--[endif]-->买家不履行合同义务或者履行合同义务不符合合同约定或交易习惯的，应当承担违约责任。卖家</p>
            <p>根据损失的大小，可以合理选择请求买家承担违约责任。</p>
            <h3><a>二、买家的权利和义务</a></h3>
            <p><!--[if-->1.<!--[endif]-->买家的知情权、自主选择权、公平交易权、人身财产安全权、受尊重权等合法权益受到侵害时，有权向卖家发起维权主张，依法获得赔偿。<b></b></p>
            <p><!--[if-->2.<!--[endif]-->卖家不履行合同义务或者履行合同义务不符合合同约定或交易习惯的，买家可根据损失的大小，要求卖家承担更换、退货、退款或赔偿损失等违约责任。</p>
            <p><!--[if-->3.<!--[endif]-->因交付的商品不符合质量要求，致使不能实现合同目的的，买家可以拒绝接受商品 并要求卖家承担违约责任。买家拒绝接受的商品，商品的毁损、丢失、变质等风险由卖家承担。
            </p>
            <p><!--[if-->4.<!--[endif]-->商品毁损、丢失、变质等风险由买家承担的，不影响因卖家履行义务不符合约定，买家请求其承担违约责任的权利。</p>
            <p><!--[if-->5.<!--[endif]-->卖家对买家做出更有利于买家权益的承诺之后并未履行，买家有权要求卖家兑现承。</p>
            <p><!--[if-->6.<!--[endif]-->卖家违约后，买家应当采取适当措施防止损失的扩大；没有采取适当措施致使损失扩大的，不得就扩大的损失请求赔偿。买家因防止损失扩大而支出的合理费用，由卖家承担。&nbsp;
            </p>
            <p><!--[if-->7.<!--[endif]-->买家和卖家都违反合同的，应当各自承担相应的违约责任。一方违约造成对方损失，对方对损失的发生有过错的，可以减少相应的损失赔偿额。</p>
            <h3><a>三、平台的义务</a></h3>
            <p><!--[if-->1.<!--[endif]--><b>信息保存</b><b></b></p>
            <p>平台应当记录、保存平台上发布的商品和服务信息、交易信息，并确保信息的完整性、保密性、可用性。商品和服务信息、交易信息保存时间自交易完成之日起不少于三年；法律法规另有规定的，依照其规定。</p>
            <p><!--[if-->2.<!--[endif]--><b>信息展示</b></p>
            <p>平台应当收集、整理卖家的历史交易、义务履行情况等相关信息，以合理的方式展示给买家作为交易参考。</p>
            <p><!--[if-->3.<!--[endif]--><b>信息通道</b></p>
            <p><!--[if-->3.1&nbsp; <!--[endif]-->为卖家建立信息传递通道，让卖家能够将履行信息及时、准确、真实、全面的传递给到买家和配送方。</p>
            <p><!--[if-->3.2&nbsp; <!--[endif]-->为配送方建立信息传递通道，让配送方能够将履行信息及时、准确、真实、全面的传递给到买家和卖家。</p>
            <p><!--[if-->3.3&nbsp; <!--[endif]-->为买家建立咨询通道，买家能够由此发起向卖家和配送方的咨询，获取更多的交易信息和履行信息。</p>
            <p><!--[if-->4.<!--[endif]--><b>服务提供</b></p>
            <p>为买家、卖家、配送方等提供必要的咨询、产品、数据等服务，帮助实现合同目的、提升整体的交易体验。</p>
            <p><!--[if-->5.<!--[endif]--><b>合同履行中的纠纷争议处理</b></p>
            <p>建立完善的合同履行过程中的纠纷争议在线解决机制。包括但不限于为买家、卖家&nbsp;
                和配送方提供在线投诉入口，帮助买家、卖家和配送方制定纠纷争议的处理标准和处理时限等，提供相关的服务支持，监督违约责任的承担，推动合同目的的顺利实现。</p>
            <p><!--[if-->6.<!--[endif]--><b>交易过程中的权益保障</b></p>
            <p>建立完善的维权机制。包括但不限于为买家、卖家和配送方提供在线维权入口，帮
                助买家、卖家和配送方制定权利维护的处理标准、处理时限、保护手段等，提供相关的服务支持，并对于侵害方采取必要措施，帮助交易主体合法权益的得到有效保护。</p>
            <h2><a>附则</a></h2>
            <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b></b></p>
            <p><!--[if-->1.<!--[endif]-->卖家出售商品或者提供服务，不履行合同义务或者履行合同义务不符合约定，或者 造成买家或配送方损害的，依法承担民事责任。</p>
            <p><!--[if-->2.<!--[endif]-->买家发起维权主张之后，平台根据规则对卖家进行处置之后，并不能免除其应付的 法律责任，买家可以继续向国家监管机构投诉或向法院起诉。</p>
            <p><!--[if-->3.<!--[endif]-->交易合同不生效、无效、被撤销或者终止的，不影响违约责任的纠纷争议投诉和解决。</p>
            <p><!--[if-->4.<!--[endif]-->承担违约责任或权益侵害责任的方式主要有：</p>
            <p><!--[if-->1)<!--[endif]-->停止侵害；</p>
            <p><!--[if-->2)<!--[endif]-->返还商品或退钱；&nbsp;&nbsp; </p>
            <p><!--[if-->3)<!--[endif]-->更换商品；</p>
            <p><!--[if-->4)<!--[endif]-->继续履行；</p>
            <p><!--[if-->5)<!--[endif]-->赔偿损失；</p>
            <p><!--[if-->6)<!--[endif]-->支付违约金；</p>
            <p><!--[if-->7)<!--[endif]-->赔礼道歉。</p>
            <p>法律规定惩罚性赔偿的，依照其规定。</p>
            <p>
            </p>
            <p>本条规定的承担违约责任或权益侵害责任的方式，可以单独适用，也可以合并适用。</p>
        </div>

    </div>
</div></template>

<script>


export default {



    created() {
        this.title = this.$route.query.data
    },





}

</script>