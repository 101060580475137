
<template>
    <div class="model-content">
        <div class="mode19" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'model_ug' : 'model_zh'">
            <div class="img-box">
                <img v-if="posterList.lang == 'ug' || !posterList.lang" style="width: 100%;" :src="moban_list.url_ug" />
                <img v-if="posterList.lang == 'zh'" style="width: 100%;" :src="moban_list.url_zh" />
            </div>
            <div class="merchant-name">
                <div class="merchant-name-title">
                    {{ posterList.merchant_name }}
                </div>
            </div>
            <div class="merchant_img">
                <img :src="posterList.merchant_img" alt="">
            </div>
            <div class="merchant_qrcode">
                <img :src="posterList.merchant_qrcode" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "model_19",
    data() {
        return {
            posterList: {},
            moban_list: {
                url_zh: require('../../img/poster/model_19_zh.jpg'),
                url_ug: require('../../img/poster/model_19_ug.jpg'),
            },
        };
    },
    created() {
        this.canvasfortitle(this.$route.query.merchant_name, this.$route.query);
    },
    methods: {
        canvasfortitle(merchant_name, query) {
            let res_name = merchant_name.replaceAll('(', '-')
            res_name = res_name.replaceAll('（', '-')
            res_name = res_name.replaceAll(')', ' ')
            res_name = res_name.replaceAll('）', ' ')
            this.posterList = query;
            this.posterList['merchant_name'] = res_name;
            console.log(this.posterList);
        },
    },
};
</script>
  
<style scoped>
.model-content {
    width: 339px;
    height: 632px;
    display: flex; 
    justify-content: center;
    position: relative;
    margin: auto;
}

.mode19 {
    width: 339px;
    height: 632px;
    position: relative;
}

.model_ug {
    direction: rtl;
}

.model_zh {
    direction: ltr;
}

.img-box {
    width: 100%;
    height: 100%;
}

.merchant-name {
    position: absolute;
    color: #fff;
    top: 372px;
    width: 172px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.model_ug .merchant-name {
    right: 84px;
}

.model_zh .merchant-name {
    left: 84px;
    top: 373px;
}

.merchant-name-title {
    font-size: 14px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 26px;
    padding: 0 5px;
}

.merchant_img {
    position: absolute;
    top: 242px;
    left: 112px;
    width: 115px;
    height: 115px;
    border-radius: 10px;
    overflow: hidden;
}

.merchant_img img {
    width: 100%;
    height: 100%;
}

.merchant_qrcode {
    position: absolute;
    bottom: 77px;
    left: 51px;
    width: 124px;
    height: 124px;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
}

.merchant_qrcode img {
    /* border-radius: 50%; */
    width: 100%;
    height: 100%;
}
</style>