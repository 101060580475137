
<template>
    <div class="model-content">
        <div class="mode23" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'model_ug' : 'model_zh'">
            <div class="img-box">
                <img v-if="posterList.lang == 'ug' || !posterList.lang" style="width: 100%;" :src="moban_list.url_ug" />
                <img v-if="posterList.lang == 'zh'" style="width: 100%;" :src="moban_list.url_zh" />
            </div>

            <div class="merchant-name">
                <div class='merchant-name-title'>{{ posterList.merchant_name }}</div>
            </div>

            <div class="food_name">
                <div class="food_name_text">{{ posterList.food_name }}</div>
            </div>

            <div class="food_img">
                <img :src="posterList.food_img" alt="">
            </div>

            <div class="food_price" :style="{fontSize: posterList.fontSize + 'px'}">
                <span>{{ posterList.food_price }}</span>
                <span v-if="posterList.lang == 'ug'" :style="{fontSize: (posterList.fontSize - 4) + 'px'}"> يۈەن</span>
                <span v-if="posterList.lang == 'zh'"> 元</span>
            </div>

            <div class="old_price">
                <span>{{ posterList.old_price }}</span>
            </div>

            <div class="merchant_qrcode">
                <img :src="posterList.merchant_qrcode" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "model_23",
    data() {
        return {
            posterList: {},
            moban_list: {
                url_zh: require('../../img/poster/model_23_zh.jpg'),
                url_ug: require('../../img/poster/model_23_ug.jpg'),
            },
        };
    },
    created() {
        this.canvasfortitle(this.$route.query.merchant_name, this.$route.query.food_name, this.$route.query);
    },
    methods: {
        canvasfortitle(merchant_name, food_names, query) {
            let res_name = merchant_name.replaceAll('(', '-')
            res_name = res_name.replaceAll('（', '-')
            res_name = res_name.replaceAll(')', ' ')
            res_name = res_name.replaceAll('）', ' ')
            let food_name = food_names.replaceAll('(', '-')
            food_name = food_name.replaceAll('（', '-')
            food_name = food_name.replaceAll(')', ' ')
            food_name = food_name.replaceAll('）', ' ')
            this.posterList = query;
            this.posterList['merchant_name'] = res_name;
            this.posterList['food_name'] = food_name;
            const price = this.posterList.food_price.toString();
            let fontSize = 20;
            if(price.length <= 2) {
                fontSize = 33;
            } else if(price.length == 3) {
                fontSize = 30;
            } else if(price.length == 4) {
                fontSize = 26;
            } else if(price.length == 5) {
                fontSize = 23;
            } else if(price.length == 6) {
                fontSize = 20;
            }
            this.posterList.fontSize = fontSize;
        },
    },
};
</script>
  
<style scoped>
.model-content {
    width: 352px;
    height: 632px;
    position: relative;
    margin: auto;
    display: flex; 
    justify-content: center;
}

.mode23 {
    width: 352px;
    height: 632px;
    position: relative;
}

.model_ug {
    direction: rtl;
}

.model_zh {
    direction: ltr;
}

.img-box {
    width: 100%;
    height: 100%;
}

.merchant-name {
    position: absolute;
    top: 80px;
    right: 50%;
    transform: translateX(50%);
    color: #ff9000;
    width: 260px;
    height: 56px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.merchant-name-title {
    font-size: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*垂直排列*/
    line-clamp: 2;
    -webkit-line-clamp: 2;
    /*只显示6行*/
    overflow: hidden;
    /*溢出隐藏*/
    text-overflow: ellipsis;
    /*省略号代替*/
}

.food_name {
    position: absolute;
    width: 170px;
    height: 56px;
    top: 176px;
    right: 18px;
    font-size: 18px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
}

.food_name_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*垂直排列*/
    line-clamp: 2;
    -webkit-line-clamp: 2;
    /*只显示6行*/
    overflow: hidden;
    /*溢出隐藏*/
    text-overflow: ellipsis;
    /*省略号代替*/
}

.food_img {
    position: absolute;
    top: 250px;
    left: 66px;
    width: 233px;
    height: 225px;
    border-radius: 50%;
    overflow: hidden;
}

.food_img img {
    width: 100%;
    height: 100%;
}

.food_price {
    position: absolute;
    top: 185px;
    left: 38px;
    width: 112px;
    color: #fff;
    text-align: center;
    line-height: 38px;
    font-weight: bold;
    transform: rotate(-9deg);
}
.old_price {
    position: absolute;
    top: 504px;
    width: 46px;
    font-size: 14px;
    color: #fff;
    text-decoration: line-through;
    font-weight: bold;
    text-align: center;
}

.model_ug .old_price {
    right: 296px;
    transform: rotate(-20deg);
}

.model_zh .old_price {
    top: 480px;
    left: 91px;
    width: 50px;
    transform: rotate(-14deg);
}

.merchant_qrcode {
    position: absolute;
    top: 532px;
    left: 57px;
    width: 84px;
    height: 84px;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
}

.merchant_qrcode img {
    width: 100%;
    height: 100%;
}
</style>