<template>
    <div class="model-content" style="display: flex; justify-content: center;">
        <div class="model_54" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'rtl' : ''">
            <div class="bg_img_container">
                <img style="width: 100%;" :src="moban_list.url" />
            </div>
            <div class="merchant_name">
                <div class="merchant_name_font_container">
                    <div class="merchant_name_font">{{ posterList.merchant_name }}</div>
                </div>
            </div>
            <div class="food_name">
                <div class="food_name_font_container">
                    <div class="food_name_font">{{ posterList.food_name }}</div>
                </div>

            </div>
            <div class="food_img">
                <img :src="posterList.food_img" alt="">
            </div>
            <div class="food_price">
                <span class="price_font">{{ posterList.food_price }}</span>
                <span v-if="posterList.lang == 'ug' || !posterList.lang">يۈەن</span>
                <span v-if="posterList.lang == 'zh'">元</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "model_54",
    data() {
        return {
            posterList: {},
            moban_list: {
                url: require('../../img/poster/model_54.jpg'),
            },
        };
    },
    created() {
        console.log();
        this.canvasfortitle(this.$route.query.merchant_name, this.$route.query.food_name, this.$route.query);
    },
    methods: {
        canvasfortitle(merchant_name, food_names, query) {
            let res_name = merchant_name.replaceAll('(', '-')
            res_name = res_name.replaceAll('（', '-')
            res_name = res_name.replaceAll(')', ' ')
            res_name = res_name.replaceAll('）', ' ')
            let food_name = food_names.replaceAll('(', '-')
            food_name = food_name.replaceAll('（', '-')
            food_name = food_name.replaceAll(')', ' ')
            food_name = food_name.replaceAll('）', ' ')
            this.posterList = query;
            this.posterList['merchant_name'] = res_name;
            this.posterList['food_name'] = food_name;
            this.posterList['food_price'] = Math.round(Number(query.food_price || 0) * 100) / 100;

        },
    },
};
</script>

<style scoped>
.model-content {
    width: 750px;
    height: 320px;
    position: relative;
    margin: auto;
    overflow: hidden;
}

.model_54 {
    width: 750px;
    height: 320px;
    position: relative;
    overflow: hidden;
}

.rtl {
    direction: rtl;
}

.bg_img_container {
    width: 100%;
    height: 100%;
}

.merchant_name {
    position: absolute;
    right: 0px;
    top: 80px;
    width: 470px;
    display: flex;
}

.merchant_name_font_container {
    max-width: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding: 8px 12px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 50px;
    margin: auto;
}

.merchant_name_font {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*垂直排列*/
    line-clamp: 1;
    -webkit-line-clamp: 1;
    /*只显示6行*/
    overflow: hidden;
    /*溢出隐藏*/
    text-overflow: ellipsis;
    /*省略号代替*/
    text-align: center;
    color: #321308;
    font-weight: bold;
}

.food_name {
    position: absolute;
    right: 0px;
    top: 130px;
    width: 470px;

}

.food_name_font_container {
    max-width: 358px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    box-sizing: border-box;
    border-radius: 50px;
    margin: auto;
}

.food_name_font {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*垂直排列*/
    line-clamp: 2;
    -webkit-line-clamp: 2;
    /*只显示6行*/
    overflow: hidden;
    /*溢出隐藏*/
    text-overflow: ellipsis;
    /*省略号代替*/
    text-align: center;
    color: #321308;
    font-size: 25px;
    font-weight: bold;
}

.food_price {
    position: absolute;
    bottom: 69px;
    left: 283px;
    transform: rotate(19deg);
    color: #FFF;
    font-weight: bold;
    font-size: 26px;
    display: flex;
    align-items: center;
}

.price_font {
    padding: 0 4px;
    font-size: 30px;
}


.food_img {
    position: absolute;
    left: 8px;
    top: 35px;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    overflow: hidden;
}

.food_img img {
    width: 100%;
    height: 100%;
}
</style>