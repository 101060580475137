
<template>
    <div class="model-content">
        <div class="mode21" :class="(posterList.lang == 'ug' || !posterList.lang) ? 'model_ug' : 'model_zh'">
            <div class="img-box">
                <img v-if="posterList.lang == 'ug' || !posterList.lang" style="width: 100%;" :src="moban_list.url_ug" />
                <img v-if="posterList.lang == 'zh'" style="width: 100%;" :src="moban_list.url_zh" />
            </div>

            <div class="merchant-name">
                <div class='merchant-name-title'>{{ posterList.merchant_name }}</div>
            </div>
            
            <div class="date-time" v-if="posterList.date">
                <div class="date-item">
                    <span> {{ posterList.date }}</span>
                </div>
            </div>

            <div class="food_name">
                <div class="food_name_text">{{ posterList.food_name }}</div>
            </div>

            <div class="food_img">
                <img :src="posterList.food_img" alt="">
            </div>

            <div class="food_price" :style="{fontSize: posterList.fontSize + 'px'}">
                <span>{{ posterList.food_price }}</span>
            </div>

            <div class="old_price">
                <span>{{ posterList.old_price }}</span>
            </div>

            <div class="merchant_qrcode">
                <img :src="posterList.merchant_qrcode" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "model_21",
    data() {
        return {
            posterList: {},
            moban_list: {
                url_zh: require('../../img/poster/model_21_zh.jpg'),
                url_ug: require('../../img/poster/model_21_ug.jpg'),
            },
        };
    },
    created() {
        this.canvasfortitle(this.$route.query.merchant_name, this.$route.query.food_name, this.$route.query);
    },
    methods: {
        canvasfortitle(merchant_name, food_names, query) {
            let res_name = merchant_name.replaceAll('(', '-')
            res_name = res_name.replaceAll('（', '-')
            res_name = res_name.replaceAll(')', ' ')
            res_name = res_name.replaceAll('）', ' ')
            let food_name = food_names.replaceAll('(', '-')
            food_name = food_name.replaceAll('（', '-')
            food_name = food_name.replaceAll(')', ' ')
            food_name = food_name.replaceAll('）', ' ')
            this.posterList = query;
            this.posterList['merchant_name'] = res_name;
            this.posterList['food_name'] = food_name;
            const price = this.posterList.food_price.toString();
            let fontSize = 20;
            if(price.length <= 2) {
                fontSize = 45;
            } else if(price.length == 3) {
                fontSize = 40;
            } else if(price.length == 4) {
                fontSize = 35;
            } else if(price.length == 5) {
                fontSize = 27;
            } else if(price.length == 6) {
                fontSize = 22;
            }
            this.posterList.fontSize = fontSize;
        },
    },
};
</script>
  
<style scoped>
.model-content {
    width: 340px;
    height: 632px;
    position: relative;
    margin: auto;
    display: flex; 
    justify-content: center;
}

.mode21 {
    width: 340px;
    height: 632px;
    position: relative;
}

.model_ug {
    direction: rtl;
}

.model_zh {
    direction: ltr;
}

.img-box {
    width: 100%;
    height: 100%;
}

.merchant-name {
    position: absolute;
    top: 143px;
    color: #75ef54;
    width: 260px;
    height: 42px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.model_ug .merchant-name {
    right: 40px;
}

.model_zh .merchant-name {
    left: 40px;
}

.merchant-name-title {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.date-time {
    position: absolute;
    top: 189px;
    left: calc(50% - 148px);
    width: 296px;
    height: 18px;
    color: #fff;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    direction: ltr;
}

.date-item {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;    
}

.food_name {
    position: absolute;
    width: 260px;
    height: 34px;
    top: 208px;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.model_ug .food_name {
    right: 40px;
}

.model_zh .food_name {
    left: 40px;
}

.food_name_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.food_img {
    position: absolute;
    top: 267px;
    left: 71px;
    width: 211px;
    height: 211px;
    border-radius: 50%;
    overflow: hidden;
}

.food_img img {
    width: 100%;
    height: 100%;
}

.food_price {
    position: absolute;
    top: 446px;
    left: 22px;
    width: 80px;
    color: #4f3e3e;
    text-align: center;
    line-height: 38px;
    font-weight: bold;
}
.old_price {
    position: absolute;
    top: 493px;
    width: 46px;
    font-size: 14px;
    color: #fff;
    text-decoration: line-through;
}

.model_ug .old_price {
    right: 186px;
}

.model_zh .old_price {
    top: 491px;
    left: 200px;
}

.merchant_qrcode {
    position: absolute;
    top: 548px;
    right: 50px;
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
}

.merchant_qrcode img {
    width: 100%;
    height: 100%;
}
</style>